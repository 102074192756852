import { useQuery } from "react-query";

import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getRoomItems } from "../apis/roomsApi";
import { ROOM_ITEMS_QUERY_KEY } from "../queries-keys/rooms";
import { getErrorMessage } from "../utils/errors";

const useGetItems = (roomId) => {
  const { handleOpenSnackbar } = useSnackbar();

  const { data: items, isLoading } = useQuery(
    ROOM_ITEMS_QUERY_KEY(roomId),
    () => getRoomItems(roomId),
    {
      staleTime: Infinity,
      onError: (error) => {
        handleOpenSnackbar(
          getErrorMessage(error),
          "error",
        );
      },
    },
  );

  return {
    items,
    isLoading,
  };
};

export default useGetItems;
