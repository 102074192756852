import React from "react";

function CardWrapper({
  isSelected = false,
  disabled = false,
  onClick = () => {},
  onDoubleClick = () => {},
  className,
  children,
}) {
  return (
    <div
      className={`cursor-pointer select-none overflow-hidden rounded-md border border-softGray shadow ${
        !disabled && "hover:shadow-lg"
      } ${isSelected && "!border-highlight !text-highlight"} ${
        disabled && "!cursor-default !border-gray-100 !text-softGray hover:shadow"
      } ${className}`}
      onClick={disabled ? () => {} : onClick}
      onDoubleClick={disabled ? () => {} : onDoubleClick}
    >
      {children}
    </div>
  );
}

export default CardWrapper;
