import React from "react";
import PropTypes from "prop-types";

function Badge({ content, children }) {
  return (
    <div className="relative">
      {!!content && (
        <span className="absolute right-0 top-0 flex w-4 h-4 items-center justify-center overflow-hidden rounded-full bg-highlight p-1 text-[12px] font-bold text-white">
          {content}
        </span>
      )}
      {children}
    </div>
  );
}

Badge.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Badge;
