import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import ROUTES from "../../../constants/routes";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import MainButton from "../../../components/customButtons/MainButton";
import usePayment from "../../../hooks/usePayment";
import { verifyCoupon } from "../../../apis/paymentApi";
import { getErrorMessage } from "../../../utils/errors";
import { formatToCurrency } from "../../../utils/numbers";
import { CURRENCY } from "../../../constants/common";
function CheckoutSummery({
  cartData = [],
  noPriceCartData = [],
  totalPrice = 0,
  isCheckingOut = false,
  shippingAddress,
}) {
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState("");
  const [couponMessage, setCouponMessage] = useState("");
  const [discount, setDiscount] = useState(0);
  const [couponId, setCouponId] = useState(null);
  const { createPaymentMutation, isPaymentInProgress } = usePayment();
  const { handleOpenSnackbar } = useSnackbar();

  const handleProceedCheckout = () => {
    const allAvailable = cartData.every((item) => item.isAvailable);

    if (allAvailable) {
      navigate(ROUTES.CHECKOUT.path);
    } else {
      handleOpenSnackbar(
        "Not All Items are Available, Remove them to proceed",
        "error",
      );
    }
  };

  const handlePaymentClick = () => {
    createPaymentMutation({
      amount: totalPrice - discount,
      cart_items: cartData.map((item) => item.id),
      shipping_address: shippingAddress,
      coupon: couponId,
    });
  };

  const { mutate: applyCouponMutation, isLoading: isApplyingCoupon } =
    useMutation({
      mutationFn: verifyCoupon,
      onSuccess: (coupon) => {
        setCouponMessage(
          `This coupon gives you a discount of ${coupon.discount} % up to ${formatToCurrency(coupon.upper_bound, CURRENCY)} .`,
        );
        setDiscount(
          Math.min((totalPrice * coupon.discount) / 100.0, coupon.upper_bound),
        );
        setCouponId(coupon.id);
      },
      onError: (error) => {
        setCouponMessage(getErrorMessage(error));
        setDiscount(0);
        setCouponId(null);
      },
    });

  const handleApplyCouponClick = () => {
    if (coupon) {
      applyCouponMutation({
        coupon: coupon,
      });
    }
  };

  const handleCouponChange = (event) => {
    setCoupon(event.target.value);
    setCouponMessage("");
    setDiscount(0);
    setCouponId(null);
  };

  return (
    <div className="flex h-fit w-full flex-col space-y-5 rounded bg-gray-100 p-5 text-[14px] lg:w-1/4 lg:p-10">
      <span className="text-[18px] font-medium">Summary</span>
      <div className="flex items-center justify-between text-secondary">
        <span>Subtotal</span>
        <span>{formatToCurrency(totalPrice, CURRENCY)}</span>
      </div>
      <div className="flex items-center justify-between text-secondary">
        <span>Shipping</span>
        <span>{formatToCurrency(0, CURRENCY)}</span>
      </div>
      {isCheckingOut && (
        <>
          <div className="flex items-center justify-between text-secondary">
            <span>Discount</span>
            <span>{formatToCurrency(discount, CURRENCY)}</span>
          </div>
          <span className="border-b" />
          <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-evenly border-b-2 pb-2">
              <input
                className="h-6 border-none bg-transparent placeholder:text-secondary focus:outline-none focus:ring-0"
                placeholder="Enter gift code"
                value={coupon}
                onChange={handleCouponChange}
              />
              {isApplyingCoupon ? (
                <AiOutlineLoading3Quarters
                  size={20}
                  className="shrink-0 animate-spin text-secondary"
                />
              ) : (
                <IoIosArrowForward
                  size={20}
                  onClick={coupon ? handleApplyCouponClick : () => {}}
                  title="Apply coupon"
                  className={`shrink-0 cursor-pointer ${coupon ? "scale-125 text-primary" : "!cursor-not-allowed text-secondary"}`}
                />
              )}
            </div>
            {couponMessage && (
              <div
                className={`text-[12px] ${couponId ? "text-green-500" : "text-red-500"}`}
              >
                <span>{couponMessage}</span>
              </div>
            )}
          </div>
        </>
      )}
      <span className="border-b" />
      <div className="flex items-center justify-between text-secondary">
        <span>Total Price</span>
        <span>{formatToCurrency(totalPrice - discount, CURRENCY)}</span>
      </div>
      <MainButton
        isLoading={isCheckingOut && isPaymentInProgress}
        disabled={isCheckingOut ? !shippingAddress : !cartData.length}
        onClick={isCheckingOut ? handlePaymentClick : handleProceedCheckout}
        className={"h-[40px]"}
      >
        {isCheckingOut ? "Proceed to Payment" : "Proceed to Checkout"}
      </MainButton>
    </div>
  );
}

export default CheckoutSummery;
