export default class Question {
    id: number;
    question: string;
    answer: string;

    constructor({id, question,answer} : {id: number, question: string, answer: string}) {
        this.id = id;
        this.question = question;
        this.answer = answer;
    }
  }