import React from "react";
import PageWrapper from "../../components/pageWrapper";
import OrdersList from "./components/OrdersList";
import Wishlist from "./components/Wishlist";
import PersonalDetails from "./components/PersonalDetails";
import NextConsultation from "./components/NextConsultation";

const UserProfile = () => {
  return (
    <PageWrapper>
      <div className="flex flex-col space-y-3 text-primary lg:space-y-5">
        <h1 className="text-[24px] font-bold lg:text-[34px]">My Account</h1>
        <div className="flex flex-col space-y-2 lg:h-[600px] lg:flex-row lg:space-x-3 lg:space-y-0">
          <div className="flex h-full w-full flex-col space-y-2 lg:space-y-3">
            <PersonalDetails />
            <NextConsultation />
          </div>
          <OrdersList />
          <Wishlist />
        </div>
      </div>
    </PageWrapper>
  );
};

export default UserProfile;
