import React from "react";
import ReactRouterPrompt from "react-router-prompt";

import Modal from "../modal";
import MainButton from "../customButtons/MainButton";
import OutlineButton from "../customButtons/OutlineButton";

function ChangingPrompt({ open = false }) {
  return (
    <ReactRouterPrompt when={open}>
      {({ isActive, onConfirm, onCancel }) => (
        <Modal show={isActive} onClose={onCancel} className="lg:w-4/12">
          <div className="flex flex-col space-y-3 p-2 text-primary">
            <span className="text-[18px] font-bold">Unsaved Changes</span>
            <span className="text-[16px]">
              You have unsaved changes. Do you really want to leave this page?
            </span>
            <div className="!mt-6 flex space-x-5">
              <OutlineButton
                onClick={onCancel}
                className="flex-1 focus:shadow-inner"
              >
                Close
              </OutlineButton>
              <MainButton
                onClick={onConfirm}
                className="flex-1 focus:shadow-inner"
              >
                Leave
              </MainButton>
            </div>
          </div>
        </Modal>
      )}
    </ReactRouterPrompt>
  );
}

export default ChangingPrompt;
