import React from "react";
import PropTypes from "prop-types";

const Skeleton = ({ width, height, isRounded, className }) => {
  const skeletonClasses = `animate-pulse bg-gray-200 ${
    isRounded && "rounded-md"
  } ${className}`;

  return (
    <div
      className={skeletonClasses}
      style={{ maxWidth: width, width: "100%", height: height }}
    ></div>
  );
};

Skeleton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  isRounded: PropTypes.bool,
  className: PropTypes.string,
};

export default Skeleton;
