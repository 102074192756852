import React from "react";
import MoonLoader from "react-spinners/MoonLoader";

import useGetConsultantPreviousSlots from "../../../hooks/useGetConsultantPerviousSlots";
import ConsultationSlotCard from "./ConsultationSlotCard";
import Accordion from "../../../components/accordion";
import useMediaQuery from "../../../hooks/useMediaQuery";

export default function ConsultantPrevConsultations() {
  const isMaxWidth1024 = useMediaQuery("(max-width: 1024px)");
  const { consultantPreviousSlots, isFetchingPreviousSlots } =
    useGetConsultantPreviousSlots();

  return (
    <div className="w-full select-none space-y-3 overflow-y-hidden rounded border-[0.3px] border-softGray p-3 shadow shadow-softGray lg:h-full lg:p-5">
      <Accordion enable={isMaxWidth1024} summary={"Previous Consultations"}>
        {isFetchingPreviousSlots ? (
          <div className="flex items-center justify-center lg:h-[500px]">
            <MoonLoader color="#24595C" />
          </div>
        ) : consultantPreviousSlots?.length ? (
          <div className="flex flex-col space-y-2 overflow-y-auto pr-2 lg:h-[500px]">
            {consultantPreviousSlots?.map((slot) => (
              <React.Fragment key={slot.id}>
                <ConsultationSlotCard key={slot.id} slot={slot} />
                {<div className="border-b border-softGray last:hidden" />}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center lg:h-[500px]">
            <span className="font-medium lg:text-[18px]">
              No previous consultations
            </span>
          </div>
        )}
      </Accordion>
    </div>
  );
}
