import React from "react";

import { formatToCurrency } from "../../../utils/numbers";
import { CURRENCY } from "../../../constants/common";
function ProductDetails({ product }) {
  return (
    <div className="flex flex-col space-y-3">
      <span className="text-[18px] font-medium">Details</span>
      <span className="text-[14px] font-light">{product?.details}</span>
      <div className="flex space-x-5">
        <div className="flex space-x-1">
          <span className="text-[14px]">Width</span>
          <span className="text-[14px] font-light">{+product?.width} cm</span>
        </div>
        <div className="flex space-x-1">
          <span className="text-[14px]">Length</span>
          <span className="text-[14px] font-light">{+product?.length} cm</span>
        </div>
        <div className="flex space-x-1">
          <span className="text-[14px]">Height</span>
          <span className="text-[14px] font-light">{+product?.height} cm</span>
        </div>
      </div>
      {!!product?.colors?.length && <span className="text-[14px]">Color</span>}
      <div className="flex flex-wrap space-x-5">
        {product?.colors?.map((color) => {
          return (
            <div
              key={color}
              style={{ backgroundColor: color }}
              className={`h-8 w-8 rounded-full`}
            />
          );
        })}
      </div>
      <span className="text-[18px] font-medium">
        {formatToCurrency(product?.price, CURRENCY)}
      </span>
    </div>
  );
}

export default ProductDetails;
