import api from "./config/axiosWith401Redirect";

const NEWS_URL = "/newsletter";

export const subscribeToNewsletter = async (email) => {
  const response = await api.post(`${NEWS_URL}/subscribe/`, {
    email,
  });

  return response.data;
};
