export class Route {
  constructor(label, path) {
    this.label = label;
    this.path = path;
  }
}

const HOME = new Route("Home", "/");
const SIGN_UP = new Route("Sign Up", "/auth/register");
const SIGN_IN = new Route("Sign In", "/auth/login");
const STYLE = new Route("Find Your Style", "/style");
const SHOP = new Route("Shop", "/shop");
const CONSULTANT = new Route("Book a Consultant", "/book-consultant");
const ABOUT = new Route("About", "/about");
const FAQ = new Route("FAQ", "/faq");
const CONTACT_US = new Route("Contact Us", "/contact-us");
const READY_TO_BUY = new Route("Limited Collection", "/ready-to-buy");
const SAMPLE = new Route("Samples", "/sample");
const DESIGNS = new Route("Designs", "/shop/designs");
const FBXBASEURL = new Route("FBX Base URL", "./fbx/scaled/FBX/");
const PRODUCT = new Route("Products", "/ready-to-buy/products");
const CART = new Route("Cart", "/cart");
const CHECKOUT = new Route("Checkout", "/checkout");
const PROFILE = new Route("Profile", "/profile");
const FORGET_PASSWORD = new Route("Forget Password", "/auth/forget-password");
const PAYMENT_STATUS = new Route("Payment Status", "/payment-status");

export const BOOKING_CONSULTANT_PARAMS = {
  CONSULTATION_TYPE: "consultation-type",
  BOOKING_DETAILS: "booking-details",
};

export const CONTACT_US_PARAM = "contact-us";

const ROUTES = {
  HOME,
  SIGN_UP,
  SIGN_IN,
  STYLE,
  SHOP,
  CONSULTANT,
  ABOUT,
  FAQ,
  CONTACT_US,
  READY_TO_BUY,
  SAMPLE,
  DESIGNS,
  FBXBASEURL,
  PRODUCT,
  CART,
  CHECKOUT,
  PROFILE,
  FORGET_PASSWORD,
  PAYMENT_STATUS,
};

export default ROUTES;
