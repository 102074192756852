import React, { useLayoutEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import SideBar from './components/SideBar';
import DesignsSelector from './components/DesignsSelector';
import Viewer from './components/Viewer';
import PageWrapper from '../../components/pageWrapper';
import ChangingPrompt from '../../components/changingPrompt';
import usePreventNavigation from '../../hooks/usePreventNavigation';
import useStepper from '../../components/stepper/hooks/useStepper';
import { StepperProvider } from '../../components/stepper/contexts/StepperProvider';
import { FilterProvider } from './contexts/FilterProvider';
import { reset as resetDesignState } from '../../features/designSlice';
import { DESIGN_CATEGORY } from '../../constants/designs';

const Design = () => {
  const dispatch = useDispatch();
  const { item } = useOutletContext() || {};
  const isDesignChanged = useSelector((state) => state.design.isChanged);
  usePreventNavigation(isDesignChanged);

  useLayoutEffect(() => {
    return () => {
      dispatch(resetDesignState());
    };
  }, []);

  return (
    <PageWrapper className='pt-[50px] lg:px-20 xl:!max-w-[1100px] 2xl:!max-w-[1350px] min-[1800px]:!max-w-[1500px]'>
      <SideBar item={item} />
      <StepperProvider>
        <FilterProvider>
          <DesignInner item={item} />
        </FilterProvider>
      </StepperProvider>
      <ChangingPrompt open={isDesignChanged} />
    </PageWrapper>
  );
};

export default Design;

const DesignInner = ({ item }) => {
  const { currentStepData, isFinalStep } = useStepper();
  const isLayout = currentStepData?.category === DESIGN_CATEGORY.LAYOUT;

  return (
    <div
      className={
        (!currentStepData?.category || isLayout) && !isFinalStep
          ? 'flex flex-col space-y-3'
          : 'flex flex-col gap-3 lg:grid lg:grid-cols-7 lg:gap-10'
      }
    >
      <span className='text-[24px] font-bold text-primary lg:hidden'>{item?.name} Design</span>
      <div
        className={`col-span-4 flex h-min flex-col space-y-5 ${
          (!currentStepData?.category || isLayout) && !isFinalStep && 'hidden'
        }`}
      >
        <Viewer />
      </div>
      <DesignsSelector />
    </div>
  );
};
