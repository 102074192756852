import React from "react";

import Skeleton from "../skeleton";

function ProductSkeleton() {
  return (
    <div className="flex flex-col space-y-3 lg:space-y-5">
      <Skeleton className="h-8 w-1/4" />
      <div className="flex grid-cols-11 flex-col gap-5 lg:grid lg:gap-10">
        <div className="col-span-6 flex flex-col space-y-3">
          <Skeleton className="aspect-[13/9] w-full rounded" />
          <div className="flex space-x-2">
            {Array.from({ length: 4 }).map((_, index) => {
              return (
                <React.Fragment key={index}>
                  <Skeleton className={"h-[100px] w-full rounded"} />
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="col-span-5 flex flex-col space-y-3">
          <Skeleton className={"h-5 w-[100px]"} />
          <div className="flex flex-col space-y-2">
            {Array.from({ length: 6 }).map((_, index) => {
              return (
                <React.Fragment key={index}>
                  <Skeleton className={"h-3 w-full"} />
                </React.Fragment>
              );
            })}
          </div>
          <div className="flex space-x-2">
            <Skeleton className={"h-6 w-[100px]"} />
            <Skeleton className={"h-6 w-[100px]"} />
            <Skeleton className={"h-6 w-[100px]"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductSkeleton;
