import React, { createContext, useReducer, useState } from "react";

const FilterContext = createContext();

const UPDATE_SELECTED_COLORS_FILTERS = "UPDATE_COLORS_FILTERS";
const UPDATE_SELECTED_MATERIALS_FILTERS = "UPDATE_MATERIALS_FILTERS";
const UPDATE_SELECTED_STYLES_FILTERS = "UPDATE_STYLES_FILTERS";
const UPDATE_SELECTED_DIMENSIONS_FILTERS = "UPDATE_DIMENSIONS_FILTERS";
const RESET_SELECTED_FILTERS = "RESET_FILTERS";
const RESET_SELECTED_FILTERS_EXCEPT_DIMENSIONS = "RESET_FILTERS_EXCEPT_DIMENSIONS";

const initialSelectedFilters = {
  selectedColorsFilters: [],
  selectedMaterialsFilters: [],
  selectedStylesFilters: [],
  selectedDimensionsFilters: [],
};

function filtersReducer(state, action) {
  switch (action.type) {
    case UPDATE_SELECTED_COLORS_FILTERS:
      return { ...state, selectedColorsFilters: action.payload };
    case UPDATE_SELECTED_MATERIALS_FILTERS:
      return { ...state, selectedMaterialsFilters: action.payload };
    case UPDATE_SELECTED_STYLES_FILTERS:
      return { ...state, selectedStylesFilters: action.payload };
    case UPDATE_SELECTED_DIMENSIONS_FILTERS:
      return { ...state, selectedDimensionsFilters: action.payload };
    case RESET_SELECTED_FILTERS_EXCEPT_DIMENSIONS:
      return {...initialSelectedFilters, selectedDimensionsFilters: state.selectedDimensionsFilters};
    case RESET_SELECTED_FILTERS:
      return initialSelectedFilters;
    default:
      return state;
  }
}

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    colorsFilters: [],
    materialsFilters: [],
    stylesFilters: [],
    dimensionsFilters: [],
  });
  const [selectedFilters, selectedFiltersDispatch] = useReducer(
    filtersReducer,
    initialSelectedFilters,
  );

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters,
        selectedFilters,
        selectedFiltersDispatch,
        UPDATE_SELECTED_COLORS_FILTERS,
        UPDATE_SELECTED_MATERIALS_FILTERS,
        UPDATE_SELECTED_STYLES_FILTERS,
        UPDATE_SELECTED_DIMENSIONS_FILTERS,
        RESET_SELECTED_FILTERS,
        RESET_SELECTED_FILTERS_EXCEPT_DIMENSIONS,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContext;
