import Item from "./furniture/item";

export default class Design {
  id: number;
  user: number; // TODO: add user class
  item: number | Item;
  layout_body: any = {};
  layout_size: any = {};
  elements: any = {};
  price: number | null = null;
  price_mode: string;
  image: string | null = null;

  constructor({
    id,
    user,
    item,
    layout_body,
    layout_size,
    elements,
    price,
    price_mode,
    image,
  }: {
    id: number;
    user: number;
    item: number | Item;
    layout_body: any;
    layout_size: any;
    elements: any;
    price: number | null;
    price_mode: string;
    image: string | null;
  }) {
    this.id = id;
    this.user = user;
    this.item = item;
    this.layout_body = layout_body;
    this.layout_size = layout_size;
    this.elements = elements;
    this.price = price;
    this.price_mode = price_mode;
    this.image = image;
  }
}
