import { useState, useEffect } from "react";

function useRemainingTime(targetTime) {
  const [remainingTime, setRemainingTime] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeDifference = targetTime - currentTime;

      if (timeDifference && timeDifference > 0) {
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        setRemainingTime({ hours, minutes, seconds });
      } else {
        setRemainingTime({ hours: 0, minutes: 0, seconds: 0 });
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetTime]);

  return remainingTime;
}

export default useRemainingTime;
