export const getErrorMessage = (error) => {
  return (
    error?.response?.data?.message ||
    error?.response?.data?.detail ||
    (typeof error?.response?.data?.non_field_errors?.[0] === "string" &&
      error?.response?.data?.non_field_errors?.[0]) ||
    error?.message ||
    (typeof error === "string" && error)
  );
};
