class ConfigurationSettings {
  autoSessionAcceptance: boolean;
  autoSessionAcceptanceLimit: number;
  numberOfHoursNotToRescheduleBefore: number;
  numberOfReschedulingTime: number;
  sessionDuration: string;
  priceMode: string;
  priceOfOnlineSlot: number;
  priceOfOnsiteSlot: number;
  priceOfInOfficeSlot: number;
  backgroundImage: string;
  descriptionImage: string;
  descriptionTitle: string;
  descriptionBody: string;
  sampleExpectedDeliveryDuration: number;
  furnitureExpectedDeliveryDuration: number;
  designExpectedDeliveryDuration: number;
  defaultRangePricePercentage: number;
  couponDefaultDiscountPercentage: number;
  couponDefaultDiscountUpperBound: number;
  shippingNotes: string;
  designRequestsEmail: string;
  contactUsEmail: string;
  contactUsPhone: string;
  contactUsAddress: string;
  contactUsMapLink: string;
  payment_duration: number;

  constructor(config: {
    auto_session_acceptance: boolean;
    auto_session_acceptance_limit: number;
    number_of_hours_not_to_reschedule_before: number;
    number_of_rescheduling_time: number;
    session_duration: string;
    price_mode: string;
    price_of_online_slot: number;
    price_of_onsite_slot: number;
    price_of_in_office_slot: number;
    background_image: string;
    description_image: string;
    description_title: string;
    description_body: string;
    sample_expected_delivery_duration: number;
    limited_collection_expected_delivery_duration: number;
    design_expected_delivery_duration: number;
    default_range_price_percentage: number;
    coupon_default_discount_percentage: number;
    coupon_default_discount_upper_bound: number;
    shipping_notes: string;
    design_requests_email: string;
    contact_us_email: string;
    contact_us_phone: string;
    contact_us_address: string;
    contact_us_map_link: string;
    payment_duration: number;
  }) {
    this.autoSessionAcceptance = config.auto_session_acceptance;
    this.autoSessionAcceptanceLimit = config.auto_session_acceptance_limit;
    this.numberOfHoursNotToRescheduleBefore = config.number_of_hours_not_to_reschedule_before;
    this.numberOfReschedulingTime = config.number_of_rescheduling_time;
    this.sessionDuration = config.session_duration;
    this.priceMode = config.price_mode;
    this.priceOfOnlineSlot = config.price_of_online_slot;
    this.priceOfOnsiteSlot = config.price_of_onsite_slot;
    this.priceOfInOfficeSlot = config.price_of_in_office_slot;
    this.backgroundImage = config.background_image;
    this.descriptionImage = config.description_image;
    this.descriptionTitle = config.description_title;
    this.descriptionBody = config.description_body;
    this.sampleExpectedDeliveryDuration = config.sample_expected_delivery_duration;
    this.furnitureExpectedDeliveryDuration = config.limited_collection_expected_delivery_duration;
    this.designExpectedDeliveryDuration = config.design_expected_delivery_duration;
    this.defaultRangePricePercentage = config.default_range_price_percentage;
    this.couponDefaultDiscountPercentage = config.coupon_default_discount_percentage;
    this.couponDefaultDiscountUpperBound = config.coupon_default_discount_upper_bound;
    this.shippingNotes = config.shipping_notes;
    this.designRequestsEmail = config.design_requests_email;
    this.contactUsEmail = config.contact_us_email;
    this.contactUsPhone = config.contact_us_phone;
    this.contactUsAddress = config.contact_us_address;
    this.contactUsMapLink = config.contact_us_map_link;
    this.payment_duration = config.payment_duration;
  }
}

export default ConfigurationSettings;
