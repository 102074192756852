
import { useQuery } from "react-query";
import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getClientPreviousSlots } from "../apis/consultationApi";
import { CLIENT_PREVIOUS_SLOTS } from "../queries-keys/consultation";
import { getErrorMessage } from "../utils/errors";

const useGetClientPreviousSlots = (client_id) => {
  const { handleOpenSnackbar } = useSnackbar();

  const { data: clientPreviousSlots, isLoading: isFetchingPreviousSlots } = useQuery({
    queryKey: CLIENT_PREVIOUS_SLOTS(client_id),
    queryFn: () => getClientPreviousSlots(client_id),
    onError: (error) => {
      handleOpenSnackbar(
        getErrorMessage(error),
        "error",
      );
    },
  },);

  return {
    clientPreviousSlots,
    isFetchingPreviousSlots,
  };
};

export default useGetClientPreviousSlots;

