class ConsultationType {
  constructor(name, price, description) {
    this.name = name;
    this.price = price;
    this.description = description;
  }
}

const ONLINE = new ConsultationType(
  "Online",
  100,
  "Consultation conducted remotely via video call platforms, allowing flexibility and convenience without physical presence.",
);

const ONSITE = new ConsultationType(
  "Onsite",
  300,
  "Consultation conducted directly at your location, enabling our experts to assess the space firsthand and offer tailored advice for optimal furniture placement and design.",
);

const IN_OFFICE = new ConsultationType(
  "In-Office",
  200,
  "Consultation held at our dedicated office space, providing a professional environment for discussions and planning furniture solutions.",
);

export const CONSULTATION_TYPES = {
  ONLINE,
  IN_OFFICE,
  ONSITE,
};
