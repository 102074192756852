import React from "react";
import { useDispatch, useStore } from "react-redux";

import useStepper from "../../../components/stepper/hooks/useStepper";
import SvgIcon from "../../../components/svgIcon";
import { generateShapesAndMaterialsSteps } from "../../../utils/designs";
import { setShape, setMaterial } from "../../../features/designSlice";
import { DESIGN_CATEGORY } from "../../../constants/designs";
import { SHUFFLE_ICON_URL } from "../../../constants/iconsUrls";
import { COLORS_ICON_URL, MATERIALS_ICON_URL } from "../../../constants/iconsUrls";

function OptionsShuffle({ setSteps, options, elements, selectShape, selectMaterial }) {
  const dispatch = useDispatch();
  const { getState } = useStore();
  const { currentStepData } = useStepper();

  const isShape = currentStepData?.category === DESIGN_CATEGORY.SHAPE;
  const isMaterial = currentStepData?.category === DESIGN_CATEGORY.MATERIAL;

  const handleShuffling = () => {
    if (!options || options.length === 0) {
      return;
    }

    const randomIdx = Math.floor(Math.random() * options.length);

    if (isShape) {
      let selectedElements = getState().design.elements;
      let elementIndex = null;
      for (let i = 0; i < elements.length; i++) {
        if (elements[i]?.id === currentStepData?.elementId) {
          elementIndex = i;
          break;
        }
      }
      selectShape(options[randomIdx]?.id, elementIndex, elements, selectedElements);
      selectedElements = getState().design.elements;
      selectMaterial(null, 0, 0, elements, selectedElements);
      selectedElements = getState().design.elements;
      const [shapesSteps, materialsSteps] = generateShapesAndMaterialsSteps(
        elements,
        selectedElements
      );

      setSteps((steps) => [
        steps[0],
        {
          label: DESIGN_CATEGORY.SHAPE,
          icon: COLORS_ICON_URL,
          subSteps: shapesSteps,
        },
        {
          label: DESIGN_CATEGORY.MATERIAL,
          icon: MATERIALS_ICON_URL,
          subSteps: materialsSteps,
        },
      ]);
    } else if (isMaterial) {
      dispatch(
        setMaterial({
          material: { ...options[randomIdx] },
          elementId: currentStepData?.elementId,
          elementName: currentStepData?.elementName,
          materialName: currentStepData?.materialName,
        })
      );
      let selectedElements = getState().design.elements;
      selectMaterial(null, 0, 0, elements, selectedElements);
      selectedElements = getState().design.elements;
      const [shapesSteps, materialsSteps] = generateShapesAndMaterialsSteps(
        elements,
        selectedElements
      );

      setSteps((steps) => [
        steps[0],
        {
          label: DESIGN_CATEGORY.SHAPE,
          icon: COLORS_ICON_URL,
          subSteps: shapesSteps,
        },
        {
          label: DESIGN_CATEGORY.MATERIAL,
          icon: MATERIALS_ICON_URL,
          subSteps: materialsSteps,
        },
      ]);
    }
  };

  return (
    <SvgIcon
      src={SHUFFLE_ICON_URL}
      onClick={handleShuffling}
      className="h-[20px] w-[20px] cursor-pointer !stroke-secondary hover:!stroke-primary"
    />
  );
}

export default OptionsShuffle;
