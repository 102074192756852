import api from "./config/axiosWith401Redirect";
import Payment from "../models/Payment";
import { createPayment } from "./paymentApi";
import { getProfile } from "./profileApi";
import { getAdminConfigs } from "./adminConfigsApi";

const TOKEN_URL = "https://accept.paymob.com/api/auth/tokens";
const ORDER_REGISTER_URL = "https://accept.paymob.com/api/ecommerce/orders";
const PAYMENT_KEY = "https://accept.paymob.com/api/acceptance/payment_keys";
const PAYMENT_URL =
  "https://accept.paymobsolutions.com/api/acceptance/iframes/";
const TRANSACTION_INQUIRY =
  "https://accept.paymobsolutions.com/api/ecommerce/orders/transaction_inquiry";
const API_KEY = process.env.REACT_APP_PAYMOB_API_KEY;
const INTEGRATION_ID = process.env.REACT_APP_PAYMOB_INTEGRATION_ID;
const IFRAME_ID = process.env.REACT_APP_PAYMOB_IFRAME_ID;

async function AuthenticationRequest() {
  const response = await api.post(
    TOKEN_URL,
    {
      headers: {
        "Content-Type": "application/json",
      },
      api_key: API_KEY,
    },
    { shouldIncludeToke: false },
  );
  return response?.data?.token;
}

const OrderRegistration = async (amount, token) => {
  const response = await api.post(
    ORDER_REGISTER_URL,
    {
      auth_token: token,
      amount_cents: amount * 100,
      currency: "EGP",
      delivery_needed: "false",
      items: [],
    },
    { shouldIncludeToke: false },
  );
  return [response?.data?.id, response?.data?.merchant?.id];
};

const PaymentKeyRequest = async (order_id, token, billing_data, payment_duration=null) => {
  const response = await api.post(
    PAYMENT_KEY,
    {
      auth_token: token,
      amount_cents: billing_data?.amount * 100,
      currency: "EGP",
      order_id: order_id,
      expiration: payment_duration ? Math.min(payment_duration*60, 3600) : 3600, 
      integration_id: INTEGRATION_ID,
      billing_data: {
        apartment: billing_data?.apartment,
        email: billing_data?.email,
        floor: billing_data?.floor,
        first_name: billing_data?.first_name,
        street: billing_data?.street,
        building: billing_data?.building,
        phone_number: billing_data?.phone_number,
        shipping_method: "PKG",
        postal_code: billing_data?.postal_code,
        city: billing_data?.city,
        country: billing_data?.country,
        last_name: billing_data?.last_name,
        state: billing_data?.state,
      },
    },
    { shouldIncludeToke: false },
  );
  return response?.data?.token;
};

export const TransactionInquiry = async (
  token,
  order_id,
  merchant_order_id,
) => {
  const response = await api.post(
    TRANSACTION_INQUIRY,
    {
      auth_token: token,
      merchant_order_id: merchant_order_id,
      order_id: order_id,
    },
    { shouldIncludeToke: false },
  );
  return response.data;
};

export const OpenPaymobWindow = async (billing_data) => {
  const token = await AuthenticationRequest();
  const [order_id, merchant_order_id] = await OrderRegistration(
    billing_data.amount,
    token,
  );
  const payment_token = await PaymentKeyRequest(order_id, token, billing_data);
  let payment_url = PAYMENT_URL + IFRAME_ID + "?payment_token=" + payment_token;
  var paymentWindow = window.open(
    payment_url,
    "payment",
    "width=800,height=600",
  );
  const paymentInfo = { token, order_id, merchant_order_id };
  return { paymentWindow, paymentInfo };
};

export const CreatePayment = async ({
  amount,
  cart_items = [],
  shipping_address = null,
  booking_slots = [],
  booking_requests = [],
  coupon = null,
} = {}) => {
  const user = await getProfile();
  const { payment_duration } = await getAdminConfigs();

  const billing_data = {
    amount: amount,
    apartment: "NA",
    email: user?.email,
    floor: "NA",
    first_name: user?.first_name,
    street: shipping_address?.street || "NA",
    building: "NA",
    phone_number: user?.mobile,
    postal_code: "NA",
    city: shipping_address?.city || "NA",
    country: "Egypt",
    last_name: user?.last_name,
    state: shipping_address?.state || "NA",
  };

  const token = await AuthenticationRequest();
  const [order_id, merchant_order_id] = await OrderRegistration(
    billing_data.amount,
    token,
  );
  const payment_token = await PaymentKeyRequest(order_id, token, billing_data, payment_duration);

  let payment_url = PAYMENT_URL + IFRAME_ID + "?payment_token=" + payment_token;

  const payment = new Payment({
    payment_order_id: order_id,
    cart_items: cart_items,
    shipping_address: shipping_address?.id,
    booking_slots: booking_slots,
    booking_requests: booking_requests,
    coupon : coupon,
  });

  await createPayment(payment);

  return payment_url;
};
