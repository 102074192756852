import React, { useState } from "react";
import { useQuery } from "react-query";

import PageWrapper from "../../components/pageWrapper";
import SamplesSelector from "./SamplesSelector";
import useSnackbar from "../../components/snackbar/hooks/useSnackbar";
import Loader from "../../components/loader";
import { getSamples } from "../../apis/sampleApi";
import { getErrorMessage } from "../../utils/errors";
import { SAMPLES_QUERY_KEY } from "../../queries-keys/samples";

const OrderSample = () => {
  const [currentViewedSample, setCurrentViewedSample] = useState();
  const [currentSelectedSampleType, setCurrentSelectedSampleType] = useState();
  const { handleOpenSnackbar } = useSnackbar();

  const {
    data: samples,
    isLoading,
    isFetching,
  } = useQuery(SAMPLES_QUERY_KEY(), getSamples, {
    refetchOnWindowFocus: false,
    onSuccess: (samples = {}) => {
      const firstSampleType = Object.keys(samples || {})[0];
      setCurrentSelectedSampleType(firstSampleType);
      const firstSampleSubType = Object.keys(
        samples?.[firstSampleType] || {},
      )[0];

      setCurrentViewedSample(
        samples?.[firstSampleType]?.[firstSampleSubType]?.[0],
      );
    },
    onError: (error) => {
      handleOpenSnackbar(getErrorMessage(error), "error");
    },
  });

  return (
    <PageWrapper>
      {isLoading || isFetching ? (
        <Loader className={"min-h-[400px]"} />
      ) : (
        <div className="flex flex-col space-y-3 text-primary lg:space-y-5">
          <h1 className="text-[24px] font-bold lg:text-[34px]">
            Order a Sample
          </h1>
          <div className="flex flex-col-reverse lg:flex-row lg:space-x-20">
            <SamplesSelector
              {...{
                samples,
                currentSelectedSampleType,
                setCurrentSelectedSampleType,
                currentViewedSample,
                setCurrentViewedSample,
              }}
            />
            {currentViewedSample?.image && (
              <img
                className="aspect-square h-[250px] rounded object-cover lg:h-[500px]"
                src={currentViewedSample?.image}
              />
            )}
          </div>
        </div>
      )}
    </PageWrapper>
  );
};

export default OrderSample;
