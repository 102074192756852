import api from "./config/axiosConfig";
import ReadyProduct from "../models/ReadyProduct";

const READY_TO_BUY_URL = "/readyToBuy";
const SERVICE_URL = "/product/rtb";

export const getReadyProducts = async (offset = 0, limit = 9) => {
  const response = await api.get(
    `${READY_TO_BUY_URL}/?limit=${limit}&offset=${offset}`,
  );
  const products =
    response.data?.results?.map((product) => new ReadyProduct(product)) || [];
  return {
    products,
    hasNext: !!response?.data?.next,
    hasPrevious: !!response?.data?.previous,
    totalProductsCount: response.data?.count,
  };
};

export const getReadyProduct = async (productId) => {
  const response = await api.get(`${READY_TO_BUY_URL}/${productId}/`);

  return new ReadyProduct(response?.data);
};

export const getRandomReadyProduct = async (limit = 3) => {
  const response = await api.get(`${READY_TO_BUY_URL}/random/?limit=${limit}`);

  const products =
    response.data?.map((product) => new ReadyProduct(product)) || [];

  return products;
};

export const getReadyProductServiceQuestion = async (productId) => {
  const response = await api.get(`${SERVICE_URL}/${productId}/questions/`);
  return response.data;
};

export const getReadyProductServiceInfo = async () => {
  const response = await api.get(`${SERVICE_URL}/info/`);
  return response.data;
};
