import { useMutation, useQueryClient } from "react-query";

import { activateSession } from "../apis/sessionApi";
import { SESSION_QUERY_KEY } from "../queries-keys/sessions";
import { getErrorMessage } from "../utils/errors";

const useActivateSession = () => {
  const queryClient = useQueryClient();

  const {
    mutate: activateSessionMutation,
    data: activatedSession,
    isLoading: isActivatingSession,
    isError: isActivatingSessionFailed,
    error: activatingSessionError,
  } = useMutation(activateSession, {
    onSuccess: () => {
      queryClient.setQueryData(SESSION_QUERY_KEY(), null);
      queryClient.invalidateQueries(SESSION_QUERY_KEY());
    },
  });

  return {
    activateSessionMutation,
    activatedSession,
    isActivatingSession,
    isActivatingSessionFailed,
    activatingSessionError: getErrorMessage(activatingSessionError),
  };
};

export default useActivateSession;
