import React from "react";

function Loader({ className }) {
  return (
    <div
      className={`flex h-full w-full items-center justify-center ${className}`}
    >
      <img
        src="/resources/loading.gif"
        alt="Loading..."
        className="h-24 lg:h-36"
      />
    </div>
  );
}

export default Loader;
