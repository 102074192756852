import Joi from "joi";

const validators = {
  firstName: Joi.string()
    .regex(/^[a-zA-Z\s-]+$/)
    .min(2)
    .max(50)
    .required()
    .messages({
      "string.base": "First name must be a string",
      "string.empty": "First name is required",
      "string.min": "Please enter a First name with at least {#limit} characters",
      "string.max": "First name cannot be longer than {#limit} characters",
      "string.pattern.base":
        "First name must contain only letters, spaces, or hyphens",
    }),

  lastName: Joi.string()
    .regex(/^[a-zA-Z\s-]+$/)
    .min(2)
    .max(50)
    .required()
    .messages({
      "string.base": "Last name must be a string",
      "string.empty": "Last name is required",
      "string.min": "Please enter a Last name with at least {#limit} characters",
      "string.max": "Last name cannot be longer than {#limit} characters",
      "string.pattern.base":
        "Last name must contain only letters, spaces, or hyphens",
    }),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.base": "Email must be a string",
      "string.empty": "Email is required",
      "string.email": "Email must be a valid email address",
    }),

  password: Joi.string()
    .min(8)
    .max(30)
    .required()
    .messages({
      "string.base": "Password must be a string",
      "string.empty": "Password is required",
      "string.min": "Please enter a Password with at least {#limit} characters",
      "string.max": "Password cannot be longer than {#limit} characters",
    }),

  confirmedPassword: Joi.string()
    .valid(Joi.ref("password"))
    .required()
    .messages({
      "any.only": "Passwords do not match",
      "string.empty": "Confirm password is required",
    }),

  birthdate: Joi.date().max("now").allow(null).messages({
    "date.base": "Birthdate must be a valid date",
    "date.max": "Birthdate cannot be in the future",
  }),

  mobile: Joi.string()
    .pattern(/^(\+2)?(010|011|012|015)[0-9]{8}$/)
    .required()
    .messages({
      "string.base": "Mobile number must be a string",
      "string.empty": "Mobile number is required",
      "string.pattern.base":
        "Mobile number must be a valid Egyptian phone number",
    }),

  OTP: Joi.string()
    .length(6)
    .pattern(/^[0-9]+$/)
    .required()
    .messages({
      "string.base": "OTP must be a string",
      "string.empty": "OTP is required",
      "string.length": "OTP must be exactly {#limit} characters long",
      "string.pattern.base": "OTP must contain only numbers",
    }),

  address: {
    state: Joi.string().min(2).max(30).required().messages({
      "string.base": "State must be a string",
      "string.empty": "State is required",
      "string.min": "Please enter a State with at least {#limit} characters",
      "string.max": "State cannot be longer than {#limit} characters",
    }),

    city: Joi.string().min(2).max(50).required().messages({
      "string.base": "City must be a string",
      "string.empty": "City is required",
      "string.min": "Please enter a City with at least {#limit} characters",
      "string.max": "City cannot be longer than {#limit} characters",
    }),

    street: Joi.string().min(2).max(100).required().messages({
      "string.base": "Street must be a string",
      "string.empty": "Street is required",
      "string.min": "Please enter a Street with at least {#limit} characters",
      "string.max": "Street cannot be longer than {#limit} characters",
    }),

    additionalInfo: Joi.string().allow("").max(255).messages({
      "string.base": "Additional info must be a string",
      "string.max": "Additional info cannot be longer than {#limit} characters",
    }),
  },
};

export default validators;
