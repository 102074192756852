import React from "react";
import MoonLoader from "react-spinners/MoonLoader";

import useGetClientPreviousSlots from "../../../hooks/userGetClientPerviousSlots";
import { formatDateToddMMyyyy } from "../../../utils/dates";

function ClientConsultationHistory({
  client_id,
  client_first_name,
  client_last_name,
}) {
  const { clientPreviousSlots, isFetchingPreviousSlots } =
    useGetClientPreviousSlots(client_id);

  return (
    <div className="flex flex-col items-center space-y-3">
      <span className="text-[18px] font-bold text-primary">
        {client_first_name + " " + client_last_name + "'s History"}
      </span>
      <div className="max-h-[400px] w-[90%] overflow-y-auto pr-2">
        {isFetchingPreviousSlots ? (
          <div className="flex items-center justify-center">
            <MoonLoader color="#24595C" />
          </div>
        ) : clientPreviousSlots?.length ? (
          <table className="w-full table-auto">
            <thead>
              <tr>
                <th className="py-2 text-center">Consultant name</th>
                <th className="border-l border-r border-gray-300 py-2 text-center">
                  Date and Time
                </th>
                <th className="py-2 text-center">Notes</th>
              </tr>
            </thead>
            <tbody>
              {clientPreviousSlots?.map((slot, index) => (
                <tr key={index} className="border-t border-gray-300">
                  <td className="w-1/4 p-2 text-center">
                    {slot?.consultant_first_name} {slot?.consultant_last_name}
                  </td>
                  <td className="w-1/4 border-l border-r border-gray-300 p-2 text-center">
                    {formatDateToddMMyyyy(slot?.start)} <br />{" "}
                    {slot?.start?.slice(11, 16)}
                  </td>
                  <td className="w-2/4 whitespace-pre-wrap p-2 text-center">
                    {slot?.notes}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex items-center justify-center">
            <span className="text-[14px] font-medium">
              No consultation history
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClientConsultationHistory;
