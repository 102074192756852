import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

function PasswordOutLinedField({
  id,
  label = "password",
  innerRef,
  className,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleCopyPaste = (event) => {
    event.preventDefault();
  };

  return (
    <div className={`flex flex-col space-y-2 ${className}`}>
      <label htmlFor={id} className="text-[16px] font-semibold text-primary">
        {label}
      </label>
      <div className="relative">
        <input
          ref={innerRef}
          type={showPassword ? "text" : "password"}
          {...rest}
          className="w-full rounded-md border px-4 py-2 outline-none autofill-color focus:ring-2 focus:ring-primary lg:w-full"
          onCopy={handleCopyPaste}
          onPaste={handleCopyPaste}
        />
        {showPassword ? (
          <FiEye
            className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer"
            onClick={() => setShowPassword(false)}
          />
        ) : (
          <FiEyeOff
            className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer"
            onClick={() => setShowPassword(true)}
          />
        )}
      </div>
    </div>
  );
}

export default PasswordOutLinedField;
