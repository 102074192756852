import React from "react";
import { useSearchParams } from "react-router-dom";

import ConsultationLayout from "./ConsultationLayout";
import MainButton from "../../../components/customButtons/MainButton";
import { BOOKING_CONSULTANT_PARAMS } from "../../../constants/routes";

function ConsultationServicesOverview() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <ConsultationLayout>
      <div className="flex flex-col space-y-2">
        <h3 className="text-[18px]">What we do?</h3>
        <p className="max-w-sm text-[14px] font-light">
          Our furniture consultancy service provides expert advice on selecting,
          arranging, and optimizing furniture to enhance both functionality and aesthetic
          appeal, creating beautiful and efficient spaces tailored to your needs.
        </p>
      </div>
      <div className="!mb-3 flex flex-col space-y-2">
        <h3 className="text-[18px]">What is the service?</h3>
        <p className="max-w-sm text-[14px]  font-light">
          An expert designer will guide you through navigating our website, and assist 
          with all your requirements, ensuring a seamless experience. Our service offers
          flexible options to suit your needs, whether you prefer online, onsite, or office
          consultations at a time that works best for you.
        </p>
      </div>
      <MainButton
        className={
          "h-[40px] w-full text-[14px] lg:!mt-auto lg:ml-auto lg:max-w-[200px]"
        }
        onClick={() => {
          searchParams.set("step", BOOKING_CONSULTANT_PARAMS.CONSULTATION_TYPE);
          setSearchParams(searchParams);
        }}
      >
        Book a Consultant
      </MainButton>
    </ConsultationLayout>
  );
}

export default ConsultationServicesOverview;
