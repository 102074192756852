import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import HEADER_ROUTES from "../../constants/headerRoutes";
import { arePathsEqual } from "../../utils/urls";

function NavBar() {
  const currentPath = useLocation().pathname;

  return (
    <nav className="hidden h-[52px] flex-grow items-center justify-evenly rounded-full border border-solid border-secondary font-medium lg:flex">
      {HEADER_ROUTES.map((route) => (
        <div key={route.path} className="relative flex h-full items-center">
          <NavLink
            to={route.path}
            className={`whitespace-nowrap text-[18px] hover:text-primary 2xl:text-[22px] ${
              arePathsEqual(currentPath, route.path)
                ? "font-bold !text-highlight"
                : "text-secondary"
            }`}
          >
            {route.label}
          </NavLink>
          {arePathsEqual(currentPath, route.path) && (
            <div className="absolute bottom-0 h-[2px] w-full rounded-xl bg-highlight"></div>
          )}
        </div>
      ))}
    </nav>
  );
}

export default NavBar;
