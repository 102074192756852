import React from "react";

import useUser from "../../hooks/useUser";
import NotFound from "../../pages/NotFound";

const PublicOrRoleRequired = ({ element: Component, allowedRoles }) => {
  const { user, isLoggedIn } = useUser();

  return !isLoggedIn || allowedRoles.includes(user?.role) ? (
    <Component />
  ) : (
    <NotFound />
  );
};

export default PublicOrRoleRequired;
