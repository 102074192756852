import React from "react";
import {
  Outlet,
  useOutletContext,
  Navigate,
  useParams,
} from "react-router-dom";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";

import Loader from "../loader";
import { getDesign } from "../../apis/designsApi";
import { init as initDesignState } from "../../features/designSlice.js";
import { DESIGN_QUERY_KEY } from "../../queries-keys/designs";
import { ITEM_LAYOUTS_QUERY_KEY } from "../../queries-keys/items";
import { getItemLayouts } from "../../apis/itemsApi";

function GetDesign() {
  const dispatch = useDispatch();
  const { designId } = useParams();
  const { sessionRemainingTime } = useOutletContext() || {};
  const {
    data: design,
    isLoading: isLoadingDesign,
    isFetching: isFetchingDesign,
  } = useQuery(DESIGN_QUERY_KEY(designId), () => getDesign(designId), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onSuccess: (design) => {
      dispatch(initDesignState({ ...design }));
    },
  });
  const {
    data: layouts,
    isLoading: isFetchingItemLayouts,
    isIdle,
  } = useQuery(
    ITEM_LAYOUTS_QUERY_KEY(design?.item?.id),
    () => getItemLayouts(design?.item?.id),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!design?.item?.id,
    },
  );

  // TODO: navigate to error page when there is an error
  return (
    <>
      {isFetchingDesign ||
      isFetchingItemLayouts ||
      isIdle ||
      isLoadingDesign ? (
        <Loader className={"min-h-[400px]"} />
      ) : (
        <Outlet
          context={{
            sessionRemainingTime,
            item: design?.item,
            layouts,
          }}
        />
      )}
    </>
  );
}

export default GetDesign;
