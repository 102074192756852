import React, { useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { useMutation } from "react-query";
import { useQuery } from "react-query";
import { IoClose } from "react-icons/io5";

import MainButton from "../../../components/customButtons/MainButton";
import TrackingStepper from "./TrackingStepper";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import useUser from "../../../hooks/useUser";
import useAddCartItem from "../../../hooks/useAddCartItem";
import Accordion from "../../../components/accordion";
import useMediaQuery from "../../../hooks/useMediaQuery";
import useCreateDesignPricingRequest from "../../../hooks/useCreateDesignPricingRequest";
import { DEFAULT_DESIGN_IMAGE } from "../../../constants/imagesUrls";
import { getOrders } from "../../../apis/ordersApi";
import { ORDERS_QUERY_KEY } from "../../../queries-keys/orders";
import { formatToCurrency } from "../../../utils/numbers";
import { CART_ITEMS_TYPES } from "../../../constants/cartItems";
import { getErrorMessage } from "../../../utils/errors";
import { createCopiedDesign } from "../../../apis/designsApi";
import { CURRENCY } from "../../../constants/common";
import { PRICE_MODES } from "../../../constants/priceModes";

function OrdersList() {
  const { user } = useUser();
  const isMaxWidth1024 = useMediaQuery("(max-width: 1024px)");
  const { handleOpenSnackbar } = useSnackbar();
  const [currentDisplayedOrder, setCurrentDisplayedOrder] = useState();
  const [currentReOrderedOrder, setCurrentReOrderedOrder] = useState();

  const { data: orders, isLoading } = useQuery(
    ORDERS_QUERY_KEY(user?.id),
    getOrders,
  );

  const { createDesignPricingRequestMutation, isPricingRequestLoading } =
    useCreateDesignPricingRequest();

  const { addCartItemMutation, isAddingCartItemLoading } = useAddCartItem({
    onSuccess: ({ design: { id, price_mode } = {} } = {}) => {
      handleOpenSnackbar("Design is added to cart", "success");

      if (price_mode && price_mode !== PRICE_MODES.ON) {
        createDesignPricingRequestMutation(id);
      }
    },
  });

  const handleViewOrder = (order) => {
    setCurrentDisplayedOrder(order);
  };

  const { mutate: createCopiedDesignMutation, isLoading: isReOrderingDesign } =
    useMutation({
      mutationFn: createCopiedDesign,
      onSuccess: (newDesignData) => {
        addCartItemMutation({
          design: newDesignData?.id,
          type: CART_ITEMS_TYPES.DESIGN,
          quantity: 1,
        });
      },
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    });

  const handleDesignReorder = (copiedDesignId) => {
    setCurrentReOrderedOrder(copiedDesignId);
    createCopiedDesignMutation(copiedDesignId);
  };

  return (
    <div className="relative w-full space-y-3 overflow-y-hidden rounded border-[0.3px] border-softGray p-3 shadow shadow-softGray lg:h-full lg:p-5">
      <Accordion
        enable={isMaxWidth1024}
        summary={currentDisplayedOrder ? "Track order" : "Orders"}
      >
        {currentDisplayedOrder && (
          <IoClose
            size={20}
            className="absolute right-12 top-4 cursor-pointer text-secondary hover:text-primary lg:right-3 lg:top-3"
            onClick={() => setCurrentDisplayedOrder()}
          />
        )}

        <div
          className={`flex flex-col lg:h-[500px] lg:overflow-y-auto ${currentDisplayedOrder && "h-[250px]"}`}
        >
          {isLoading ? (
            <div className="flex h-full items-center justify-center">
              <MoonLoader color="#24595C" />
            </div>
          ) : (
            <>
              {currentDisplayedOrder && (
                <TrackingStepper order={currentDisplayedOrder} />
              )}
              {orders?.length ? (
                <div
                  className={`flex flex-col space-y-4 pr-2 lg:h-[500px] lg:overflow-y-auto ${currentDisplayedOrder && "hidden"}`}
                >
                  {orders?.map((order, index) => {
                    return (
                      <div key={index} className="flex space-x-5">
                        <img
                          className="aspect-square h-[100px] rounded object-cover"
                          src={order?.image || DEFAULT_DESIGN_IMAGE}
                          alt={order?.id}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = DEFAULT_DESIGN_IMAGE;
                          }}
                        />
                        <div className="flex flex-1 flex-col space-y-1 place-self-center text-[12px]">
                          <span>Order: #{order?.id}</span>
                          <span>Quantity: {order?.quantity || 1}</span>
                          {/* <span>
                            Price: {formatToCurrency(order?.price, CURRENCY)}
                          </span> */}
                          <div className="grid grid-cols-2 gap-2">
                            <MainButton
                              className="col-span-1 h-[35px] min-w-[80px]"
                              onClick={() => handleViewOrder(order)}
                            >
                              Track Order
                            </MainButton>
                            {order?.type === CART_ITEMS_TYPES.DESIGN && (
                              <MainButton
                                className="col-span-1 h-[35px] min-w-[80px]"
                                isLoading={
                                  currentReOrderedOrder === order?.itemId && (
                                  isReOrderingDesign ||
                                  isAddingCartItemLoading ||
                                  isPricingRequestLoading)
                                }
                                onClick={() => {
                                  handleDesignReorder(order?.itemId);
                                }}
                              >
                                Re-Order
                              </MainButton>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex h-full items-center justify-center">
                  <span className="font-medium lg:text-[18px]">
                    No orders in your account
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </Accordion>
    </div>
  );
}

export default OrdersList;
