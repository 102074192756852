export default class WishlistItem {
  id: number;
  item: number;
  image: string | null;
  price: number;
  createdAt: Date;
  updatedAt: Date;
  priceMode: string;

  constructor({
    id,
    item,
    image,
    price,
    createdAt,
    updatedAt,
    priceMode,
  }: {
    id: number;
    item: number;
    image: string | null;
    price: number;
    createdAt: Date;
    updatedAt: Date;
    priceMode: string;
  }) {
    this.id = id;
    this.item = item;
    this.image = image;
    this.price = price;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.priceMode = priceMode;
  }
}
