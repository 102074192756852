import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { MdErrorOutline } from "react-icons/md";
import { useMutation } from "react-query";
import Joi from "joi";

import PageWrapper from "../../components/pageWrapper";
import MainButton from "../../components/customButtons/MainButton";
import PasswordOutLinedField from "../../components/customInputField/PasswordOutLinedField";
import OutlinedField from "../../components/customInputField/OutlinedField";
import OtpInput from "../../components/otpInput";
import Alert from "../../components/alert";
import ROUTES from "../../constants/routes";
import validators from "../../constants/validators";
import { generateOTP, resetPassword } from "../../apis/authApi";
import { getErrorMessage } from "../../utils/errors";
import { formatSeconds } from "../../utils/time";

const ForgetPassword = () => {
  const remainingSecondsIntervalRef = useRef();
  const passwordFieldRef = useRef();
  const navigate = useNavigate();
  const [isOtpSended, setIsOtpSended] = useState(false);
  const [OTP, setOTP] = useState();
  const [email, setEmail] = useState();
  const [error, setError] = useState("");
  const [remainingSeconds, setRemainingSeconds] = useState(0);

  const emailSchema = Joi.object({ email: validators.email });

  const passwordsSchema = Joi.object({
    password: validators.password,
    confirmedPassword: validators.confirmedPassword,
  });

  useEffect(() => {
    return () => clearInterval(remainingSecondsIntervalRef.current);
  }, []);

  const { mutate: generateOTPMutation, isLoading: isGeneratingOTP } =
    useMutation((email) => generateOTP(email, true), {
      onSuccess: () => {
        setError("");
        setRemainingSeconds(60);
        setIsOtpSended(true);
        clearInterval(remainingSecondsIntervalRef.current);
        remainingSecondsIntervalRef.current = setInterval(() => {
          setRemainingSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
      },
      onError: (error) => {
        setError(getErrorMessage(error));
      },
    });

  const { mutate: resetPasswordMutation, isLoading: isResetPassword } =
    useMutation(
      ({ email, OTP, newPassword }) =>
        resetPassword({ email, OTP, newPassword }),
      {
        onSuccess: () => {
          navigate(ROUTES.SIGN_IN.path, { replace: true });
        },
        onError: (error) => {
          setError(getErrorMessage(error));
        },
      },
    );

  const handleResendOTP = () => {
    if (remainingSeconds <= 0) {
      generateOTPMutation(email);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    if (!isOtpSended) {
      const { email } = Object.fromEntries(new FormData(event.target));
      const { error } = emailSchema.validate({ email: email });

      if (error) {
        return setError(error.message);
      }
      
      setEmail(email);
      generateOTPMutation(email);
    } else {
      const { newPassword, confirmedNewPassword } = Object.fromEntries(
        new FormData(event.target),
      );
      if (!OTP || OTP?.length < 6) {
        return setError("Please enter valid OTP.");
      }

      const { error } = passwordsSchema.validate({
        password: newPassword,
        confirmedPassword: confirmedNewPassword,
      });

      if (error) {
        return setError(error.message);
      }
      resetPasswordMutation({ email, newPassword, OTP });
    }
  };

  return (
    <PageWrapper>
      <div className="grid grid-cols-1 lg:grid-cols-7 lg:gap-16">
        <img
          alt=""
          src="/auth.png"
          className="col-span-4 hidden lg:block lg:w-full"
        />
        <div className="col-span-3 flex flex-col space-y-4 text-primary lg:space-y-8">
          <span className="text-[24px] font-bold lg:text-[34px]">
            Reset your password
          </span>
          <form
            onSubmit={handleSubmit}
            noValidate
            className="flex flex-col space-y-5"
          >
            <Alert isOpen={error} message={error} severity={"error"} />
            {!isOtpSended ? (
              <OutlinedField
                id="email"
                name="email"
                label="Email"
                type="email"
                autoFocus
              />
            ) : (
              <>
                <div className="flex flex-col space-y-2">
                  <label
                    htmlFor="OTP"
                    className="mx-auto text-[16px] font-semibold text-primary"
                  >
                    OTP
                  </label>
                  <OtpInput
                    autoFocus
                    onComplete={(OTP) => {
                      setOTP(OTP);
                      passwordFieldRef?.current?.focus();
                    }}
                  />
                  <div className="flex space-x-2 text-sm text-primary">
                    <span className="text-secondary">Don't receive OTP?</span>
                    <span
                      onClick={handleResendOTP}
                      className={`text-secondary ${!formatSeconds(remainingSeconds) && "cursor-pointer hover:text-primary"}`}
                    >
                      {formatSeconds(remainingSeconds) || "RESEND OTP"}
                    </span>
                  </div>
                </div>
                <PasswordOutLinedField
                  innerRef={passwordFieldRef}
                  id="newPassword"
                  name="newPassword"
                  label="New Password"
                  required
                />
                <PasswordOutLinedField
                  id="confirmedNewPassword"
                  name="confirmedNewPassword"
                  label="Confirmed New Password"
                  required
                />
              </>
            )}
            <MainButton
              type="submit"
              className="ml-auto w-full lg:w-1/3"
              isLoading={isGeneratingOTP || isResetPassword}
            >
              {isOtpSended ? "Reset Password" : "Get OTP"}
            </MainButton>
          </form>
          <div className="flex flex-col space-y-2 text-[14px] font-light text-highlight lg:!mt-auto">
            <div className="!my-4 mx-auto w-[80%] border-b border-softGray"></div>
            <span>
              * The OTP is valid for a limited time, so make sure to complete
              the process promptly.
            </span>
            <span>
              * If you don't see the email in your inbox, check your spam or
              junk folder.
            </span>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ForgetPassword;
