import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { FaRegAddressCard } from "react-icons/fa6";
import { RiLockPasswordLine } from "react-icons/ri";

import ROUTES from "../../../constants/routes";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import Modal from "../../../components/modal";
import UserAddressesHandler from "../../../components/userAddresses";
import useUser from "../../../hooks/useUser";
import { formatDateToddMMyyyy } from "../../../utils/dates";

const PersonalDetails = () => {
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showAddressesModal, setShowAddressesModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { user } = useUser();

  return (
    <div className="relative flex h-fit w-full flex-col space-y-3 overflow-y-hidden rounded border-[0.3px] border-softGray p-3 shadow shadow-softGray lg:p-5">
      <div className="flex justify-between">
        <span className="font-medium">Personal details</span>
        <FaRegEdit
          className="ml-auto cursor-pointer text-secondary hover:text-primary"
          onClick={() => setShowEditProfileModal(true)}
        />
      </div>
      <div className="flex flex-col space-y-1">
        <div className="flex space-x-2">
          <span className="font-medium">Name:</span>
          <span>
            {user?.firstName} {user?.lastName}
          </span>
        </div>

        <div className="flex space-x-2">
          <span className="font-medium">Email:</span>
          <span>{user?.email}</span>
        </div>
        <div className="flex space-x-2">
          <span className="font-medium">Mobile Number:</span>
          <span>{user?.mobile}</span>
        </div>
        <div className="flex space-x-2">
          <span className="font-medium">Birthday:</span>
          <span>{formatDateToddMMyyyy(user?.birthdate)}</span>
        </div>

        <div
          className="group flex w-fit cursor-pointer items-center space-x-2 text-[14px]"
          onClick={() => setShowAddressesModal(true)}
        >
          <FaRegAddressCard size={20} />
          <span className="underline-offset-2 group-hover:underline">
            View Addresses ({user?.address?.length})
          </span>
        </div>
        <div
          className="group flex w-fit cursor-pointer items-center space-x-2 text-[14px]"
          onClick={() => setShowChangePasswordModal(true)}
        >
          <RiLockPasswordLine size={20} />
          <span className="underline-offset-2 group-hover:underline">
            Change Password
          </span>
        </div>
      </div>

      <Modal
        show={showEditProfileModal}
        onClose={() => setShowEditProfileModal(false)}
      >
        <EditProfile onCloseModal={() => setShowEditProfileModal(false)} />
      </Modal>
      <Modal
        show={showAddressesModal}
        onClose={() => setShowAddressesModal(false)}
      >
        <div className="flex w-full flex-col items-center space-y-3">
          <span className="text-[18px] font-bold text-primary">
            Your addresses
          </span>
          <UserAddressesHandler />
        </div>
      </Modal>
      <Modal
        show={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
      >
        <ChangePassword
          onPasswordChange={() => {
            setShowChangePasswordModal(false);
            window.location.href = ROUTES.SIGN_IN.path;
          }}
        />
      </Modal>
    </div>
  );
};

export default PersonalDetails;
