import { useQuery, useQueryClient } from "react-query";
import { getErrorMessage } from "../utils/errors";
import { getSession } from "../apis/sessionApi";
import { SESSION_QUERY_KEY } from "../queries-keys/sessions";

const useGetSession = () => {
  const queryClient = useQueryClient();

  const {
    data: session,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery(SESSION_QUERY_KEY(), getSession, {
    refetchOnWindowFocus: false,
    onError: () => {
      queryClient.setQueryData(SESSION_QUERY_KEY(), null);
    },
  });

  return {
    session,
    isLoading,
    isFetching,
    isError,
    error: getErrorMessage(error),
  };
};

export default useGetSession;
