import api from "./config/axiosWith401Redirect";

const Cart_URL = "/cart";

export const getCartItems = async () => {
  const response = await api.get(`${Cart_URL}/`);
  return response.data;
};

export const addCartItem = async (newCartItem) => {
  const response = await api.post(`${Cart_URL}/`, newCartItem);
  return response.data;
};

export const updateCartItem = async ({ cartItemId, updatedCartItemData }) => {
  const response = await api.patch(
    `${Cart_URL}/${cartItemId}/`,
    updatedCartItemData,
  );
  return response.data;
};

export const deleteCartItem = async (cartItemId) => {
  const response = await api.delete(`${Cart_URL}/${cartItemId}/`);
  return response.data;
};
