import {
  BrowserRouter as Router,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import AppRoutes from "../components/routes";
import Snackbar from "../components/snackbar";
import { SnackbarProvider } from "../components/snackbar/contexts/SnackbarProvider";

const router = createBrowserRouter([{ path: "*", Component: AppRoutes }]);

function App() {
  return (
    <SnackbarProvider>
      {/* <Router>
        <AppRoutes />
      </Router> */}
      <RouterProvider router={router} />
      <Snackbar />
    </SnackbarProvider>
  );
}

export default App;
