import React from "react";

import Skeleton from "../skeleton";

function ProductsSkeleton() {
  return Array.from({ length: 6 }).map((_, index) => {
    return (
      <div key={index} className="flex w-full flex-col space-y-2">
        <Skeleton className={"aspect-[11/9] w-full rounded"} />
        <Skeleton height={20} width={80} />
      </div>
    );
  });
}

export default ProductsSkeleton;
