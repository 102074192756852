import { useQuery } from "react-query";
import PageWrapper from "../../components/pageWrapper";
import Loader from "../../components/loader";
import Accordion from "../../components/accordion";
import useSnackbar from "../../components/snackbar/hooks/useSnackbar";
import { getFaqs } from "../../apis/faqApi";
import { getErrorMessage } from "../../utils/errors";

const FAQ = () => {
  const { handleOpenSnackbar } = useSnackbar();

  const { data: questions, isLoading } = useQuery("FAQS", getFaqs, {
    staleTime: Infinity,
    onError: (error) => {
      handleOpenSnackbar(getErrorMessage(error), "error");
    },
  });

  return (
    <PageWrapper>
      {isLoading ? (
        <Loader className={"min-h-[400px]"} />
      ) : (
        <div className="flex flex-col items-center space-y-3 lg:space-y-5">
          <h1 className="text-[24px] font-bold text-primary lg:text-[34px]">
            Frequently Asked Questions
          </h1>
          {questions?.map(({ question, answer }, index) => {
            return (
              <Accordion
                key={index}
                enable
                summary={question}
                className={
                  "max-w-[1000px] border-b border-gray-200 last:border-none"
                }
              >
                <p className="whitespace-pre-wrap px-3 pb-3 text-[14px] text-secondary">
                  {answer}
                </p>
              </Accordion>
            );
          })}
        </div>
      )}
    </PageWrapper>
  );
};

export default FAQ;
