import React from "react";
import { Outlet } from "react-router";
import { useOutletContext } from "react-router-dom";

import Header from "../components/header";
import Footer from "../components/footer";

function MainLayout() {
  return (
    <>
      <Header />
      <Outlet context={useOutletContext()} />
      <Footer />
    </>
  );
}

export default MainLayout;
