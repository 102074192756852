import React, { useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaCartPlus } from "react-icons/fa";
import { GoScreenFull } from "react-icons/go";
import { MdDeleteForever } from "react-icons/md";
import { TbReportMoney } from "react-icons/tb";
import ROUTES from "../../../constants/routes";
import useAddCartItem from "../../../hooks/useAddCartItem";
import useDeleteDesign from "../../../hooks/useDeleteDesign";
import useGetCart from "../../../hooks/useGetCart";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import Gallery from "../../../components/gallery";
import Accordion from "../../../components/accordion";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { getWishlistItems } from "../../../apis/wishlistAPI";
import { WISHLIST_QUERY_KEY } from "../../../queries-keys/wishlist";
import { CART_ITEMS_TYPES } from "../../../constants/cartItems";
import { DEFAULT_DESIGN_IMAGE } from "../../../constants/imagesUrls";
import { PRICE_MODES } from "../../../constants/priceModes";
import useCreateDesignPricingRequest from "../../../hooks/useCreateDesignPricingRequest";

function Wishlist() {
  const isMaxWidth1024 = useMediaQuery("(max-width: 1024px)");
  const [openGallery, setOpenGallery] = useState(false);
  const [currentViewedImage, setCurrentViewedImage] = useState(0);
  const { handleOpenSnackbar } = useSnackbar();
  const designRoute = ROUTES?.DESIGNS?.path;

  const { data: designs, isLoading } = useQuery(
    WISHLIST_QUERY_KEY(),
    getWishlistItems,
  );

  const { cartItems } = useGetCart();
  const { addCartItemMutation } = useAddCartItem();
  const { deleteDesignMutation } = useDeleteDesign();
  const { createDesignPricingRequestMutation } = useCreateDesignPricingRequest();

  const handleAddToCart = (design) => {
    const found = cartItems.some(
      (cartItem) =>
        cartItem?.type === CART_ITEMS_TYPES.DESIGN &&
        cartItem[cartItem?.type]?.id === design.id,
    );

    if (found) {
      return handleOpenSnackbar("This design already in your cart", "info");
    }

    if(design?.priceMode !== PRICE_MODES.ON ){
      createDesignPricingRequestMutation(design.id)
    }

    addCartItemMutation({
      design: design.id,
      type: CART_ITEMS_TYPES.DESIGN,
      quantity: 1,
    });

  };

  const handleViewImage = (imageIdx) => {
    setCurrentViewedImage(imageIdx);
    setOpenGallery(true);
  };

  return (
    <>
      <div className="w-full select-none space-y-3 overflow-y-hidden rounded border-[0.3px] border-softGray p-3 shadow shadow-softGray lg:h-full lg:p-5">
        <Accordion enable={isMaxWidth1024} summary={"Wishlist"}>
          {isLoading ? (
            <div className="flex items-center justify-center lg:h-[500px]">
              <MoonLoader color="#24595C" />
            </div>
          ) : designs?.length ? (
            <div className="grid grid-cols-2 content-start gap-2 overflow-y-auto pr-2 lg:h-[500px]">
              {designs?.map((design, index) => {
                return (
                  <div
                    key={index}
                    className="group relative h-32 w-full overflow-hidden hover:z-10 hover:scale-105"
                  >
                    <div className="absolute left-0 top-0 hidden h-full w-full bg-gray-900 opacity-70 group-hover:block" />
                    <GoScreenFull
                      size={30}
                      onClick={() => handleViewImage(index)}
                      className="absolute left-1/2 top-1/2 hidden -translate-x-1/2 -translate-y-1/2 transform cursor-pointer text-highlight hover:scale-110 hover:text-red-700 group-hover:block"
                    />
                    <div className="absolute bottom-2 hidden w-full justify-evenly group-hover:flex">
                      <NavLink to={`${designRoute}/${design?.id}`}>
                        <BiSolidEditAlt
                          title="Edit design"
                          size={30}
                          className="cursor-pointer text-highlight hover:scale-110 hover:text-red-700"
                        />
                      </NavLink>
                      {design?.priceMode === PRICE_MODES.ON 
                      ? (
                        <FaCartPlus
                          size={30}
                          title = "Add to cart"
                          className="cursor-pointer text-highlight hover:scale-110 hover:text-red-700"
                          onClick={() => handleAddToCart(design)}
                        />
                      )
                      : (
                        <TbReportMoney
                          size={30}
                          title = "Request pricing"
                          className="cursor-pointer text-highlight hover:scale-110 hover:text-red-700"
                          onClick={() => handleAddToCart(design)}
                        />
                      )}
                      <MdDeleteForever
                        size={30}
                        title="Delete design"
                        className="cursor-pointer text-highlight hover:scale-110 hover:text-red-700"
                        onClick={() => deleteDesignMutation(design?.id)}
                      />
                    </div>
                    <img
                      src={design.image || DEFAULT_DESIGN_IMAGE}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = DEFAULT_DESIGN_IMAGE;
                      }}
                      className="h-full w-full object-cover"
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center justify-center lg:h-[500px]">
              <span className="font-medium lg:text-[18px]">
                No designs in your wishlist
              </span>
            </div>
          )}
        </Accordion>
      </div>
      {designs?.length > 0 && (
        <Gallery
          open={openGallery}
          images={designs.map((design) => design?.image)}
          startImageIdx={currentViewedImage}
          onClose={() => setOpenGallery(false)}
        />
      )}
    </>
  );
}

export default Wishlist;
