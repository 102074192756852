import { useDispatch, useSelector } from "react-redux";

import {
  selectUser,
  selectIsLoggedIn,
  setUser,
  removeUser,
} from "../features/userSlice";

const useUser = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const deleteUser = () => {
    dispatch(removeUser());
  };

  const saveUser = (user) => {
    dispatch(setUser({ ...(user || {}) }));
  };

  return {
    user,
    isLoggedIn,
    saveUser,
    deleteUser,
  };
};

export default useUser;
