import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import ROUTES from "../constants/routes";

export default function useSetDocumentTitleFromRoute() {
  const location = useLocation();

  useEffect(() => {
    const currentRoute = Object.values(ROUTES).find(
      (route) =>
        route.path === location.pathname && route.path !== ROUTES.HOME.path,
    );

    document.title = `WAE${
      currentRoute?.label ? " · " + currentRoute.label : ""
    }`;
  }, [location.pathname]);
}
