import React, { useRef, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { NavLink } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import Joi from "joi";

import PageWrapper from "../../components/pageWrapper";
import Alert from "../../components/alert";
import OutlinedField from "../../components/customInputField/OutlinedField";
import MainButton from "../../components/customButtons/MainButton";
import SvgIcon from "../../components/svgIcon";
import useGetAdminConfigs from "../../hooks/useGetAdminConfigs";
import useSnackbar from "../../components/snackbar/hooks/useSnackbar";
import validators from "../../constants/validators";
import { getErrorMessage } from "../../utils/errors";
import { SOCIAL_MEDIA_QUERY_KEY } from "../../queries-keys/about";
import { getSocialMedia } from "../../apis/aboutApi";
import { submitInquiry } from "../../apis/contactUsApi";

function ContactUs() {
  const fromRef = useRef();
  const [error, setError] = useState();
  const { handleOpenSnackbar } = useSnackbar();

  const emailSchema = Joi.object({ email: validators.email });

  const {
    configs: {
      contactUsMapLink,
      contactUsEmail,
      contactUsPhone,
      contactUsAddress,
    },
    isLoadingConfigs,
  } = useGetAdminConfigs();

  const { data: socialMedia, isLoading: isLoadingSocialMedia } = useQuery(
    SOCIAL_MEDIA_QUERY_KEY(),
    getSocialMedia,
    {
      staleTime: Infinity,
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    },
  );

  const { mutate: submitInquiryMutation, isLoading: isSubmittingInquiry } =
    useMutation((data) => submitInquiry(data), {
      onSuccess: () => {
        fromRef?.current?.reset();
        handleOpenSnackbar("Your inquiry submitted successfully", "success");
      },
      onError: (error) => {
        setError(getErrorMessage(error));
      },
    });

  const handleSubmit = (event) => {
    event.preventDefault();

    setError("");

    const form = event.target;
    const { name, email, message } = Object.fromEntries(new FormData(form));

    if (!name) {
      return setError("Name is required");
    }

    if (!message) {
      return setError("Message is required");
    }

    const { error } = emailSchema.validate({ email: email });
    if (error) {
      return setError(error.message);
    }

    submitInquiryMutation({ name, email, message });
  };

  return (
    <PageWrapper>
      <div className="flex flex-col space-y-5 text-primary lg:space-y-10">
        <div className="grid grid-cols-1 lg:grid-cols-7 lg:gap-24">
          <div className="col-span-3 flex flex-col space-y-3 text-primary lg:space-y-5">
            <span className="text-[24px] font-bold lg:text-[34px]">
              Contact Us
            </span>
            <form
              ref={fromRef}
              onSubmit={handleSubmit}
              className="flex flex-col space-y-5"
              noValidate
            >
              <Alert isOpen={error} message={error} severity={"error"} />
              <OutlinedField
                autoFocus
                id="name"
                name="name"
                label="Full Name"
                onChange={() => setError()}
              />
              <OutlinedField
                id="email"
                name="email"
                label="Email"
                type="email"
                onChange={() => setError()}
              />
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="message"
                  className="text-[16px] font-semibold text-primary"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="3"
                  onChange={() => setError()}
                  className="w-full resize-none rounded-md border p-3 px-4 py-2 outline-none focus:ring-2 focus:ring-primary lg:w-full "
                />
              </div>
              <MainButton
                type="submit"
                className="ml-auto w-full lg:w-1/3"
                isLoading={isSubmittingInquiry}
              >
                Submit
              </MainButton>
            </form>
          </div>
          <div className="col-span-4 hidden flex-col space-y-3 lg:flex lg:w-full lg:space-y-5">
            {contactUsMapLink ? (
              <iframe
                src={contactUsMapLink}
                allowFullScreen
                className="h-full w-full"
              />
            ) : (
              <img
                alt=""
                src="/auth.png"
                className="h-full w-full object-cover"
              />
            )}
          </div>
        </div>
        {!!socialMedia?.length && (
          <div className="flex items-center space-x-5 lg:space-x-10">
            <span className="flex-1 border-b border-secondary" />
            {socialMedia?.map(({ name, icon, url }) => {
              return (
                <NavLink key={name} to={url}>
                  <SvgIcon
                    src={icon}
                    className={
                      "h-[30px] w-[30px] shrink-0 !stroke-primary"
                    }
                  />
                </NavLink>
              );
            })}
            <span className="flex-1 border-b border-secondary" />
          </div>
        )}
        <div className="flex flex-col space-y-3 text-primary lg:flex-row lg:justify-evenly lg:space-x-5 lg:space-y-0">
          {contactUsEmail && (
            <div className="flex items-center space-x-2">
              <MdOutlineMailOutline className="shrink-0 text-[40px] lg:text-[50px]" />
              <div className="flex flex-col">
                <span className="text-[14px] font-medium">Email:</span>
                <span className="text-[14px] text-secondary">
                  {contactUsEmail}
                </span>
              </div>
            </div>
          )}
          {contactUsAddress && (
            <div className="flex items-center space-x-2">
              <IoLocationOutline className="shrink-0 text-[40px] lg:text-[50px]" />
              <div className="flex flex-col">
                <span className="text-[14px] font-medium">Location:</span>
                <span className="text-[14px] text-secondary">
                  {contactUsAddress}
                </span>
              </div>
            </div>
          )}
          {contactUsPhone && (
            <div className="flex items-center space-x-2">
              <FiPhoneCall className="shrink-0 text-[40px] lg:text-[50px]" />
              <div className="flex flex-col">
                <span className="text-[14px] font-medium">Number:</span>
                <span className="text-[14px] text-secondary">
                  {contactUsPhone}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
}

export default ContactUs;
