import api from "./config/axiosWith401Redirect";
import WishlistItem from "../models/WishlistItem";

const DESIGNS_URL = "/designs/user/";

export const getWishlistItems = async () => {
  const response = await api.get(DESIGNS_URL);

  const wishlistItems =
    response?.data?.map((item) => {
      return new WishlistItem({
        id: item.id,
        item: item.item,
        image: item.image,
        price: item.price,
        createdAt: item.created_at,
        updatedAt: item.updated_at,
        priceMode: item.price_mode,
      });
    }) || [];

  return wishlistItems;
};
