import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { PiClockThin } from "react-icons/pi";

import SvgIcon from "../svgIcon";
import useRemainingTime from "./hooks/useRemainingTime";
import { CLOCK_ICON_URL } from "../../constants/iconsUrls";

function CountDown({ time, onExpire, beforeExpire }) {
  const timeRef = useRef(new Date().getTime() + time);
  const [isCountDownVisible, setIsCountDownVisible] = useState(false);
  const remainingTime = useRemainingTime(timeRef.current);

  const showCountDown = () => {
    setIsCountDownVisible(true);
  };

  const hideCountDown = () => {
    setIsCountDownVisible(false);
  };

  useEffect(() => {
    if (!remainingTime?.hours && !remainingTime?.minutes && remainingTime?.seconds <= 0) {
      onExpire && onExpire();
    }

    if (!remainingTime?.hours && !remainingTime?.minutes && remainingTime?.seconds === 10) {
      beforeExpire && beforeExpire();
    }
  }, [remainingTime?.seconds]);

  return (
    <div className="relative w-fit">
      {!remainingTime?.hours &&
        !remainingTime?.minutes &&
        remainingTime?.seconds < 10 &&
        remainingTime?.seconds > 0 && (
          <span
            onMouseEnter={showCountDown}
            onMouseLeave={hideCountDown}
            className={`absolute h-full w-full animate-ping cursor-pointer rounded-full bg-primary opacity-75`}
          />
        )}
      <SvgIcon
        src={CLOCK_ICON_URL}
        onMouseEnter={showCountDown}
        onMouseLeave={hideCountDown}
        className={`h-[30px] !stroke-secondary hover:cursor-pointer hover:!stroke-primary lg:h-[40px]`}
      />
      <div
        className={`transition-max-w absolute left-[calc(100%+2px)] top-0 flex min-w-0 h-[30px] items-center justify-center space-x-1 overflow-hidden rounded rounded-b-full rounded-t-full border 
        border-gray-300 bg-white bg-opacity-30 px-3 text-center text-[14px] font-bold text-primary shadow backdrop-blur duration-300 lg:h-[40px] lg:text-[18px] 
        ${isCountDownVisible ? "max-w-auto" : "max-w-0 border-none !p-0"}`}
      >
        <span className="">{remainingTime?.hours}</span>
        <span className="">:</span>

        <span className="">{remainingTime?.minutes}</span>
        <span className="">:</span>

        <span className="">{remainingTime?.seconds}</span>
      </div>
    </div>
  );
}

CountDown.propTypes = {
  time: PropTypes.number.isRequired,
  onExpire: PropTypes.func,
  beforeExpire: PropTypes.func,
};

export default CountDown;
