import React, { useLayoutEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useQuery } from "react-query";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import Stepper from "../../../components/stepper";
import useStepper from "../../../components/stepper/hooks/useStepper";
import useFilter from "../hooks/useFilter";
import OptionsContainer from "./OptionsContainer";
import DesignSummary from "./DesignSummary";
import OptionsFilter from "./OptionsFilter";
import FavoritesPanel from "./FavoritesPanel";
import DimensionFilter from "./DimensionFilter";
import OptionsShuffle from "./OptionsShuffle";
import Table from "../../../components/table";
import ServiceInfo from "../../../components/ServiceInfo";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { formatToCurrency } from "../../../utils/numbers";
import { getErrorMessage } from "../../../utils/errors";
import { generateShapesAndMaterialsSteps } from "../../../utils/designs";
import { DESIGN_CATEGORY, LAYOUT_TYPES } from "../../../constants/designs";
import {
  MEASUREMENTS_ICON_URL,
  MATERIALS_ICON_URL,
  COLORS_ICON_URL,
} from "../../../constants/iconsUrls";
import { ELEMENTS_QUERY_KEY } from "../../../queries-keys/elements";
import { getElements } from "../../../apis/elementsApi";
import { setShape,setMaterial } from "../../../features/designSlice";
import { CURRENCY } from "../../../constants/common";

function DesignsSelector() {
  const { item, layouts } = useOutletContext() || {};
  const isMaxWidth1024 = useMediaQuery("(max-width: 1024px)");
  const layout_body_items = layouts?.layout_body;
  const layout_size_items = layouts?.layout_size;
  const layoutStep = {
    icon: MEASUREMENTS_ICON_URL,
    label: DESIGN_CATEGORY.LAYOUT,
    subSteps: [
      {
        label: "First Layout",
        data: {
          options: layout_size_items,
          category: DESIGN_CATEGORY.LAYOUT,
          type: LAYOUT_TYPES.LAYOUT_SIZE,
        },
      },
      {
        label: "Second Layout",
        data: {
          options: layout_body_items,
          category: DESIGN_CATEGORY.LAYOUT,
          type: LAYOUT_TYPES.LAYOUT_BODY,
        },
      },
    ],
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getState } = useStore();
  const { handleOpenSnackbar } = useSnackbar();
  const { prevRoute = "/" } = useSelector((state) => state.app);
  const [steps, setSteps] = useState([layoutStep]);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const {
    currentStep,
    isFinalStep,
    currentStepTitle,
    currentStepData,
    nextStep,
    nextButtonDisabled,
    previousStep,
    prevButtonDisabled,
  } = useStepper();
  const { filteredOptions } = useFilter(currentStepData?.options, currentStepTitle);
  const selectedLayout = useSelector((state) => state.design.layout);
  const selectedElements = useSelector((state) => state.design.elements);
  const pricesTable = useSelector((state) => state.design.prices);
  const totalPricePerStep = useSelector((state) => state.design.totalPricePerStep);
  const totalPriceRangePercentagePerStep = useSelector((state) => state.design.totalPriceRangePercentagePerStep);
  const priceMode = useSelector((state) => state.design.priceMode);

  const isLayout = currentStepData?.category === DESIGN_CATEGORY.LAYOUT;
  const isLayoutBody = currentStepData?.type === LAYOUT_TYPES.LAYOUT_BODY;
  const isLayoutSize = currentStepData?.type === LAYOUT_TYPES.LAYOUT_SIZE;
  const isShape = currentStepData?.category === DESIGN_CATEGORY.SHAPE;
  const isMaterial = currentStepData?.category === DESIGN_CATEGORY.MATERIAL;

  const isNextStepDisabled =
    (isLayoutBody && !selectedLayout.layout_body) ||
    (isLayoutSize && !selectedLayout.layout_size) ||
    (isShape &&
      (currentStepData?.options.length === 0 || !selectedElements[currentStepData?.elementId]));

  const { data: elements = [], isLoading: isLoadingLayoutElements } = useQuery({
    queryKey: ELEMENTS_QUERY_KEY(selectedLayout?.layout_body?.id, selectedLayout?.layout_size?.id),
    queryFn: () => getElements(selectedLayout?.layout_body?.id, selectedLayout?.layout_size?.id),
    onError: (error) => {
      handleOpenSnackbar(getErrorMessage(error), "error");
    },
    onSuccess: (elements) => {
      generateSteps(elements);
    },
    staleTime: Infinity,
    enabled: Boolean(selectedLayout?.layout_body?.id && selectedLayout?.layout_size?.id),
  });

  const selectShape = (shapeId = null, elementIndex, elements, selectedElements) => {
    let selectedShape = elements[elementIndex]?.shapes[0];
    elements[elementIndex]?.shapes.forEach((shape) => {
      if (shape?.id === shapeId) selectedShape = shape;
    });
    if (selectedElements[elements[elementIndex]?.id]?.shape?.id !== selectedShape?.id) {
      dispatch(
        setShape({
          shape: { ...selectedShape },
          elementId: elements[elementIndex]?.id,
          elementName: elements[elementIndex]?.name,
        })
      );
    }
    if (elementIndex + 1 < elements.length && selectedShape?.compatabile_shapes.length > 0) {
      let nextShapeId = selectedElements[elements[elementIndex + 1]?.id]?.shape?.id;
      if (!selectedShape?.compatabile_shapes.includes(nextShapeId)) {
        nextShapeId = selectedShape?.compatabile_shapes[0];
      }
      selectShape(nextShapeId, elementIndex + 1, elements, selectedElements);
    }
  };

  const selectMaterial = (materialId = null, partIndex = 0, elementIndex, elements, selectedElements, compatibility_prefix = null) => {
    if (!compatibility_prefix){
      compatibility_prefix=Object.values(selectedElements).map(element => element?.shape?.code).sort().join(",")
    }    
    let materials = selectedElements[elements[elementIndex]?.id]?.shape?.materials || {};
    let materialsNames = Object.keys(materials).sort();
    let materialName = materialsNames[partIndex];
    if(!materialId && selectedElements[elements[elementIndex]?.id]?.material)
      materialId = selectedElements[elements[elementIndex]?.id]?.material[materialName]?.id
    let compatabile_materials = []
    materials[materialName]?.forEach((material) => {
      if(material.compatibility_prefixes?.includes(compatibility_prefix)) {
        compatabile_materials.push(material)
      }
    })
    let selectedMaterial = compatabile_materials.find((material) => materialId && material.id === materialId);
    if (!selectedMaterial) selectedMaterial = compatabile_materials[0];
    if(!selectedMaterial) return;
    dispatch(
      setMaterial({
        material: { ...selectedMaterial },
        elementId: elements[elementIndex]?.id,
        elementName: elements[elementIndex]?.name,
        materialName: materialName,
        isSet: partIndex === 0
      })
    );
    compatibility_prefix += ","
    if(partIndex === 0) compatibility_prefix += "["
    compatibility_prefix += selectedMaterial.code
    if (partIndex + 1 < materialsNames.length) {
      selectMaterial(null, partIndex + 1, elementIndex, elements, selectedElements, compatibility_prefix);
    }
    else if(elementIndex + 1 < elements.length) {
      compatibility_prefix += "]"
      selectMaterial(null, 0, elementIndex + 1, elements, selectedElements, compatibility_prefix);
    }
    
  }


  const generateSteps = (elements) => {
    if (!elements || elements.length === 0) return;
    let selectedElements = getState().design.elements;
    selectShape(selectedElements[elements[0]?.id]?.shape?.id, 0, elements, selectedElements);
    selectedElements = getState().design.elements;
    selectMaterial(null, 0, 0, elements, selectedElements);
    selectedElements = getState().design.elements;
    let [shapesSteps, materialsSteps] = generateShapesAndMaterialsSteps(elements, selectedElements);

    setSteps([
      steps[0],
      {
        label: DESIGN_CATEGORY.SHAPE,
        icon: COLORS_ICON_URL,
        subSteps: shapesSteps,
      },
      {
        label: DESIGN_CATEGORY.MATERIAL,
        icon: MATERIALS_ICON_URL,
        subSteps: materialsSteps,
      },
    ]);
  };

  useLayoutEffect(() => {
    if (Boolean(selectedLayout?.layout_body?.id && selectedLayout?.layout_size?.id && elements?.length !== 0)) {
      generateSteps(elements);
    } else {
      setSteps([
        steps[0],
        {
          label: DESIGN_CATEGORY.SHAPE,
          icon: COLORS_ICON_URL,
          subSteps: [],
        },
        {
          label: DESIGN_CATEGORY.MATERIAL,
          icon: MATERIALS_ICON_URL,
          subSteps: [],
        },
      ]);
    }
  }, [selectedLayout]); // ToDO: add elements, generateSteps, steps to dependency array

  return (
    <div
      className={`relative col-span-3 flex w-full flex-col space-y-5 ${
        isLayout && "mx-auto max-w-[1000px]"
      }`}
    >
      {isLoadingLayoutElements && (
        <div className="absolute left-0 top-0 z-[1] !m-0 flex h-full w-full items-center justify-center">
          <AiOutlineLoading3Quarters size={50} className="shrink-0 animate-spin text-primary" />
        </div>
      )}
      <div className="flex flex-col space-y-3">
        <span className="hidden text-[34px] font-bold text-primary lg:block">
          {item?.name} Design
        </span>
        <Stepper
          steps={steps}
          disableNext={isNextStepDisabled}
          disableJumping={isNextStepDisabled}
        />
      </div>
      <div className="flex flex-col space-y-3">
        {isFinalStep ? (
          <DesignSummary />
        ) : (
          <>
            <div className="flex items-center justify-between text-primary">
              <span className="text-[18px] font-bold">{currentStepData?.category}</span>
              <div className="flex items-center mr-1 space-x-5">
                {!isLayout && (
                  <OptionsShuffle
                    elements={elements}
                    options={filteredOptions}
                    setSteps={setSteps}
                    selectShape={selectShape}
                    selectMaterial={selectMaterial}
                  />
                )}
                {(isMaterial || isShape) && (
                  <OptionsFilter
                    open={isFilterDialogOpen}
                    onClose={() => setIsFilterDialogOpen(false)}
                    toggle={() => setIsFilterDialogOpen((prev) => !prev)}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-[14px] text-primary">{currentStepTitle}</span>
              {currentStepData?.elementNotes && (
                <span className="text-[13px] text-secondary">{currentStepData?.elementNotes}</span>
              )}
            </div>
            {isShape && <DimensionFilter />}
            {!isLayout && !!currentStepTitle && (
              <FavoritesPanel selectShape={selectShape} selectMaterial={selectMaterial} setSteps={setSteps} elements={elements} />
            )}
            <OptionsContainer
              elements={elements}
              options={filteredOptions}
              setSteps={setSteps}
              selectShape={selectShape}
              selectMaterial={selectMaterial}
            />
          </>
        )}
      </div>
      <div className="flex items-center justify-between text-[18px] lg:justify-evenly">
        <div
          className={`flex items-center space-x-2 cursor-pointer text-highlight hover:text-red-700 hover:underline underline-offset-2`}
          onClick={prevButtonDisabled ? () => navigate(prevRoute) : previousStep}
        >
          <MdKeyboardArrowLeft size={25} />
          <span>Previous</span>
        </div>
        <div
          className={`flex items-center space-x-2 ${
            nextButtonDisabled
              ? "cursor-default text-softGray"
              : "cursor-pointer text-highlight hover:text-red-700 hover:underline underline-offset-2"
          }`}
          onClick={nextStep}
        >
          <span>Next</span>
          <MdKeyboardArrowRight size={25} />
        </div>
      </div>
      {isMaxWidth1024 && (
        <>
          {!isFinalStep && (
            <>
              <Table rows={pricesTable.slice(0,currentStep)}/>
              <div className="flex items-center justify-between px-5 text-primary">
                <span className="text-[18px] font-medium">Total Price</span>
                <span className="text-[24px] font-bold">
                  {formatToCurrency(
                    totalPricePerStep[currentStep-1],
                    CURRENCY,
                    totalPriceRangePercentagePerStep[currentStep-1],
                    priceMode
                  )}
                </span>
              </div>
            </>
          )}
          <ServiceInfo itemId={item?.id} />
        </>
      )}
    </div>
  );
}

export default DesignsSelector;
