
import { useQuery } from "react-query";
import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getConsultantNextSlots } from "../apis/consultationApi";
import { CONSULTANT_NEXT_SLOTS } from "../queries-keys/consultation";
import { getErrorMessage } from "../utils/errors";

const useGetConsultantNextSlots = (roomId) => {
  const { handleOpenSnackbar } = useSnackbar();

  const { data: consultantNextSlots, isLoading: isFetchingNextSlots } = useQuery({
    queryKey: CONSULTANT_NEXT_SLOTS(),
    queryFn: getConsultantNextSlots,
    onError: (error) => {
      handleOpenSnackbar(
        getErrorMessage(error),
        "error",
      );
    },
    },
  );

  return {
    consultantNextSlots,
    isFetchingNextSlots,
  };
};

export default useGetConsultantNextSlots;

