import { useContext } from "react";

import StepperContext from "../contexts/StepperProvider";

const useStepper = () => {
  const {
    currentStep,
    allStepsCount,
    isFinalStep,
    currentStepTitle,
    currentStepData,
    setCurrentStep,
    prevButtonDisabled,
    nextButtonDisabled,
  } = useContext(StepperContext);

  const nextStep = () => {
    if (nextButtonDisabled) {
      return;
    }

    setCurrentStep((prevValue) => {
      return Math.min(prevValue + 1, allStepsCount + 1);
    });
  };

  const previousStep = () => {
    if (prevButtonDisabled) {
      return;
    }

    setCurrentStep((prevValue) => {
      return Math.max(prevValue - 1, 1);
    });
  };

  return {
    currentStep,
    currentStepTitle,
    currentStepData,
    isFinalStep,
    nextStep,
    previousStep,
    prevButtonDisabled,
    nextButtonDisabled,
  };
};

export default useStepper;
