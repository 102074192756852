import React, { useState } from "react";

import Modal from "../../../components/modal";
import UpdateConsultationSlotNotes from "./UpdateConsultationSlotNotes";
import ClientConsultationHistory from "./ClientConsultationHistory";
import OutlineButton from "../../../components/customButtons/OutlineButton";
import { formatDateToddMMyyyy } from "../../../utils/dates";

function ConsultationSlotCard({ slot }) {
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showClientHistoryModel, setShowClientHistoryModel] = useState(false);
  return (
    <>
      <Modal show={showNotesModal} onClose={() => setShowNotesModal(false)}>
        <UpdateConsultationSlotNotes slot={slot} />
      </Modal>
      <Modal
        show={showClientHistoryModel}
        onClose={() => setShowClientHistoryModel(false)}
        className="lg:w-8/12"
      >
        <ClientConsultationHistory
          client_id={slot?.client_id}
          client_first_name={slot?.client_first_name}
          client_last_name={slot?.client_last_name}
        />
      </Modal>
      <div className="flex flex-col space-y-1 rounded p-2">
        <span className="text-center font-semibold text-highlight">
          {slot?.type} Slot
        </span>
        <div className="flex space-x-2">
          <span className="font-medium">Client name:</span>
          <span>
            {slot?.client_first_name} {slot?.client_last_name}
          </span>
        </div>
        <div className="flex space-x-2">
          <span className="font-medium">Date:</span>
          <span>{formatDateToddMMyyyy(slot?.start)}</span>
        </div>
        <div className="flex space-x-2">
          <span className="font-medium">Time:</span>
          <span>{slot?.start?.slice(11, 16)}</span>
        </div>
        {slot?.notes && (
          <div className="flex space-x-2">
            <span className="font-medium">Notes:</span>
            <span className="whitespace-pre-wrap">{slot?.notes}</span>
          </div>
        )}
        <div className="flex w-full">
          <OutlineButton
            onClick={() => setShowNotesModal(true)}
            className="w-full rounded-r-none border-r-0 py-1"
          >
            {slot?.notes ? "Update Notes" : "Add Notes"}
          </OutlineButton>
          <OutlineButton
            onClick={() => setShowClientHistoryModel(true)}
            className="w-full rounded-l-none py-1"
          >
            Client History
          </OutlineButton>
        </div>
      </div>
    </>
  );
}

export default ConsultationSlotCard;
