export const SEARCH_ICON_URL = `/resources/icons/search.svg`;
export const MATERIALS_ICON_URL = `/resources/icons/materials.svg`;
export const COLORS_ICON_URL = `/resources/icons/colours.svg`;
export const MEASUREMENTS_ICON_URL = `/resources/icons/measurements.svg`;
export const CART_ICON_URL = `/resources/icons/cart.svg`;
export const CLOCK_ICON_URL = `/resources/icons/clock.svg`;
export const DELIVERY_ICON_URL = `/resources/icons/delivery.svg`;
export const USER_ICON_URL = `/resources/icons/user.svg`;
export const SHUFFLE_ICON_URL = `/resources/icons/shuffle.svg`;
export const SAVE_ICON_URL = `/resources/icons/save.svg`;
export const FILTER_ICON_URL = `/resources/icons/filter.svg`;
