import React from "react";

import PageWrapper from "../../components/pageWrapper";
import Loader from "../../components/loader";
import useGetCart from "../../hooks/useGetCart";
import CartItemsTable from "./components/CartItemsTable";
import CheckoutSummery from "./components/CheckoutSummery";

const Cart = () => {
  const { cartData, noPriceCartData, totalPrice, isLoadingCartItems } =
    useGetCart();

  return (
    <PageWrapper>
      {isLoadingCartItems ? (
        <Loader className={"min-h-[400px]"} />
      ) : (
        <div className="flex flex-col space-y-3 text-primary lg:space-y-5">
          <span className="text-[24px] font-bold lg:text-[34px]">Cart</span>
          <div className="flex flex-col space-y-3 lg:flex-row lg:space-x-10 lg:space-y-0">
            <CartItemsTable
              cartData={cartData}
              noPriceCartData={noPriceCartData}
            />
            <CheckoutSummery {...{ cartData, noPriceCartData, totalPrice }} />
          </div>
        </div>
      )}
    </PageWrapper>
  );
};

export default Cart;
