import { createContext, useState, useMemo, useRef } from "react";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const timeoutIdRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");

  const snackbarObject = useMemo(() => {
    return {
      isOpen,
      setIsOpen,
      message,
      setMessage,
      severity,
      setSeverity,
      timeoutIdRef,
    };
  }, [
    isOpen,
    setIsOpen,
    message,
    setMessage,
    severity,
    setSeverity,
    timeoutIdRef,
  ]);

  return (
    <SnackbarContext.Provider value={snackbarObject}>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContext;
