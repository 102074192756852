import React from "react";

export default function Table({ rows }) {
  const maxRowLength = rows.reduce(
    (maxRowLength, row) => Math.max(maxRowLength, row?.length),
    0,
  );
  return (
    <div className="flex flex-col">
      {rows?.map((row, index) => {
        return (
          <React.Fragment key={index}>
            <TableRow row={row} maxRowLength={maxRowLength} />
          </React.Fragment>
        );
      })}
    </div>
  );
}

function TableRow({ row, maxRowLength }) {
  return (
    <div
      className={`grid grid-cols-${maxRowLength+1} grid-rows-1 justify-center rounded odd:bg-gray-100`}
    >

      {row?.map((field, index) => {
        return (
          <span
            key={index}
            className={`col-span-${(index==0 ? 2 : 1)} p-1 text-center text-[12px] text-primary`}
          >
            {field}
          </span>
        );
      })}
    </div>
  );
}
