import { PRICE_MODES } from "../constants/priceModes";

export const calculatePercentageAmount = (amount, percentage) => {
  return amount + (amount * percentage) / 100.0;
};

export const formatToUSDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
}).format;

export const formatToCurrency = (
  amount,
  currency = "EGP",
  percentage = 0,
  priceMode = PRICE_MODES.ON,
  currencySign = true,
) => {
  amount = +amount;
  percentage = +percentage;
  if (priceMode === PRICE_MODES.OFF) return "";
  else if (priceMode === PRICE_MODES.ON) {
    return amount && amount >= 0
      ? amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + (currencySign ? " "+currency : "")
      : "";
  } else if (priceMode === PRICE_MODES.RANGE) {
    if (!(amount && amount >= 0)) return "";
    const amountR = calculatePercentageAmount(amount, percentage);
    return (
      amount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) +
      " : " +
      amountR.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) +
      (currencySign ? " "+currency : "")
    );
  }
};
