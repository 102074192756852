import React, { useRef, useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { TfiReload } from "react-icons/tfi";

import useFilterLogic from "../hooks/useFilterLogic";
import SvgIcon from "../../../components/svgIcon";
import { FILTER_ICON_URL } from "../../../constants/iconsUrls";

function OptionsFilter({ open, toggle = () => {}, onClose = () => {} }) {
  const filterRef = useRef();
  const [showAllColors, setShowAllColors] = useState(false);
  const [showAllMaterials, setShowAllMaterials] = useState(false);
  const [showAllStyles, setShowAllStyles] = useState(false);
  const {
    filters: { colorsFilters, materialsFilters, stylesFilters },
    selectedFilters: { selectedColorsFilters, selectedMaterialsFilters, selectedStylesFilters },
    selectColorFilter,
    selectMaterialFilter,
    selectStyleFilter,
    resetSelectedFiltersExceptDimensions,
  } = useFilterLogic();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  if (!colorsFilters?.length && !materialsFilters?.length && !stylesFilters?.length) {
    return;
  }

  return (
    <div ref={filterRef} className="relative select-none h-[20px] w-[20px]">
      <SvgIcon
        src={FILTER_ICON_URL}
        className={`h-[20px] w-[20px] cursor-pointer ${
          selectedColorsFilters?.length ||
          selectedMaterialsFilters?.length ||
          selectedStylesFilters?.length
            ? "!text-highlight"
            : open && "!stroke-secondary hover:!stroke-primary"
        }`}
        onClick={toggle}
      />
      <div
        className={`${
          !open && "hidden"
        } absolute -right-[calc(50%+4px)] top-[calc(100%+14px)] z-20 max-h-[350px] w-[260px] whitespace-nowrap rounded-md border border-gray-100 bg-white p-3 shadow`}
      >
        <IoMdClose
          className="ml-auto cursor-pointer text-secondary hover:text-primary"
          onClick={onClose}
        />
        <div className="flex h-full flex-col">
          <div className="flex items-center space-x-2 text-[16px] font-medium">
            <div>Filter</div>
            <TfiReload
              className="cursor-pointer text-secondary hover:text-primary"
              onClick={resetSelectedFiltersExceptDimensions}
            />
          </div>
          <div className="my-2 mb-3 flex h-full flex-col space-y-2 overflow-y-auto pr-2">
            {!!colorsFilters?.length && <span className="text-[14px]">Color</span>}
            <div className="grid grid-cols-4 gap-2 pr-2">
              {colorsFilters.map((color, index) => {
                if (index > 10 && !showAllColors) {
                  return;
                }

                const isSelected = !!selectedColorsFilters.find((filter) => filter === color);

                return (
                  <div
                    key={index}
                    className={`h-[35px] cursor-pointer rounded border border-gray-200 opacity-80 hover:opacity-100 hover:shadow-lg ${
                      isSelected && "scale-[80%]"
                    }`}
                    style={{ backgroundColor: color }}
                    onClick={() => selectColorFilter(color)}
                  ></div>
                );
              })}
              {colorsFilters.length > 11 && (
                <div
                  className={`flex h-[35px] cursor-pointer items-center justify-center p-1 text-[12px] underline opacity-80`}
                  onClick={() => setShowAllColors((prev) => !prev)}
                >
                  {showAllColors ? "View less" : "View all"}
                </div>
              )}
            </div>
            {!!materialsFilters?.length && <span className="text-[14px]">Material</span>}
            <FiltersOptionsContainer
              key={"Material"}
              filters={materialsFilters}
              selectedFilters={selectedMaterialsFilters}
              selectFilter={selectMaterialFilter}
              showAllFilters={showAllMaterials}
              setShowAllFilters={setShowAllMaterials}
            />
            {!!stylesFilters?.length && <span className="text-[14px]">Style</span>}
            <FiltersOptionsContainer
              key={"Style"}
              filters={stylesFilters}
              selectedFilters={selectedStylesFilters}
              selectFilter={selectStyleFilter}
              showAllFilters={showAllStyles}
              setShowAllFilters={setShowAllStyles}
            />
          </div>
        </div>
      </div>
      <span
        className={`${
          !open && "hidden"
        } absolute left-1/2 -translate-x-1/2 top-[calc(100%+8px)] z-20 h-3 w-3 rotate-45 border border-b-0 border-r-0 border-gray-100 bg-white`}
      />
    </div>
  );
}

const FiltersOptionsContainer = ({
  filters,
  selectedFilters,
  selectFilter,
  showAllFilters,
  showAllMaterials,
  setShowAllFilters,
}) => {
  return (
    <div className="flex flex-wrap gap-2">
      {filters.map((filter, index) => {
        if (index > 8 && !showAllMaterials) {
          return;
        }

        const isSelected = !!selectedFilters.find((f) => f === filter);

        return (
          <div
            key={index}
            className={`flex h-[30px] cursor-pointer items-center justify-center rounded border border-gray-200 p-2 text-[12px] hover:shadow ${
              isSelected && "scale-[85%] border-highlight text-highlight"
            }`}
            onClick={() => selectFilter(filter)}
          >
            {filter}
          </div>
        );
      })}
      {filters.length > 9 && (
        <div
          className={`flex h-[35px] cursor-pointer items-center justify-center p-1 text-[12px] underline opacity-80`}
          onClick={() => setShowAllFilters((prev) => !prev)}
        >
          {showAllFilters ? "View less" : "View all"}
        </div>
      )}
    </div>
  );
};

export default OptionsFilter;
