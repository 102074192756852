import React from "react";
import { BsBookmark } from "react-icons/bs";

function BookmarkButton({ onClick = () => {}, disabled = false }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`flex h-10 w-[72px] items-center justify-center rounded-md border ${disabled  ? "border-softGray text-softGray cursor-not-allowed" : "border-softGray text-secondary hover:border-primary hover:text-primary cursor-pointer"}`}
    >
      <BsBookmark size={20} />
    </button>
  );
}

export default BookmarkButton;
