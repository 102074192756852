import React, { useRef, useEffect } from "react";
import { IoClose } from "react-icons/io5";

function Modal({
  show,
  onClose,
  showCloseIcon = true,
  children,
  className = "",
}) {
  const modalRef = useRef();

  useEffect(() => {
    const handleOverlayClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOverlayClick);

    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, [modalRef.current]);

  useEffect(() => {
    if (show) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [show]);

  return (
    <div
      className={`fixed left-0 top-0 z-50 !m-0 flex h-screen w-screen items-center justify-center ${
        !show && "hidden"
      }`}
    >
      <div
        ref={modalRef}
        className={`z-[51] mx-auto h-auto w-11/12 overflow-y-auto rounded-lg bg-white shadow-lg lg:w-4/12 ${className}`}
      >
        {show && (
          <div className="p-4 text-left lg:p-6">
            {showCloseIcon && (
              <IoClose
                className="ml-auto cursor-pointer rounded text-[25px] text-primary hover:bg-gray-200 lg:text-[30px]"
                onClick={onClose}
              />
            )}
            {children}
          </div>
        )}
      </div>
      <div className="fixed left-0 top-0 !m-0 h-full w-full bg-black opacity-50" />
    </div>
  );
}

export default Modal;
