import React, { useEffect, useRef, useState } from "react";

import useKeyPressed from "../../hooks/useKeyPressed";

function OtpInput({
  length = 6,
  autoFocus = false,
  disabled = false,
  onComplete = () => {},
  className,
  fieldClassName,
  ...rest
}) {
  const inputRef = useRef(Array(length).fill(null));
  const [OTP, setOTP] = useState(Array(length).fill(""));
  const [currentFocusField, setCurrentFocusField] = useState(autoFocus ? 0 : -1);
  const [isOtpFieldFocused, setIsOtpFieldFocused] = useState(false);

  useKeyPressed("ArrowRight", () => {
    if (isOtpFieldFocused) {
      setCurrentFocusField((prev) => Math.min(prev + 1, length - 1));
    }
  });

  useKeyPressed("ArrowLeft", () => {
    if (isOtpFieldFocused) {
      setCurrentFocusField((prev) => Math.max(prev - 1, 0));
    }
  });

  useKeyPressed("Backspace", () => {
    if (isOtpFieldFocused) {
      const newOTP = [...OTP];
      newOTP[currentFocusField] = "";
      setOTP(newOTP);
      setCurrentFocusField((prev) => Math.max(prev - 1, 0));
    }
  });

  const handleTextChange = (input, index) => {
    if (!input) {
      return;
    }

    const newOTP = [...OTP];
    const strInput = input + "";
    newOTP[index] = strInput.length > 1 ? strInput[0] : strInput;
    setOTP(newOTP);

    if (index < length - 1) {
      setCurrentFocusField((prev) => Math.min(prev + 1, length - 1));
    }

    if (newOTP.every((digit) => digit !== "")) {
      onComplete(newOTP.join(""));
    }
  };

  const handleFieldFocus = (index) => {
    setCurrentFocusField(index);
    setIsOtpFieldFocused(true);
  };

  const handleFieldBlur = () => {
    setIsOtpFieldFocused(false);
  };

  const handlePaste = (event, index) => {
    event.stopPropagation();
    event.preventDefault();

    const pastedData = (event.clipboardData || window.clipboardData).getData("text");

    if (pastedData && typeof pastedData === "string") {
      const numbersOnly = (pastedData.match(/\d+/g)?.join("") || "").slice(0, length);

      const newOTP = [...OTP];

      for (let i = index, j = 0; i < length && j < numbersOnly.length; ++i, ++j) {
        newOTP[i] = numbersOnly[j];
      }

      setOTP(newOTP);

      if (newOTP.every((digit) => digit !== "")) {
        onComplete(newOTP.join(""));
      }
    }
  };

  useEffect(() => {
    inputRef.current[currentFocusField]?.focus();
  }, [currentFocusField]);

  return (
    <div className={`mx-auto flex items-center space-x-1 ${className}`}>
      {Array.from({ length }).map((_, index) => (
        <div key={index}>
          <input
            {...rest}
            ref={(ref) => (inputRef.current[index] = ref)}
            type="number"
            max={"9"}
            min={"0"}
            autoFocus={autoFocus && index === 0}
            disabled={disabled}
            value={OTP[index]}
            onChange={(event) => handleTextChange(event.target.value, index)}
            onPaste={(event) => handlePaste(event, index)}
            onFocus={() => handleFieldFocus(index)}
            onBlur={handleFieldBlur}
            className={`w-8 rounded border px-2 py-2 text-center outline-none focus:ring-2 focus:ring-primary ${fieldClassName}`}
          />
          {index !== length - 1 && <span className="h-[2px] w-3 bg-softGray"></span>}
        </div>
      ))}
    </div>
  );
}

export default OtpInput;
