import { Outlet } from "react-router-dom";
import { useQuery } from "react-query";

import Loader from "../loader";
import useUser from "../../hooks/useUser";
import { getProfile } from "../../apis/authApi";
import { PROFILE_QUERY_KEY } from "../../queries-keys/user";
import { useState } from "react";

const ProfileFetcher = () => {
  const [isRequestDone, setIsRequestDone] = useState(false);
  const { saveUser } = useUser();
  const { isLoading } = useQuery(PROFILE_QUERY_KEY(), getProfile, {
    enabled: !isRequestDone,
    onSuccess: (user) => {
      saveUser(user);
    },
    onSettled: () => {
      setIsRequestDone(true);
    },
    retry: false,
  });

  return <>{isLoading ? <Loader /> : <Outlet />}</>;
};

export default ProfileFetcher;
