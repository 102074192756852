import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import useCreateDesign from "../../../hooks/useCreateDesign";
import useGetItems from "../../../hooks/useGetItems";
import SvgIcon from "../../../components/svgIcon";

function SideBar({ item: currentDesignItem }) {
  const [selectedItem, setSelectedItem] = useState();
  const { items } = useGetItems(currentDesignItem?.room);
  const { createDesignMutation, isCreatingDesign } = useCreateDesign();

  const handleCreateDesign = (item) => {
    if (!item?.is_available) {
      return;
    }
    setSelectedItem(item);
    createDesignMutation(item?.id);
  };

  return (
    <div className="absolute left-0 top-[68px] flex h-fit w-full flex-1 items-center justify-center space-x-6 overflow-x-auto overflow-y-auto py-2 lg:left-8 lg:top-[96px] lg:h-full lg:w-fit lg:flex-col lg:justify-start lg:space-x-0 lg:space-y-6 lg:overflow-x-hidden lg:py-0 lg:pr-2 lg:pt-[52px]">
      {items?.map((item, index) => {
        return isCreatingDesign && selectedItem?.id === item?.id ? (
          <AiOutlineLoading3Quarters
            key={index}
            size={30}
            className="shrink-0 animate-spin text-primary"
          />
        ) : (
          <SvgIcon
            key={index}
            src={item?.icon || ""}
            className={`h-[30px] w-[30px] shrink-0 cursor-not-allowed fill-gray-200 stroke-gray-200 ${
              item?.is_available &&
              item.id !== currentDesignItem.id &&
              "cursor-pointer !fill-secondary hover:!fill-primary !stroke-secondary hover:!stroke-primary"
            } ${
              item.id === currentDesignItem.id && "cursor-pointer !stroke-red-800 !fill-red-800"
            }`}
            onClick={() => handleCreateDesign(item)}
          />
        );
      })}
    </div>
  );
}

export default SideBar;
