import React, { useState } from 'react';

import OutlineButton from '../../components/customButtons/OutlineButton';
import Loader from '../../components/loader';

const Background = ({ image, onStartNowClick }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };

  return (
    <div className='flex flex-col justify-center overflow-hidden h-full'>
      <div className='relative flex-1'>
        {isLoading && <Loader className={'absolute top-0 left-0 h-full w-full'} />}
        <img
          src={image || '/Backdrop.png'}
          alt='Background'
          className={`h-[90vh] w-full shrink-0 object-cover ${isLoading ? 'hidden' : 'block'}`}
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
      </div>
      <div className='mx-auto p-5'>
        <OutlineButton className='px-10 py-2' onClick={onStartNowClick}>
          Start Now
        </OutlineButton>
      </div>
    </div>
  );
};

export default Background;
