import React, { useState } from "react";
import PropTypes from "prop-types";
import { BsCartFill, BsFillBoxSeamFill } from "react-icons/bs";

import "./AddToCart.css";

function AddToCartButton({ disabled, playAnimation = true, onClick = () => {}, className }) {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    console.log('clicked');
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 2000);

    onClick();
  };

  return (
    <button
      className={`relative min-w-[180px] overflow-hidden rounded-md bg-highlight p-2 text-white hover:bg-red-700
                ${disabled && !isClicked && "cursor-not-allowed !bg-gray-200 !text-softGray"} ${
        playAnimation && isClicked && "clicked"
      } 
                ${className}`}
      onClick={handleClick}
      disabled={disabled || isClicked}
    >
      <span className="add-to-cart-label absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 transform">
        Add to Cart
      </span>
      <span className="added-label z-20 opacity-0">Added</span>
      <BsCartFill
        size={20}
        className="shopping-cart absolute left-[-10%] top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-2xl"
      />
      <BsFillBoxSeamFill
        size={12}
        className="shopping-box absolute left-1/2 top-[-20%] z-20 -translate-x-1/2 -translate-y-1/2 transform"
      />
    </button>
  );
}

AddToCartButton.propTypes = {
  disabled: PropTypes.bool,
  playAnimation: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default AddToCartButton;
