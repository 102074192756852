import { useState } from "react";
import { NavLink } from "react-router-dom";

import PageWrapper from "../../components/pageWrapper";
import ROUTES from "../../constants/routes";
import UserInfoForm from "./components/UserInfoForm";
import VerificationAndPasswordForm from "./components/VerificationAndPasswordForm";

const SignupForm = () => {
  const [userInfo, setUserInfo] = useState({ firstName: "", lastName: "", email: "", mobile: "" });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isVerificationAndPasswordStep, setIsVerificationAndPasswordStep] = useState();

  return (
    <PageWrapper>
      <div className="grid grid-cols-1 lg:grid-cols-7 lg:gap-16">
        <img
          alt="Sign-up Thumbnail"
          src="/auth.png"
          className="col-span-4 hidden lg:block lg:w-full"
        />
        <div className="col-span-3 flex flex-col space-y-3 text-primary lg:space-y-5">
          <span className="text-[24px] font-bold lg:text-[34px]">Sign Up</span>

          {isVerificationAndPasswordStep ? (
            <VerificationAndPasswordForm {...{ userInfo, setIsVerificationAndPasswordStep }} />
          ) : (
            <UserInfoForm
              {...{
                isOtpSent,
                setIsOtpSent,
                setIsVerificationAndPasswordStep,
                userInfo,
                setUserInfo,
              }}
            />
          )}
          {/* <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-center space-x-5">
              <div className="w-1/3 border-t border-gray-400"></div>
              <span className="text-sm text-gray-500">or</span>
              <div className="w-1/3 border-t border-gray-400"></div>
            </div>
            <span className="text-center text-sm text-gray-500">
              Sign up with
            </span>
          </div>
          <div className="flex items-center justify-center space-x-3">
            <FcGoogle size={30} className="cursor-pointer" />
            <FaFacebook size={30} className="cursor-pointer text-blue-500" />
            <FaApple size={30} className="cursor-pointer text-black" />
          </div> */}
          <div className="flex justify-between">
            <span className="text-sm text-gray-500">Do you have an account?</span>
            <NavLink
              to={ROUTES.SIGN_IN.path}
              className="cursor-pointer justify-end text-sm text-primary underline hover:text-blue-500"
            >
              Sign In
            </NavLink>
          </div>
          {isVerificationAndPasswordStep && (
            <div className="flex flex-col space-y-2 text-[14px] text-highlight lg:!mt-auto">
              <span className="!my-4 mx-auto w-[80%] border-b border-softGray" />
              <span>
                * The OTP is valid for a limited time, so make sure to complete the process
                promptly.
              </span>
              <span>
                * If you don't see the email in your inbox, check your spam or junk folder.
              </span>
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default SignupForm;
