import api from "./config/axiosWith401Redirect";
import Room from "../models/furniture/room";
import Item from "../models/furniture/item";

const ROOMS_URL = "/rooms";

export const getRooms = async () => {
  const response = await api.get(`${ROOMS_URL}/`);
  const rooms = response.data?.map((room) => new Room(room));
  return rooms;
};

export const getRoom = async (roomId) => {
  const response = await api.get(`${ROOMS_URL}/${roomId}/`);
  return new Room(response.data);
};

export const getRoomItems = async (roomId) => {
  const response = await api.get(`${ROOMS_URL}/${roomId}/items/`);
  const roomItems = response.data?.map((item) => new Item(item));
  return roomItems;
};

export const getItem = async (roomId, itemId) => {
  const response = await api.get(`${ROOMS_URL}/${roomId}/items/${itemId}/`);
  return new Item(response.data);
};
