export default class Payment {
  payment_order_id: number;
  cart_items: number[];
  shipping_address: number | null;
  booking_slots: number[];
  booking_requests: number[];
  coupon : number | null;

  constructor({
    payment_order_id,
    cart_items,
    shipping_address,
    booking_slots,
    booking_requests,
    coupon,
    }: 
    {
      payment_order_id: number;
      cart_items: number[];
      shipping_address: number | null;
      booking_slots: number[];
      booking_requests: number[];
      coupon : number | null;
    })
  {
    this.payment_order_id = payment_order_id;
    this.cart_items = cart_items;
    this.shipping_address = shipping_address;
    this.booking_slots = booking_slots;
    this.booking_requests = booking_requests;
    this.coupon = coupon;
  }
}