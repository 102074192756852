import api from "./config/axiosWith401Redirect";
import BookingSlot from "../models/BookingSlot";

const BOOKING_SLOTS_URL = "/booking/slots";
const BOOKING_REQUEST_URL = "/booking/requests";
const CONSULTANT_NEXT_SLOTS = "/booking/consultant/next";
const CONSULTANT_PREVIOUS_SLOTS = "/booking/consultant/previous";
const CLIENT_PREVIOUS_SLOTS = `/booking/some-user/previous`;
const UPDATE_SLOT_NOTES = "/booking/consultant/update-notes";

export const getAvailableTimeSlots = async (consultationType) => {
  const response = await api.get(
    `${BOOKING_SLOTS_URL}/?type=${consultationType}`,
  );
  const timeSlots = response.data?.map((slot) => new BookingSlot(slot));
  return timeSlots;
};

export const bookTimeSlot = async (timeSlotId) => {
  const response = await api.post(`${BOOKING_SLOTS_URL}/${timeSlotId}/book/`);
  return new BookingSlot(response.data);
};

export const bookOnsiteRequest = async ({ address, notes }) => {
  const response = await api.post(`${BOOKING_REQUEST_URL}/`, {
    address,
    notes,
  });
  return response;
};

export const getConsultantNextSlots = async () => {
  const response = await api.get(`${CONSULTANT_NEXT_SLOTS}/`);
  return response.data;
};

export const getConsultantPreviousSlots = async () => {
  const response = await api.get(`${CONSULTANT_PREVIOUS_SLOTS}/`);
  return response.data;
};

export const getClientPreviousSlots = async (client_id) => {
  const response = await api.get(`${CLIENT_PREVIOUS_SLOTS}/${client_id}/`);
  return response.data;
};

export const updateSlotNotes = async (data) => {
  const response = await api.post(`${UPDATE_SLOT_NOTES}/`, data);
  return response;
};
