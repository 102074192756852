import React, { useReducer } from "react";
import { useSearchParams } from "react-router-dom";

import PageWrapper from "../../components/pageWrapper";
import ConsultationServicesOverview from "./components/ConsultationServicesOverview";
import ConsultationType from "./components/ConsultationType";
import AppointmentScheduler from "./components/AppointmentScheduler";
import OnsiteBookingForm from "./components/OnsiteBookingForm";
import UserInfoForm from "./components/UserInfoForm";
import { BOOKING_CONSULTANT_PARAMS } from "../../constants/routes";
import { CONSULTATION_TYPES } from "../../constants/consultationService";
import useUser from "../../hooks/useUser";

const SET_BOOKING_TYPE = "SET_BOOKING_TYPE";
const SET_BOOKING_DATE = "SET_BOOKING_DATE";
const SET_BOOKING_TIME_SLOT = "SET_BOOKING_TIME_SLOT";
const RESET_BOOKING_DETAILS = "RESET_BOOKING_DETAILS";

const bookingInitialState = {
  type: undefined,
  date: undefined,
  timeSlot: undefined,
};

const bookingReducer = (state, action) => {
  switch (action.type) {
    case SET_BOOKING_TYPE:
      return { ...state, type: action.payload };

    case SET_BOOKING_DATE:
      return { ...state, date: action.payload };

    case SET_BOOKING_TIME_SLOT:
      return { ...state, timeSlot: action.payload };

    case RESET_BOOKING_DETAILS:
      return bookingInitialState;

    default:
      return state;
  }
};

function BookConsultant() {
  const { isLoggedIn, user } = useUser();
  const [searchParams] = useSearchParams();
  const [bookingDetails, bookingDispatch] = useReducer(
    bookingReducer,
    bookingInitialState,
  );
  const bookingStep = searchParams.get("step");

  return (
    <PageWrapper>
      {bookingStep === BOOKING_CONSULTANT_PARAMS.BOOKING_DETAILS ? (
        isLoggedIn && user ? (
          bookingDetails.type === CONSULTATION_TYPES.ONSITE.name ? (
            <OnsiteBookingForm bookingDetails={bookingDetails} />
          ) : (
            <AppointmentScheduler {...{ bookingDetails, bookingDispatch }} />
          )
        ) : (
          <UserInfoForm {...{ bookingDetails }} />
        )
      ) : bookingStep === BOOKING_CONSULTANT_PARAMS.CONSULTATION_TYPE ? (
        <ConsultationType
          {...{
            bookingDetails,
            bookingDispatch,
          }}
        />
      ) : (
        <ConsultationServicesOverview />
      )}
    </PageWrapper>
  );
}

export default BookConsultant;
