import api from "./config/axiosConfig";
import Session from "../models/Session";

const SESSIONS_URL = "/session";

export const getSession = async () => {
  const response = await api.get(`${SESSIONS_URL}/`);
  return new Session(response.data);
};

export const requestSession = async () => {
  const response = await api.post(`${SESSIONS_URL}/`);
  return new Session(response.data);
};

export const activateSession = async () => {
  const response = await api.post(`${SESSIONS_URL}/activate/`);
  return new Session(response.data);
};
