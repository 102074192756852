import React, { useRef } from "react";

const ZOOM_FACTOR = 0.8;

function ImageMagnifier({
  show = false,
  imgSrc,
  cursorPosition = { x: 0, y: 0 },
  magnifierImagePosition = { x: 0, y: 0 },
  imgBound,
}) {
  const magnifierRef = useRef();
  const magnifierBound = magnifierRef?.current?.getBoundingClientRect() || {};

  if (!show) {
    return;
  }
  return (
    <div
      ref={magnifierRef}
      style={{
        left: `${cursorPosition.x - magnifierBound?.height / 2}px`,
        top: `${cursorPosition.y - magnifierBound?.width / 2}px`,
        backgroundImage: `url("${imgSrc}")`,
        backgroundPositionX: `calc(${magnifierImagePosition.x}%)`,
        backgroundPositionY: `calc(${magnifierImagePosition.y}%)`,
        backgroundSize: `${ZOOM_FACTOR * imgBound?.width}% ${ZOOM_FACTOR * imgBound?.height}%`,
      }}
      className={`pointer-events-none absolute h-[150px] w-[150px] cursor-grab overflow-hidden rounded-full border backdrop-blur border-secondary bg-cover bg-no-repeat lg:h-[200px] lg:w-[200px] xl:h-[250px] xl:w-[250px]`}
    />
  );
}

export default ImageMagnifier;
