import api from "./config/axiosWith401Redirect";
import BookingSlot from "../models/BookingSlot";

const PROFILE_URL = "auth/profile";
const ORDERS_URL = "orders/user";
const NEXT_CONSULTANT_URL = "booking/user";
const UPDATE_PASSWORD = "auth/update-password";

export const getProfile = async () => {
  const response = await api.get(`${PROFILE_URL}/`);
  return response.data;
};

export const updateProfile = async ({
  firstName,
  lastName,
  birthdate,
  mobile,
}) => {
  const response = await api.patch(`${PROFILE_URL}/`, {
    first_name: firstName,
    last_name: lastName,
    birthdate,
    mobile: mobile.startsWith("+2") ? mobile : "+2" + mobile,
  });
  return response.data;
};

export const updatePassword = async (updatedPassword) => {
  const response = await api.post(`${UPDATE_PASSWORD}/`, updatedPassword);
  return response.data;
};

export const getOrders = async () => {
  const response = await api.get(`${ORDERS_URL}/`);
  return response.data;
};

export const getNextConsultations = async () => {
  const response = await api.get(`${NEXT_CONSULTANT_URL}/next/`);
  return response.data?.map((slot) => new BookingSlot(slot));
};

export const cancelNextConsultation = async (slotId) => {
  const response = await api.post(`${NEXT_CONSULTANT_URL}/cancel/${slotId}/`);
  return response.data;
};

export const rescheduleNextConsultation = async ({
  oldSlotId: old_slot_id,
  newSlotId: new_slot_id,
}) => {
  const response = await api.post(`${NEXT_CONSULTANT_URL}/reschedule/`, {
    old_slot_id,
    new_slot_id,
  });
  return response.data;
};
