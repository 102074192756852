import Room from "./room";

export default class Item {
  id: number;
  name: string;
  image: string | null = null;
  icon: string | null = null;
  room: Room | number | null = null;
  is_available: boolean;

  constructor({id, name, image, icon, room, is_available} : {id: number, name: string, image: string | null, icon: string | null, room: Room | number | null, is_available: boolean}) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.icon = icon;
    this.room = room;
    this.is_available = is_available;
  }
}