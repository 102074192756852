import React, { useLayoutEffect, useState } from "react";

import Loader from "../loader";
import PageWrapper from "../pageWrapper";
import useCreateSession from "../../hooks/useCreateSession";
import useActivateSession from "../../hooks/useActivateSession";
import { SESSIONS_STATES } from "../../constants/sessions";

function SessionHandler({ session }) {
  const [isLoading, setIsLoading] = useState(true);
  const {
    activateSessionMutation,
    activatedSession,
    isActivatingSession,
    isActivatingSessionFailed,
    activatingSessionError,
  } = useActivateSession();

  const {
    createSessionMutation,
    createdSession,
    isCreatingSession,
    isCreatingSessionFailed,
    creatingSessionError,
  } = useCreateSession({
    onSessionCreated: (session) => {
      setIsLoading(false);
      if (session?.state === SESSIONS_STATES.APPROVED) {
        activateSessionMutation();
      }
    },
  });

  useLayoutEffect(() => {
    if (
      !session?.state ||
      session?.state === SESSIONS_STATES.EXPIRED ||
      session?.state === SESSIONS_STATES.REJECTED
    ) {
      createSessionMutation();
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {activatedSession?.state === SESSIONS_STATES.ACTIVATED ||
      createdSession?.state === SESSIONS_STATES.ACTIVATED ||
      isLoading ||
      isCreatingSession ||
      isActivatingSession ? (
        <Loader className={"min-h-[400px]"} />
      ) : (
        <PageWrapper>
          <div className="flex items-center justify-center">
            <div className=" flex h-[400px] w-full max-w-[400px] flex-col items-center justify-center space-y-20 bg-gray-100 p-5">
              {isCreatingSessionFailed ||
                (isActivatingSessionFailed && (
                  <span className="rounded bg-red-400 px-3 text-white">
                    {creatingSessionError || activatingSessionError}
                  </span>
                ))}
              <p className="text-primary">
                Current Session State: {session?.state}
              </p>
              {session?.state !== SESSIONS_STATES.PENDING && (
                <button
                  className="w-full max-w-[200px] rounded bg-primary p-2 text-white"
                  onClick={
                    session?.state === SESSIONS_STATES.APPROVED
                      ? activateSessionMutation
                      : createSessionMutation
                  }
                >
                  {isCreatingSession || isActivatingSession
                    ? "Loading..."
                    : session?.state === SESSIONS_STATES.APPROVED
                      ? "Activate"
                      : "Request"}
                </button>
              )}
            </div>
          </div>
        </PageWrapper>
      )}
    </>
  );
}

export default SessionHandler;
