import React, { useEffect } from "react";
import { BiInfoCircle, BiErrorAlt } from "react-icons/bi";
import { IoCloseSharp, IoWarningOutline } from "react-icons/io5";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import useSnackbar from "./hooks/useSnackbar";

const Snackbar = () => {
  const { isOpen, message, severity, handleCloseSnackbar, timeoutIdRef } =
    useSnackbar();

  useEffect(() => {
    return () => clearTimeout(timeoutIdRef.current);
  }, []);

  return (
    <div
      className={`fixed bottom-4 left-4 right-4 z-20 flex min-w-[200px] rounded-md border bg-white text-primary shadow-md transition-all duration-200 md:bottom-8 md:left-8 md:w-fit ${
        !isOpen && "hidden"
      } ${
        severity === "success"
          ? "border-green-200"
          : severity === "error"
            ? "border-red-200"
            : severity === "warning"
              ? "border-yellow-200"
              : "border-blue-200"
      }`}
    >
      <div
        className={`m-4 flex flex-grow items-center justify-between space-x-5 ${
          !isOpen && "hidden"
        }`}
      >
        <div className="flex items-center space-x-2">
          {isOpen && severity === "info" && (
            <div className="text-blue-500">
              <BiInfoCircle size={30} />
            </div>
          )}
          {isOpen && severity === "success" && (
            <div className="text-green-500">
              <IoMdCheckmarkCircleOutline size={30} />
            </div>
          )}
          {isOpen && severity === "error" && (
            <div className="text-red-500">
              <BiErrorAlt size={30} />
            </div>
          )}
          {isOpen && severity === "warning" && (
            <div className="text-yellow-500">
              <IoWarningOutline size={30} />
            </div>
          )}
          <span className="select-none text-[16px] lg:text-[18px]">
            {message}
          </span>
        </div>
        <button
          onClick={handleCloseSnackbar}
          className="rounded-full p-1 hover:bg-gray-100"
        >
          <IoCloseSharp size={25} />
        </button>
      </div>
    </div>
  );
};

export default Snackbar;
