import api from "./config/axiosWith401Redirect";
import Payment from "../models/Payment";

const PAYMENT_URL = "/payment";
const COUPON_URL = "/coupon";

export const createPayment = async (payment) => {
  const response = await api.post(`${PAYMENT_URL}/create/`, payment);
  return new Payment(response.data);
};

export const verifyCoupon = async (coupon) => {
  const response = await api.post(`${COUPON_URL}/verfiy/`, coupon);
  return response.data;
};
