import React, { useState, useRef, useEffect } from "react";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const DEFAULT_IMAGES_VALUE = [];

function ProductImagesContainer({
  images = DEFAULT_IMAGES_VALUE,
  setDisplayedImage = () => {},
}) {
  const imagesContainerRef = useRef();
  const [isImagesContainerOverflowing, setIsImagesContainerOverflowing] =
    useState(false);

  const handlePrevImage = () => {
    if (!imagesContainerRef.current) {
      return;
    }

    imagesContainerRef.current.scrollLeft -= 158;
  };

  const handleNextImage = () => {
    if (!imagesContainerRef.current) {
      return;
    }

    imagesContainerRef.current.scrollLeft += 158;
  };

  useEffect(() => {
    if (!imagesContainerRef.current) {
      return;
    }

    const imagesContainer = imagesContainerRef.current;

    const handleWheelScroll = (event) => {
      event.preventDefault();
      imagesContainer.scrollLeft += event.deltaX;
      // imagesContainer.scrollLeft += event.deltaY;
    };

    const handleOverflowChange = () => {
      if (imagesContainer.scrollWidth > imagesContainer.clientWidth) {
        setIsImagesContainerOverflowing(true);
      } else {
        setIsImagesContainerOverflowing(false);
      }
    };

    handleOverflowChange();

    imagesContainer.addEventListener("wheel", handleWheelScroll);
    window.addEventListener("resize", handleOverflowChange);

    return () => {
      window.removeEventListener("resize", handleOverflowChange);
      imagesContainer.removeEventListener("wheel", handleWheelScroll);
    };
  }, [imagesContainerRef.current]);

  if (!images || images?.length <= 1) {
    return;
  }

  return (
    <div className="flex items-center lg:space-x-2">
      {isImagesContainerOverflowing && (
        <MdKeyboardArrowLeft
          size={30}
          onClick={handlePrevImage}
          className="cursor-pointer text-secondary shrink-0 hover:text-primary"
        />
      )}

      <div
        ref={imagesContainerRef}
        className="flex w-full space-x-2 overflow-x-scroll rounded [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden"
      >
        {images?.map((image, index) => {
          return (
            <img
              key={index}
              src={image}
              className="aspect-[13/9] w-[150px] shrink-0 cursor-pointer rounded"
              onClick={() => setDisplayedImage(image)}
            />
          );
        })}
      </div>
      {isImagesContainerOverflowing && (
        <MdKeyboardArrowRight
          size={30}
          onClick={handleNextImage}
          className="cursor-pointer text-secondary shrink-0 hover:text-primary"
        />
      )}
    </div>
  );
}

export default ProductImagesContainer;
