import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import useUser from "../../hooks/useUser";
import ROUTES from "../../constants/routes";

const RequireAuth = () => {
  const { user, isLoggedIn } = useUser();
  const location = useLocation();

  return isLoggedIn && user ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.SIGN_IN.path} state={{ from: location }} replace />
  );
};

export default RequireAuth;
