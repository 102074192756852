import { configureStore } from "@reduxjs/toolkit";

import designReducer from "../features/designSlice";
import appReducer from "../features/appSlice";
import userReducer from "../features/userSlice";

export const Store = configureStore({
  reducer: {
    design: designReducer,
    app: appReducer,
    user: userReducer,
  },
});
