import { useMutation } from "react-query";
import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getErrorMessage } from "../utils/errors";
import { CreatePayment } from "../apis/paymobPayment";

const usePayment = () => {
  const { handleOpenSnackbar } = useSnackbar();

  const { mutate: createPaymentMutation, isLoading: isPaymentInProgress } =
    useMutation({
      mutationFn: CreatePayment,
      onSuccess: (payment_url) => {
        window.location.href = payment_url;
      },
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    });

  return {
    createPaymentMutation,
    isPaymentInProgress,
  };
};

export default usePayment;
