import React from "react";
import { useSearchParams } from "react-router-dom";

import CardWrapper from "../../../components/cardWrapper";
import MainButton from "../../../components/customButtons/MainButton";
import ConsultationLayout from "./ConsultationLayout";
import useGetAdminConfigs from "../../../hooks/useGetAdminConfigs";
import { BOOKING_CONSULTANT_PARAMS } from "../../../constants/routes";
import { CONSULTATION_TYPES } from "../../../constants/consultationService";
import {formatToCurrency} from "../../../utils/numbers";
import { CURRENCY } from "../../../constants/common";
const SET_BOOKING_TYPE = "SET_BOOKING_TYPE";
const RESET_BOOKING_DETAILS = "RESET_BOOKING_DETAILS";

function ConsultationType({ bookingDetails, bookingDispatch }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    configs: { priceOfOnlineSlot, priceOfOnsiteSlot, priceOfInOfficeSlot },
  } = useGetAdminConfigs();

  const handleSelectConsultationType = (type) => {
    bookingDispatch({
      type: RESET_BOOKING_DETAILS,
    });

    bookingDispatch({
      type: SET_BOOKING_TYPE,
      payload: type === bookingDetails.type ? undefined : type,
    });
  };

  return (
    <ConsultationLayout>
      <p className="max-w-sm text-[14px] font-light">
      Select your preferred consultation method:
      online in-office onsite
      </p>
      <div className="mx-auto grid w-full max-w-[450px] grid-cols-3 grid-rows-1 gap-2 sm:gap-4 lg:mx-0 lg:pl-5">
        {Object.keys(CONSULTATION_TYPES).map((type) => {
          return (
            <React.Fragment key={type}>
              <CardWrapper
                isSelected={
                  bookingDetails.type === CONSULTATION_TYPES[type].name
                }
                onClick={() =>
                  handleSelectConsultationType(CONSULTATION_TYPES[type].name)
                }
              >
                <div className="flex h-full w-full flex-col items-center justify-evenly space-y-2 p-3">
                  <span className="text-center text-[12px] font-medium">
                    {CONSULTATION_TYPES[type].name}
                  </span>
                  <span className={`text-[12px] font-light`}>
                    {CONSULTATION_TYPES[type].name ===
                    CONSULTATION_TYPES.ONLINE.name
                      ? formatToCurrency(priceOfOnlineSlot, CURRENCY)
                      : CONSULTATION_TYPES[type].name ===
                          CONSULTATION_TYPES.IN_OFFICE.name
                        ? formatToCurrency(priceOfInOfficeSlot, CURRENCY)
                        : formatToCurrency(priceOfOnsiteSlot, CURRENCY)}
                  </span>
                </div>
              </CardWrapper>
            </React.Fragment>
          );
        })}
      </div>
      <div className="flex flex-col space-y-2 !pt-2 lg:!pt-5">
        {Object.keys(CONSULTATION_TYPES).map((type) => {
          return (
            <div key={type} className="grid grid-cols-8 text-[12px]">
              <span className="col-span-2 whitespace-nowrap font-medium md:col-span-1">
                {CONSULTATION_TYPES[type].name}
              </span>
              <span className="col-span-6 font-light md:col-span-7">
                {CONSULTATION_TYPES[type].description}
              </span>
            </div>
          );
        })}
      </div>
      <p className="!mb-3 text-[14px] font-light text-highlight">
        * The consultancy fees are subtracted from the total price once and order has been placed. <br/>
        * Cancellations must be made at least 24 hours prior to your appointment.
      </p>
      <MainButton
        className="h-[40px] w-full text-[14px] lg:!mt-auto lg:ml-auto lg:max-w-[200px]"
        onClick={() => {
          searchParams.set("step", BOOKING_CONSULTANT_PARAMS.BOOKING_DETAILS);
          setSearchParams(searchParams);
        }}
        disabled={!bookingDetails.type}
      >
        Next
      </MainButton>
    </ConsultationLayout>
  );
}

export default ConsultationType;
