import React, { useRef, useState } from "react";

import Loader from "../loader";
import ImageMagnifier from "./ImageMagnifier";

function ImageContainer({
  imageMagnifier = false,
  containerClassName,
  imgClassName,
  onLoad,
  ...rest
}) {
  const imgRef = useRef();
  const [loading, setLoading] = useState(true);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [magnifierImagePosition, setMagnifierImagePosition] = useState({
    x: 0,
    y: 0,
  });
  const imgBound = imgRef?.current?.getBoundingClientRect() || {};

  const handleMouseEnterImage = () => {
    // setShowMagnifier(true);
  };

  const handleMouseLeaveImage = () => {
    setShowMagnifier(false);
  };

  const handleMouseMoveOnImage = (event) => {
    setShowMagnifier(true);

    if (imgRef.current) {
      const { left, top, width, height } = imgBound;
      const x = ((event.pageX - left - window.scrollX) / width) * 100;

      const y = ((event.pageY - top - window.scrollY) / height) * 100;

      setMagnifierImagePosition({ x: x, y: y });

      setCursorPosition({
        x: event.pageX - left - window.scrollX,
        y: event.pageY - top - window.scrollY,
      });
    }
  };

  return (
    <figure
      className={`relative ${containerClassName}`}
      onMouseEnter={handleMouseEnterImage}
      onMouseLeave={handleMouseLeaveImage}
      onMouseMove={handleMouseMoveOnImage}
    >
      {loading && (
        <Loader className="absolute left-0 top-0 flex h-full w-full items-center justify-center border" />
      )}
      <img
        ref={imgRef}
        className={`absolute left-0 top-0 ${loading && "!opacity-0"} ${imgClassName}`}
        loading="lazy"
        {...rest}
        onLoad={(event) => {
          setLoading(false);
          onLoad && onLoad(event);
        }}
      />
      <ImageMagnifier
        show={imageMagnifier && !loading && showMagnifier}
        imgBound={imgBound}
        imgSrc={rest.src}
        cursorPosition={cursorPosition}
        magnifierImagePosition={magnifierImagePosition}
      />
    </figure>
  );
}

export default ImageContainer;
