import { useContext } from "react";

import SnackbarContext from "../contexts/SnackbarProvider";

const DURATION = 6000;

export default function useSnackbar() {
  const {
    isOpen,
    setIsOpen,
    message,
    setMessage,
    severity,
    setSeverity,
    timeoutIdRef,
  } = useContext(SnackbarContext);

  const handleOpenSnackbar = (
    message,
    severity,
    { duration, onClose } = {},
  ) => {
    setMessage(message);
    setSeverity(severity);
    setIsOpen(true);

    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(() => {
      handleCloseSnackbar();
      onClose && onClose();
    }, duration ?? DURATION);
  };

  const handleCloseSnackbar = () => {
    clearTimeout(timeoutIdRef.current);

    setIsOpen(false);
    setMessage("");
    setSeverity("info");
  };

  return {
    isOpen,
    message,
    severity,
    handleOpenSnackbar,
    handleCloseSnackbar,
    timeoutIdRef,
  };
}
