import React, { useState, useReducer } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import MoonLoader from "react-spinners/MoonLoader";
import { FaRegNoteSticky } from "react-icons/fa6";
import { SiGooglemeet } from "react-icons/si";
import { BsCalendarDateFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import SlotsSelector from "../../BookConsultant/components/SlotsSelector";
import MainButton from "../../../components/customButtons/MainButton";
import OutlineButton from "../../../components/customButtons/OutlineButton";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import Modal from "../../../components/modal/index";
import {
  getNextConsultations,
  cancelNextConsultation,
  rescheduleNextConsultation,
} from "../../../apis/profileApi";
import { NEXT_CONSULTATION } from "../../../queries-keys/consultation";
import { getErrorMessage } from "../../../utils/errors";
import { formatDateToTime, formatDateToddMMyyyy } from "../../../utils/dates";
import { CONSULTATION_TYPES } from "../../../constants/consultationService";
import { isLessThan } from "../../../utils/dates";
import useGetAdminConfigs from "../../../hooks/useGetAdminConfigs";

const SET_BOOKING_TYPE = "SET_BOOKING_TYPE";
const SET_BOOKING_DATE = "SET_BOOKING_DATE";
const SET_BOOKING_TIME_SLOT = "SET_BOOKING_TIME_SLOT";
const RESET_BOOKING_DETAILS = "RESET_BOOKING_DETAILS";

const reBookingInitialState = {
  type: undefined,
  date: undefined,
  timeSlot: undefined,
};

const reBookingReducer = (state, action) => {
  switch (action.type) {
    case SET_BOOKING_TYPE:
      return { ...state, type: action.payload };

    case SET_BOOKING_DATE:
      return { ...state, date: action.payload };

    case SET_BOOKING_TIME_SLOT:
      return { ...state, timeSlot: action.payload };

    case RESET_BOOKING_DETAILS:
      return reBookingInitialState;

    default:
      return state;
  }
};

const NextConsultation = () => {
  const [showNextConsultationNotes, setShowNextConsultationNotes] =
    useState(false);
  const [showReschedulingModal, setShowReschedulingModal] = useState(false);
  const [
    showCancelConsultationConfirmationModal,
    setShowCancelConsultationConfirmationModal,
  ] = useState(false);
  const [nextConsultationDetails, setNextConsultationDetails] = useState();
  const [reBookingDetails, reBookingDispatch] = useReducer(
    reBookingReducer,
    reBookingInitialState,
  );
  const { handleOpenSnackbar } = useSnackbar();

  const {
    configs: {
      numberOfReschedulingTime = 3,
      numberOfHoursNotToRescheduleBefore = 24,
    },
  } = useGetAdminConfigs();

  const { data: nextConsultations, isFetching: isFetchingNextConsultation } =
    useQuery(NEXT_CONSULTATION(), getNextConsultations, {
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
      onSuccess: (response) => {
        reBookingDispatch({
          type: RESET_BOOKING_DETAILS,
        });

        reBookingDispatch({
          type: SET_BOOKING_TYPE,
          payload: response?.[0]?.type,
        });

        setNextConsultationDetails(response?.[0]);
      },
      refetchOnWindowFocus: false,
    });

  return (
    <>
      <ConsultationNotesModal
        notes={nextConsultationDetails?.notes}
        show={showNextConsultationNotes}
        onClose={() => setShowNextConsultationNotes(false)}
      />
      <ReschedulingModal
        nextConsultationDetails={nextConsultationDetails}
        show={showReschedulingModal}
        onClose={() => setShowReschedulingModal(false)}
        {...{ reBookingDetails, reBookingDispatch }}
      />

      <CancelConsultationConfirmationModal
        nextConsultationDetails={nextConsultationDetails}
        show={showCancelConsultationConfirmationModal}
        onClose={() => setShowCancelConsultationConfirmationModal(false)}
      />

      <div className="relative flex h-fit w-full flex-grow flex-col space-y-3 overflow-y-hidden rounded border-[0.3px] border-softGray p-3 shadow shadow-softGray lg:p-5">
        <span className="font-medium">Next Consultation</span>
        <div className="flex h-full flex-col space-y-3">
          {isFetchingNextConsultation ? (
            <div className="flex h-full items-center justify-center">
              <MoonLoader color="#24595C" />
            </div>
          ) : nextConsultationDetails ? (
            <>
              <div className="flex flex-col space-y-1">
                <div className="flex space-x-2">
                  <span className="font-medium">Consultant Name:</span>
                  <span>
                    {nextConsultationDetails?.consultantFirstName}{" "}
                    {nextConsultationDetails?.consultantLastName}
                  </span>
                </div>
                <div className="flex space-x-2">
                  <span className="font-medium">Consultant Mobile:</span>
                  <span>{nextConsultationDetails?.consultantMobile}</span>
                </div>
                <div className="flex space-x-2">
                  <span className="font-medium">Date:</span>
                  <span>
                    {formatDateToddMMyyyy(nextConsultationDetails?.start)}
                  </span>
                </div>
                <div className="flex space-x-2">
                  <span className="font-medium">Time:</span>
                  <span>{formatDateToTime(nextConsultationDetails.start)}</span>
                </div>
                <div className="flex space-x-2">
                  <span className="font-medium">Type:</span>
                  <span>{nextConsultationDetails.type}</span>
                </div>
                {nextConsultationDetails?.notes && (
                  <div
                    className="group flex w-fit cursor-pointer items-center space-x-2 text-[14px]"
                    onClick={() => setShowNextConsultationNotes(true)}
                  >
                    <FaRegNoteSticky size={20} />
                    <span className="underline-offset-2 group-hover:underline">
                      View Notes
                    </span>
                  </div>
                )}
                <div className="flex justify-evenly text-[25px] text-primary">
                  {nextConsultationDetails.meetingLink && (
                    <Link
                      to={nextConsultationDetails.meetingLink}
                      className="cursor-pointer rounded p-2 hover:bg-gray-200"
                    >
                      <SiGooglemeet />
                    </Link>
                  )}
                  {nextConsultationDetails.eventLink && (
                    <Link
                      to={nextConsultationDetails.eventLink}
                      className="cursor-pointer rounded p-2 hover:bg-gray-200"
                    >
                      <BsCalendarDateFill />
                    </Link>
                  )}
                </div>
                {!nextConsultationDetails?.canBeRescheduled && (
                  <span className="text-highlight">
                    {isLessThan(
                      nextConsultationDetails?.start,
                      numberOfHoursNotToRescheduleBefore,
                    )
                      ? `* You cannot change the meeting time less than ${numberOfHoursNotToRescheduleBefore} hours before it starts.`
                      : nextConsultationDetails?.rescheduleTimes >=
                          numberOfReschedulingTime
                        ? "* You have reached the maximum number of allowed reschedules."
                        : ""}
                  </span>
                )}
              </div>
              <div className="!mt-auto flex space-x-5">
                {nextConsultationDetails.type !=
                  CONSULTATION_TYPES.ONSITE.name && (
                  <MainButton
                    className={"flex-1"}
                    onClick={() => setShowReschedulingModal(true)}
                    disabled={!nextConsultationDetails?.canBeRescheduled}
                  >
                    Re-Schedule
                  </MainButton>
                )}
                <OutlineButton
                  className={"flex-1"}
                  onClick={() =>
                    setShowCancelConsultationConfirmationModal(true)
                  }
                >
                  Cancel
                </OutlineButton>
              </div>
            </>
          ) : (
            <div className="flex h-full items-center justify-center">
              <span className="font-medium lg:text-[18px]">
                You don't have any booked consultations.
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ReschedulingModal = ({
  nextConsultationDetails,
  reBookingDetails,
  reBookingDispatch,
  show,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { handleOpenSnackbar } = useSnackbar();

  const { mutate: rescheduleRequestMutation, isLoading: isRescheduling } =
    useMutation((data) => rescheduleNextConsultation(data), {
      onSuccess: () => {
        queryClient.invalidateQueries(NEXT_CONSULTATION());
        handleOpenSnackbar("Meeting Rescheduled Successfully!", "success");
        onClose();
      },
      onError: (error) => {
        handleOpenSnackbar("Please try again later!", "error");
        onClose();
      },
    });

  const handleRescheduleNextConsultant = (newSlotId) => {
    const data = {
      oldSlotId: nextConsultationDetails?.id,
      newSlotId,
    };
    rescheduleRequestMutation(data);

    reBookingDispatch({
      type: SET_BOOKING_DATE,
    });

    reBookingDispatch({
      type: SET_BOOKING_TIME_SLOT,
    });
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="flex max-h-[500px] flex-col space-y-2 overflow-y-auto pr-2">
        <SlotsSelector
          {...{
            bookingDetails: reBookingDetails,
            bookingDispatch: reBookingDispatch,
          }}
        />
      </div>
      <div className="flex justify-center">
        <MainButton
          className="mx-auto w-full lg:max-w-[300px]"
          onClick={() =>
            handleRescheduleNextConsultant(reBookingDetails?.timeSlot?.id)
          }
          isLoading={isRescheduling}
          disabled={!reBookingDetails.date || !reBookingDetails.timeSlot}
        >
          Re-Schedule
        </MainButton>
      </div>
    </Modal>
  );
};

const CancelConsultationConfirmationModal = ({
  nextConsultationDetails,
  show,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const [understandNotRefundable, setUnderstandNotRefundable] = useState(false);
  const { handleOpenSnackbar } = useSnackbar();

  const {
    mutate: cancelRequestMutation,
    isLoading: isCancelingNextConsultation,
  } = useMutation((slotId) => cancelNextConsultation(slotId), {
    onSuccess: (response) => {
      queryClient.invalidateQueries(NEXT_CONSULTATION());
      onClose();
      handleOpenSnackbar("Meeting Canceled Successfully!", "success");
    },
    onError: (error) => {
      onClose();
      handleOpenSnackbar("Please try again later!", "error");
    },
  });

  const handleCancelRequest = () => {
    cancelRequestMutation(nextConsultationDetails?.id);
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="flex flex-col space-y-3 text-primary">
        <span className="text-[18px] font-bold">Cancel Consultation</span>
        <div>
          <p className="text-highlight">
            Please note that this is not refundable!
          </p>
          <label className="flex items-center">
            <input
              type="checkbox"
              className="mr-2"
              checked={understandNotRefundable}
              onChange={() => setUnderstandNotRefundable((prev) => !prev)}
            />
            I understand this is not refundable
          </label>
        </div>
        <div className="flex space-x-5">
          <OutlineButton onClick={onClose} className="flex-1">
            No
          </OutlineButton>
          <MainButton
            onClick={handleCancelRequest}
            isLoading={isCancelingNextConsultation}
            disabled={!understandNotRefundable}
            className="flex-1"
          >
            Yes
          </MainButton>
        </div>
      </div>
    </Modal>
  );
};

const ConsultationNotesModal = ({ notes, show, onClose }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="max-h-[400px] overflow-y-auto pr-2">
        <p className="whitespace-pre-wrap">{notes}</p>
      </div>
    </Modal>
  );
};

export default NextConsultation;
