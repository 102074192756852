import React from "react";
import DatePicker from "react-datepicker";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";

import { getFormattedMonthAndYear } from "../../utils/dates";
import "./datepicker.css";

function CustomDatePicker({
  selectedDate,
  includeDates = [],
  onChange = () => {},
}) {
  const handleDecreaseMonth = (date, decreaseMonth) => {
    const prevMonth = new Date(date).getMonth() - 1;

    const firstIncludedDay = includeDates.find(
      (date) => date.getMonth() === prevMonth,
    );
    decreaseMonth && decreaseMonth();
    onChange(firstIncludedDay || undefined);
  };

  const handleIncreaseMonth = (date, increaseMonth) => {
    const nextMonth = new Date(date).getMonth() + 1;
    
    const firstIncludedDay = includeDates.find(
      (date) => date.getMonth() === nextMonth,
    );
    increaseMonth && increaseMonth();
    onChange(firstIncludedDay || undefined);
  };

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        return (
          <div className="mx-[0.166rem] mb-[15px] flex items-center justify-between text-primary">
            <div className="font-bold">{getFormattedMonthAndYear(date)}</div>
            <div className="flex">
              <MdKeyboardArrowLeft
                size={25}
                onClick={
                  prevMonthButtonDisabled
                    ? () => {}
                    : () => handleDecreaseMonth(date, decreaseMonth)
                }
                className={`cursor-pointer ${prevMonthButtonDisabled && "!cursor-auto text-gray-300"}`}
              />
              <MdKeyboardArrowRight
                size={25}
                onClick={
                  nextMonthButtonDisabled
                    ? () => {}
                    : () => handleIncreaseMonth(date, increaseMonth)
                }
                className={`cursor-pointer ${nextMonthButtonDisabled && "!cursor-auto text-gray-300"}`}
              />
            </div>
          </div>
        );
      }}
      selected={selectedDate}
      inline
      onChange={onChange}
      minDate={new Date()}
      includeDates={includeDates}
      formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3).toUpperCase()}
      disabledKeyboardNavigation
    />
  );
}

export default CustomDatePicker;
