import { useMutation, useQueryClient } from "react-query";

import useUser from "./useUser";
import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getErrorMessage } from "../utils/errors";
import { updateCartItem } from "../apis/cartApi";
import { CART_ITEMS_QUERY_KEY } from "../queries-keys/cartItems";

const useUpdateCartItem = () => {
  const queryClient = useQueryClient();
  const { handleOpenSnackbar } = useSnackbar();
  const { user } = useUser();

  const {
    mutate: updateCartItemMutation,
    isLoading: isUpdateingCartItemLoading,
  } = useMutation({
    mutationFn: updateCartItem,
    onSuccess: () => {
      queryClient.invalidateQueries(CART_ITEMS_QUERY_KEY(user?.id));
    },
    onError: (error) => {
      handleOpenSnackbar(getErrorMessage(error), "error");
    },
  });

  return {
    updateCartItemMutation,
    isUpdateingCartItemLoading,
  };
};

export default useUpdateCartItem;
