import React from "react";
import UserProfile from "./UserProfile";
import ConsultantProfile from "./ConsultantProfile";
import { USER_ROLES } from "../../constants/userRoles";
import useUser from "../../hooks/useUser";

const Profile = () => {
  const { user } = useUser();
  const isConsultant = user.role === USER_ROLES.CONSULTANT;
  return isConsultant ? <ConsultantProfile /> : <UserProfile />;
};

export default Profile;
