import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { IoArrowBackOutline } from "react-icons/io5";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Joi from "joi";

import Alert from "../../../components/alert";
import MainButton from "../../../components/customButtons/MainButton";
import PasswordOutLinedField from "../../../components/customInputField/PasswordOutLinedField";
import OtpInput from "../../../components/otpInput";
import validators from "../../../constants/validators";
import useAuth from "../../../hooks/useAuth";
import ROUTES from "../../../constants/routes";
import { formatSeconds } from "../../../utils/time";
import { getErrorMessage } from "../../../utils/errors";
import { generateOTP } from "../../../apis/authApi";

function VerificationAndPasswordForm({
  setIsVerificationAndPasswordStep,
  userInfo: { firstName, lastName, email, mobile } = {},
}) {
  const remainingSecondsIntervalRef = useRef();
  const passwordFieldRef = useRef();
  const [error, setError] = useState("");
  const [OTP, setOTP] = useState("");
  const [password, setPassword] = useState();
  const [confirmedPassword, setConfirmedPassword] = useState();
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const navigate = useNavigate();

  const OtpAndPasswordSchema = Joi.object({
    OTP: validators.OTP,
    password: validators.password,
    confirmedPassword: validators.confirmedPassword,
  });

  const { register, registerError, registerLoading } = useAuth({
    onRegister: () => {
      navigate(ROUTES.SIGN_IN.path);
    },
  });

  const { mutate: reGenerateOTPMutation, isLoading: isReGeneratingOTP } = useMutation(
    (email) => generateOTP(email),
    {
      onSuccess: () => {
        setError("");
        setRemainingSeconds(60);
        clearInterval(remainingSecondsIntervalRef.current);
        remainingSecondsIntervalRef.current = setInterval(() => {
          setRemainingSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
      },
      onError: (error) => {
        setError(getErrorMessage(error));
      },
    }
  );

  const handleResendOTP = () => {
    if (!email) {
      return setError("Something went wrong!");
    }

    if (remainingSeconds <= 0) {
      reGenerateOTPMutation(email);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setError("");

    const { error } = OtpAndPasswordSchema.validate({
      OTP,
      password,
      confirmedPassword,
    });

    if (error) {
      return setError(error.message);
    }

    register({
      firstName,
      lastName,
      email,
      mobile,
      password,
      confirmedPassword,
      OTP,
    });
  };

  useEffect(() => {
    return () => clearInterval(remainingSecondsIntervalRef.current);
  }, []);

  return (
    <form onSubmit={handleSubmit} noValidate className="flex flex-col space-y-5">
      <div
        onClick={() => {
          setIsVerificationAndPasswordStep(false);
        }}
        className="flex space-x-1 w-fit items-center cursor-pointer hover:underline text-highlight hover:text-red-700 underline-offset-2"
      >
        <IoArrowBackOutline size={25} />
        <span className="font-medium ">Back</span>
      </div>
      <Alert isOpen={error || registerError} message={error || registerError} severity={"error"} />
      <div className="flex flex-col space-y-3">
        <label htmlFor="OTP" className="mx-auto text-[16px] font-semibold text-primary">
          OTP
        </label>
        <OtpInput
          autoFocus
          onComplete={(OTP) => {
            setOTP(OTP);
            passwordFieldRef?.current?.focus();
          }}
        />
        <div className="flex space-x-2 text-sm text-primary">
          <span className="text-secondary">Don't receive OTP?</span>
          {isReGeneratingOTP ? (
            <AiOutlineLoading3Quarters size={15} className="shrink-0 animate-spin text-primary" />
          ) : (
            <span
              onClick={handleResendOTP}
              className={`text-secondary ${
                !formatSeconds(remainingSeconds) &&
                "cursor-pointer hover:text-primary hover:underline"
              }`}
            >
              {formatSeconds(remainingSeconds) || "RESEND OTP"}
            </span>
          )}
        </div>
        <PasswordOutLinedField
          innerRef={passwordFieldRef}
          id="password"
          name="password"
          label="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <PasswordOutLinedField
          id="confirmedPassword"
          name="confirmedPassword"
          label="Confirm Password"
          value={confirmedPassword}
          onChange={(event) => setConfirmedPassword(event.target.value)}
        />
      </div>
      <MainButton
        type="submit"
        disabled={!OTP}
        className="ml-auto w-full lg:w-1/3"
        isLoading={registerLoading}
      >
        Create Account
      </MainButton>
    </form>
  );
}

export default VerificationAndPasswordForm;
