export default class OrderItem {
  id: number;
  name: string;
  image: string | null;
  price: number;
  status: string;
  shippingAddress: string;
  quantity: number;
  createdAt: Date;
  expectedDeliveryDate : Date;
  type: string;
  itemId : number;

  constructor({
    id,
    name,
    image,
    price,
    status,
    shippingAddress,
    quantity,
    createdAt,
    expectedDeliveryDate,
    type,
    itemId,
  }: {
    id: number;
    name: string;
    image: string | null;
    price: number;
    status: string;
    shippingAddress: string;
    quantity: number;
    createdAt: Date;
    expectedDeliveryDate : Date;
    type: string;
    itemId: number;
  }) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.price = price;
    this.status = status;
    this.shippingAddress = shippingAddress;
    this.quantity = quantity;
    this.createdAt = createdAt;
    this.expectedDeliveryDate = expectedDeliveryDate;
    this.type = type;
    this.itemId = itemId;
  }
}
