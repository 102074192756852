import React, { useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { generateShapesAndMaterialsSteps } from "../../../utils/designs";
import useStepper from "../../../components/stepper/hooks/useStepper";
import { DESIGN_CATEGORY } from "../../../constants/designs";
import { setShape, setMaterial } from "../../../features/designSlice";
import { COLORS_ICON_URL, MATERIALS_ICON_URL } from "../../../constants/iconsUrls";

function FavoritesPanel({ setSteps, elements, selectShape, selectMaterial }) {
  const dispatch = useDispatch();
  const [favoriteOptions, setFavoriteOptions] = useState({});
  const [showAllOptions, setShowAllOptions] = useState(false);
  const { currentStepData } = useStepper();
  const elementsSelectedOptions = useSelector((state) => state.design.elements);
  const { getState } = useStore();
  const currentElementId = currentStepData?.elementId;
  const currentElementName = currentStepData?.elementName;
  const currentElementOtionsIds = currentStepData?.options.map((option) => option.id);

  const isShape = currentStepData?.category === DESIGN_CATEGORY.SHAPE;

  const currentElementFavorites =
    (isShape
      ? favoriteOptions?.[currentElementId]?.shapes
      : favoriteOptions?.[currentElementId]?.materials) || [];

  const selectedOption = isShape
    ? elementsSelectedOptions[currentElementId]?.shape
    : elementsSelectedOptions[currentElementId]?.material
    ? elementsSelectedOptions[currentElementId]?.material[currentStepData?.materialName]
    : null;

  const isSelectedOptionOneOfFavorites = currentElementFavorites.some(
    (option) => option?.id === selectedOption?.id
  );

  const updateFavorites = () => {
    if (!currentElementId || !selectedOption) {
      return;
    }

    setFavoriteOptions((favoriteOptions) => {
      const selectedOptionId = selectedOption?.id;

      const currentElementFavorites = favoriteOptions[currentElementId] || {
        shapes: [],
        materials: [],
      };

      const { shapes, materials } = currentElementFavorites;

      const updatedFavorites = { ...favoriteOptions };

      if (isShape) {
        const isOptionInFavorites = shapes.some((option) => option.id === selectedOptionId);

        updatedFavorites[currentElementId] = {
          ...currentElementFavorites,
          shapes: isOptionInFavorites
            ? shapes.filter((option) => option.id !== selectedOptionId)
            : [...shapes, selectedOption],
        };
      } else {
        const isOptionInFavorites = materials.some((option) => option.id === selectedOptionId);

        updatedFavorites[currentElementId] = {
          ...currentElementFavorites,
          materials: isOptionInFavorites
            ? materials.filter((option) => option.id !== selectedOptionId)
            : [...materials, selectedOption],
        };
      }

      return updatedFavorites;
    });
  };

  const handleSelectShape = (shape) => {
    let selectedElements = getState().design.elements;
    let elementIndex = null;
    for (let i = 0; i < elements.length; i++) {
      if (elements[i]?.id === currentElementId) {
        elementIndex = i;
        break;
      }
    }
    selectShape(shape?.id, elementIndex, elements, selectedElements);
    selectedElements = getState().design.elements;
    selectMaterial(null, 0, 0, elements, selectedElements);
    selectedElements = getState().design.elements;
    const [shapesSteps, materialsSteps] = generateShapesAndMaterialsSteps(
      elements,
      selectedElements
    );
    setSteps((steps) => [
      steps[0],
      {
        label: DESIGN_CATEGORY.SHAPE,
        icon: COLORS_ICON_URL,
        subSteps: shapesSteps,
      },
      {
        label: DESIGN_CATEGORY.MATERIAL,
        icon: MATERIALS_ICON_URL,
        subSteps: materialsSteps,
      },
    ]);
  };

  const handleSelectMaterial = (material) => {
    if (selectedOption?.id === material?.id) {
      return;
    }
    dispatch(
      setMaterial({
        material: { ...material },
        elementId: currentElementId,
        elementName: currentElementName,
        materialName: currentStepData?.materialName,
      })
    );
    let selectedElements = getState().design.elements;
    selectMaterial(null, 0, 0, elements, selectedElements);
    selectedElements = getState().design.elements;
    const [shapesSteps, materialsSteps] = generateShapesAndMaterialsSteps(
      elements,
      selectedElements
    );
    setSteps((steps) => [
      steps[0],
      {
        label: DESIGN_CATEGORY.SHAPE,
        icon: COLORS_ICON_URL,
        subSteps: shapesSteps,
      },
      {
        label: DESIGN_CATEGORY.MATERIAL,
        icon: MATERIALS_ICON_URL,
        subSteps: materialsSteps,
      },
    ]);
  };

  return (
    <div className="flex min-h-[72px] items-center space-x-5 px-6">
      <div className="flex h-6 w-6 shrink-0 cursor-pointer items-center rounded bg-gray-300 hover:bg-gray-400 ">
        {isSelectedOptionOneOfFavorites ? (
          <AiOutlineMinus size={24} className="text-white" onClick={updateFavorites} />
        ) : (
          <AiOutlinePlus size={24} className="text-white" onClick={updateFavorites} />
        )}
      </div>
      <div className="flex flex-nowrap items-center gap-2 overflow-x-auto overflow-y-hidden">
        {currentElementFavorites
          .filter((option) => currentElementOtionsIds.includes(option?.id))
          .map((option, index) => {
            if (index > 4 && !showAllOptions) {
              return;
            }

            const isSelected = option?.id === selectedOption?.id;

            return (
              <div
                key={index}
                className={`h-[72px] w-[72px] shrink-0 cursor-pointer items-center justify-center overflow-hidden rounded-md border ${
                  isSelected && "border-highlight scale-90"
                }`}
                onClick={
                  isShape ? () => handleSelectShape(option) : () => handleSelectMaterial(option)
                }
              >
                <img src={option?.image} className="aspect-square h-full w-full object-cover" />
              </div>
            );
          })}
        {currentElementFavorites?.length > 5 && (
          <div
            className={`flex h-full cursor-pointer items-center justify-center whitespace-nowrap p-1 text-[12px] font-medium text-primary underline`}
            onClick={() => setShowAllOptions((prev) => !prev)}
          >
            {showAllOptions ? "View Less" : "View More"}
          </div>
        )}
      </div>
    </div>
  );
}

export default FavoritesPanel;
