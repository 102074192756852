import CartProduct from "../models/cartProduct";
import useGetCartItems from "./useGetCartItems";
import { CART_ITEMS_TYPES } from "../constants/cartItems";
import { PRICE_MODES } from "../constants/priceModes";
import { useEffect, useState } from "react";

const useGetCart = () => {
  const [cartData, setCartData] = useState([]);
  const [noPriceCartData, setNoPriceCartData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const populateCartData = (cartItems) => {
    const newCartData = [];
    const newNoPriceCartData = [];
    let updatedTotalPrice = 0;
    for (const cartItem of cartItems) {
      const cartProduct = new CartProduct({
        id: cartItem?.id,
        quantity: cartItem?.type === CART_ITEMS_TYPES.FURNITURE ? 1 : cartItem?.quantity,
        availableQuantity:
          cartItem?.type === CART_ITEMS_TYPES.FURNITURE
            ? 1
            : cartItem[cartItem?.type]?.quantity ?? Infinity,
        name:
          cartItem?.type === CART_ITEMS_TYPES.DESIGN
            ? `Design ${cartItem[cartItem?.type]?.id}`
            : cartItem[cartItem?.type]?.name,
        type: cartItem?.type,
        image: cartItem[cartItem?.type]?.image,
        price:
          cartItem?.type === CART_ITEMS_TYPES.DESIGN &&
          cartItem[cartItem?.type]?.price_mode !== PRICE_MODES.ON
            ? cartItem[cartItem?.type]?.request_expired
              ? null
              : cartItem[cartItem?.type]?.request_price
            : cartItem[cartItem?.type]?.price,
        isAvailable: cartItem?.is_available,
        designRequestExpired:
          cartItem?.type === CART_ITEMS_TYPES.DESIGN &&
          cartItem[cartItem?.type]?.request_expired !== false,
        itemId: cartItem[cartItem?.type]?.id,
      });
      if (cartProduct?.price) newCartData.push(cartProduct);
      else newNoPriceCartData.push(cartProduct);
      updatedTotalPrice += cartProduct.finalPrice;
    }
    setCartData(newCartData);
    setNoPriceCartData(newNoPriceCartData);
    setTotalPrice(updatedTotalPrice);
  };

  const { cartItems, isLoadingCartItems } = useGetCartItems(populateCartData);

  useEffect(() => {
    if (cartItems) populateCartData(cartItems);
  }, []);

  return {
    cartItems,
    cartData,
    noPriceCartData,
    totalPrice,
    isLoadingCartItems,
  };
};

export default useGetCart;
