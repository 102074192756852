import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { FaRegCopyright } from "react-icons/fa6";
import Joi from "joi";

import FOOTER_ROUTES from "../../constants/footerRoutes";
import MainButton from "../customButtons/MainButton";
import useSnackbar from "../snackbar/hooks/useSnackbar";
import useGetAdminConfigs from "../../hooks/useGetAdminConfigs";
import validators from "../../constants/validators";
import { subscribeToNewsletter } from "../../apis/newsApi";
import { arePathsEqual } from "../../utils/urls";
import { getErrorMessage } from "../../utils/errors";

export default function Footer() {
  const currentPath = useLocation().pathname;
  const { handleOpenSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);

  const emailSchema = Joi.object({ email: validators.email });

  const {
    configs: { contactUsPhone, contactUsAddress },
  } = useGetAdminConfigs();

  const {
    mutate: subscribeToNewsletterMutation,
    isLoading: isSubscriptionLoading,
  } = useMutation((email) => subscribeToNewsletter(email), {
    onSuccess: () => {
      handleOpenSnackbar("Subscription Successful!", "success");
      setEmail("");
    },
    onError: (error) => {
      handleOpenSnackbar(getErrorMessage(error), "error");
    },
  });

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setIsEmailInvalid(false);
  };

  function isEmailValid(email) {
    const { error } = emailSchema.validate({ email: email });
    return !error;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isEmailValid(email)) {
      return setIsEmailInvalid(true);
    }

    subscribeToNewsletterMutation(email);
  };

  return (
    <footer className="mt-auto w-full bg-gray-100 text-primary">
      <div className="flex w-full flex-col items-center justify-evenly space-y-5 px-4 pb-10 pt-10 xl:flex-row xl:space-y-0 xl:pt-20">
        <div className="flex flex-col space-y-5">
          <div>
            <img src="/logo.png" />
          </div>
          <div className="hidden space-y-5 font-light xl:flex xl:flex-col">
            <span className="w-2/3">{contactUsAddress}</span>
            <span>{contactUsPhone}</span>
          </div>
        </div>
        <div className="flex flex-col justify-evenly space-y-5 self-stretch">
          <div className="flex flex-col items-center space-y-2 font-light xl:flex-row xl:space-x-10 xl:space-y-0">
            {FOOTER_ROUTES.map((route) => {
              return (
                <NavLink
                  key={route.path}
                  to={route.path}
                  className={`${
                    arePathsEqual(currentPath, route.path)
                      ? "text-highlight underline"
                      : ""
                  }`}
                >
                  {route.label}
                </NavLink>
              );
            })}
          </div>
          <div className="flex w-full max-w-[770px] flex-col space-y-2 self-center">
            <span className="text-primary">Subscribe to our Newsletter</span>
            <div className="flex flex-col space-y-1">
              <form
                className="flex h-10 flex-row"
                onSubmit={handleSubmit}
                noValidate
              >
                <input
                  type="email"
                  placeholder="Your e-mail address here"
                  value={email || ""}
                  onChange={handleEmailChange}
                  className="w-full max-w-[650px] rounded-l-md p-4 text-gray-900 outline-none focus:ring-1 focus:ring-secondary"
                />
                <MainButton
                  className={"h-[40px] w-[120px] rounded-none !rounded-r-md"}
                  isLoading={isSubscriptionLoading}
                  type={"submit"}
                >
                  Submit
                </MainButton>
              </form>
              <span
                className={`ml-2 select-none whitespace-nowrap text-[14px] text-red-500 sm:text-[16px] ${
                  isEmailInvalid ? "opacity-100" : "opacity-0"
                }`}
              >
                Please enter a valid email address.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center space-x-1 bg-gray-200 p-2 text-sm text-primary">
        <FaRegCopyright />
        <p>2024 WAE</p>
      </div>
    </footer>
  );
}
