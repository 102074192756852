import React, { useState } from "react";
import { useMutation } from "react-query";
import Joi from "joi";

import Alert from "../../../components/alert";
import MainButton from "../../../components/customButtons/MainButton";
import OutlinedField from "../../../components/customInputField/OutlinedField";
import validators from "../../../constants/validators";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import { generateOTP } from "../../../apis/authApi";
import { getErrorMessage } from "../../../utils/errors";

function UserInfoForm({
  setIsVerificationAndPasswordStep,
  isOtpSent,
  setIsOtpSent,
  setUserInfo,
  userInfo: { firstName = "", lastName = "", email = "", mobile = "" } = {},
}) {
  const [error, setError] = useState("");
  const { handleOpenSnackbar } = useSnackbar();

  const infoSchema = Joi.object({
    firstName: validators.firstName,
    lastName: validators.lastName,
    email: validators.email,
    mobile: validators.mobile,
  });

  const { mutate: generateOTPMutation, isLoading: isGeneratingOTP } = useMutation({
    mutationFn: (email) => generateOTP(email),
    onSuccess: () => {
      setIsOtpSent(true);
      setIsVerificationAndPasswordStep(true);
      handleOpenSnackbar("OTP sent successfully", "success");
    },
    onError: (error) => {
      setError(getErrorMessage(error));
    },
  });

  const handleFieldChange = (name, value) => {
    setError("");

    if (name === "email") {
      setIsOtpSent(false);
    }

    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setError("");

    const { error } = infoSchema.validate({
      firstName,
      lastName,
      email,
      mobile,
    });

    if (error) {
      return setError(error.message);
    }

    if (isOtpSent) {
      return setIsVerificationAndPasswordStep(true);
    }

    generateOTPMutation(email);
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="flex flex-col space-y-5">
      <Alert isOpen={error} message={error} severity={"error"} />
      <div className="flex flex-col space-y-3">
        <div className="flex space-x-3 justify-between">
          <OutlinedField
            id="firstName"
            name="firstName"
            label="First name"
            autoFocus
            value={firstName}
            onChange={(event) => handleFieldChange("firstName", event.target.value)}
            className="w-full"
          />
          <OutlinedField
            id="lastName"
            name="lastName"
            label="Last Name"
            value={lastName}
            onChange={(event) => handleFieldChange("lastName", event.target.value)}
            className="w-full"
          />
        </div>
        <OutlinedField
          id="email"
          name="email"
          label="Email"
          type="email"
          value={email}
          onChange={(event) => handleFieldChange("email", event.target.value)}
        />
        <OutlinedField
          id="mobile"
          name="mobile"
          label="Mobile"
          fieldClassName="pl-10"
          value={mobile}
          onChange={(event) => handleFieldChange("mobile", event.target.value)}
        >
          <span className="absolute left-2 top-1/2 -translate-y-1/2">+2</span>
        </OutlinedField>
      </div>
      <MainButton type="submit" className="ml-auto w-full lg:w-1/3" isLoading={isGeneratingOTP}>
        Continue
      </MainButton>
    </form>
  );
}

export default UserInfoForm;
