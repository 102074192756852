import { DESIGN_CATEGORY } from "../constants/designs";

export const generateShapesAndMaterialsSteps = (elements, selectedElements={}) => {
  let compatability_list = elements[0]?.shapes.map(shape => shape.id);
  const shapesSteps = []
  elements?.forEach((element) => {
    const shapeStep = {
      label: element?.name,
      data: {
        options: element?.shapes.filter((shape) => compatability_list.includes(shape?.id)),
        category: DESIGN_CATEGORY.SHAPE,
        elementId: element.id,
        elementName: element.name,
        elementNotes: element.notes,
      },
    };
    shapesSteps.push(shapeStep);
    compatability_list = selectedElements[element.id]?.shape?.compatabile_shapes || [];
  });

  const materialsSteps = [];
  let compatibility_prefix=Object.values(selectedElements).map(element => element?.shape?.code).sort().join(",")
  const elements_ids = Object.keys(selectedElements).sort(
    (a, b) => selectedElements[a]?.shape?.code.localeCompare(selectedElements[b]?.shape?.code))
    elements_ids.forEach(element_id => {
    const materials = selectedElements[element_id]?.shape?.materials || {};
    Object.keys(materials).sort().forEach((material_name, partIndex) => {
      const materialStep={
        label: material_name + " of " + selectedElements[element_id]?.elementName,
        data: {
          options: materials[material_name].filter((material) => material.compatibility_prefixes?.includes(compatibility_prefix)),
          category: DESIGN_CATEGORY.MATERIAL,
          materialName : material_name,
          elementId: element_id,
          elementName: selectedElements[element_id]?.elementName,
        },
      };
      materialsSteps.push(materialStep);
      const selectedMaterials = selectedElements[element_id]?.material || {}
      compatibility_prefix += ","
      if(partIndex === 0) compatibility_prefix += "["
      compatibility_prefix += selectedMaterials[material_name]?.code || ""
    });
    compatibility_prefix += "]"
  });

  return [shapesSteps, materialsSteps];
};
