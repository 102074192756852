import React from "react";

function OutlinedField({
  label,
  id,
  className,
  fieldClassName,
  children,
  ...rest
}) {
  return (
    <div className={`flex flex-col space-y-2 ${className}`}>
      <label htmlFor={id} className="text-[16px] font-semibold text-primary">
        {label}
      </label>
      <div className="relative">
        <input
          {...rest}
          className={`w-full rounded-md border px-4 py-2 outline-none autofill-color focus:ring-2 focus:ring-primary lg:w-full ${fieldClassName}`}
        />
        {children}
      </div>
    </div>
  );
}

export default OutlinedField;
