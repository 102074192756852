import React from "react";
import { BiInfoCircle, BiErrorAlt } from "react-icons/bi";
import { IoWarningOutline } from "react-icons/io5";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

function Alert({ isOpen, message, severity }) {
  if (!isOpen) {
    return;
  }

  return (
    <div
      className={`flex items-center space-x-2 rounded px-2 py-2 ${
        severity === "success"
          ? "bg-green-500 text-white"
          : severity === "error"
            ? "bg-red-500 text-white"
            : severity === "warning"
              ? "bg-yellow-200 text-orange-700"
              : "bg-blue-200 text-cyan-800"
      }`}
    >
      {severity === "info" && <BiInfoCircle size={25} />}
      {severity === "success" && <IoMdCheckmarkCircleOutline size={25} />}
      {severity === "error" && <BiErrorAlt size={25} />}
      {severity === "warning" && <IoWarningOutline size={25} />}
      <span>{message}</span>
    </div>
  );
}

export default Alert;
