import api from "./config/axiosWith401Redirect";
import OrderItem from "../models/OrderItem";

const ORDERS_URL = "/orders/user/";

export const getOrders = async () => {
  const response = await api.get(ORDERS_URL);

  const ordersItems =
    response?.data?.map((item) => {
      return new OrderItem({
        ...(item[item.type] || {}),
        id: item.order?.id,
        status: item.status,
        quantity: item.quantity,
        type: item.type,
        shippingAddress: item.order?.shipping_address,
        createdAt: item.order?.["created_at"],
        image: item[item?.type]?.image,
        expectedDeliveryDate: item?.expected_delivery_date,
        itemId: item[item?.type]?.id,
      });
    }) || [];

  return ordersItems;
};
