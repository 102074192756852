import React from "react";

function Radio({ checked = false, onChange = () => {}, className }) {
  return (
    <input
      type="radio"
      className={`relative shrink-0 h-5 w-5 appearance-none rounded-full border-2 border-solid
      border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full
        before:bg-transparent before:opacity-0 before:shadow-primary before:content-[''] after:absolute after:z-[1] after:block after:h-4
        after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 
        checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] 
        checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-primary focus:shadow-none focus:outline-none focus:ring-0 
        focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_8px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s]
      checked:focus:border-primary checked:focus:before:scale-100 
      checked:focus:before:shadow-secondary checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] ${className}`}
      checked={checked}
      onChange={onChange}
    />
  );
}

export default Radio;
