import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "../../../utils/errors";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import ROUTES from "../../../constants/routes";
import { getRandomReadyProduct } from "../../../apis/readyProductsApi";
import { RANDOM_READY_PRODUCTS_QUERY_KEY } from "../../../queries-keys/readyToBuy";

function RandomProducts({ currentProduct }) {
  const navigate = useNavigate();
  const { handleOpenSnackbar } = useSnackbar();

  const { data: randomProducts } = useQuery(
    RANDOM_READY_PRODUCTS_QUERY_KEY(),
    () => getRandomReadyProduct(3),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    },
  );

  const handleSelectProduct = (productId) => {
    navigate(`${ROUTES.PRODUCT.path}/${productId}`);
  };

  return (
    <div className="flex flex-col space-y-3 lg:!mt-10">
      <span className="text-[20px] font-bold lg:text-[28px]">
        You may also like
      </span>
      <div className="flex space-x-5">
        {randomProducts?.map((product) => {
          if (product?.id === currentProduct?.id) {
            return;
          }
          return (
            <div key={product?.id} className="flex flex-col space-y-2">
              <img
                src={product?.image}
                alt={product?.name}
                className="aspect-[13/9] w-[150px] cursor-pointer rounded"
                onClick={() => handleSelectProduct(product?.id)}
              />
              <span
                onClick={() => handleSelectProduct(product?.id)}
                className="w-fit cursor-pointer text-[14px] font-light"
              >
                {product?.name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RandomProducts;
