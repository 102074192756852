import { useQuery } from "react-query";

import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getErrorMessage } from "../utils/errors";
import { getAdminConfigs } from "../apis/adminConfigsApi";
import { ADMIN_CONFIGS_QUERY_KEY } from "../queries-keys/adminConfigs";

const useGetAdminConfigs = () => {
  const { handleOpenSnackbar } = useSnackbar();

  const { data: configs = {}, isLoading: isLoadingConfigs } = useQuery(
    ADMIN_CONFIGS_QUERY_KEY(),
    getAdminConfigs,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    },
  );
  return { configs, isLoadingConfigs };
};

export default useGetAdminConfigs;
