import React, { useState } from "react";

import PageWrapper from "../../components/pageWrapper";
import UserAddressesHandler from "../../components/userAddresses/index";
import CheckoutSummery from "./components/CheckoutSummery";
import useGetCart from "../../hooks/useGetCart";

const CheckOut = () => {
  const [shippingAddress, setShippingAddress] = useState();
  const { cartData, totalPrice } = useGetCart();

  return (
    <PageWrapper>
      <div className="flex flex-col space-y-3 text-primary lg:space-y-5">
        <span className="text-[24px] font-bold lg:text-[34px]">Checkout</span>
        <div className="flex flex-col space-y-3 lg:flex-row lg:space-x-10 lg:space-y-0">
          <div className="flex w-full flex-col space-y-3 lg:w-3/4">
            <h1>Select Shipping Address</h1>
            <UserAddressesHandler
              enableSelecting={true}
              selectedAddress={shippingAddress}
              setSelectedAddress={(address) => setShippingAddress(address)}
            />
          </div>
          <CheckoutSummery
            isCheckingOut
            {...{ cartData, totalPrice, shippingAddress }}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default CheckOut;
