import React, { useState } from "react";

import { getFormattedDate } from "../../../utils/dates";

const ORDERS_STATUS = {
  placed: "Placed",
  confirmed: "Confirmed",
  delivered: "Delivered",
};

function TrackingStepper({ order }) {
  return (
    <div className="flex h-full w-fit flex-col">
      <Step
        firstText={"Order Placed"}
        secondText={`We have received your order on ${getFormattedDate(order?.createdAt)}.`}
        isCompleted={
          order?.status === ORDERS_STATUS.placed ||
          order?.status === ORDERS_STATUS.confirmed ||
          order?.status === ORDERS_STATUS.delivered
        }
        isNextStepCompleted={
          order?.status === ORDERS_STATUS.confirmed ||
          order?.status === ORDERS_STATUS.delivered
        }
      />
      <Step
        firstText={"Confirmed"}
        secondText={"Your order has been confirmed."}
        index={1}
        isCompleted={
          order?.status === ORDERS_STATUS.confirmed ||
          order?.status === ORDERS_STATUS.delivered
        }
        isNextStepCompleted={order?.status === ORDERS_STATUS.delivered}
      />
      <LastStep
        firstText={"Delivered"}
        secondText={`Expected Delivery Date on ${getFormattedDate(order?.expectedDeliveryDate)}.`}
        index={2}
        isCompleted={order?.status === ORDERS_STATUS.delivered}
      />
      <span className="text-[12px] ml-7">Shipping to {order?.shippingAddress}</span>
    </div>
  );
}

const Step = ({
  firstText,
  secondText,
  isCompleted = false,
  isNextStepCompleted = false,
  index = 0,
}) => {
  const [startAnimationValue, setStartAnimationValue] = useState(false);
  setTimeout(() => {
    setStartAnimationValue(true);
  }, index * 500);

  return (
    <div
      className={`flex flex-1 space-x-3 ${startAnimationValue && isCompleted ? "text-primary" : "text-secondary"}`}
    >
      <div className="flex h-full flex-col items-center">
        <span
          className={`h-4 w-4 shrink-0 rounded-full ${startAnimationValue && isCompleted ? "bg-primary" : "bg-secondary"}`}
        ></span>
        <span
          className={`transition-max-h h-full w-[3px] bg-primary duration-500 ${startAnimationValue && isNextStepCompleted ? "max-h-full" : "max-h-0"}`}
        ></span>
        <span
          className={`transition-max-h h-full w-[3px] bg-secondary duration-500 ${startAnimationValue && isNextStepCompleted ? "max-h-0" : "max-h-full"}`}
        ></span>
      </div>
      <div className="-mt-1 flex flex-col space-y-1">
        <span className="text-[16px] font-bold">{firstText}</span>
        <span className="text-[12px]">{secondText}</span>
      </div>
    </div>
  );
};

const LastStep = ({
  firstText,
  secondText,
  isCompleted = false,
  index = 0,
}) => {
  const [startAnimationValue, setStartAnimationValue] = useState(false);
  setTimeout(() => {
    setStartAnimationValue(true);
  }, index * 500);

  return (
    <div
      className={`flex space-x-3 ${startAnimationValue && isCompleted ? "text-primary" : "text-secondary"}`}
    >
      <div className="flex flex-col items-center">
        <span
          className={`h-4 w-4 rounded-full ${startAnimationValue && isCompleted ? "bg-primary" : "bg-secondary"}`}
        ></span>
      </div>
      <div className="-mt-1 flex flex-col space-y-1">
        <span className="text-[16px] font-bold">{firstText}</span>
        <span className="text-[12px]">{secondText}</span>
      </div>
    </div>
  );
};

export default TrackingStepper;
