import { useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getErrorMessage } from "../../utils/errors";
import useSnackbar from "../../components/snackbar/hooks/useSnackbar";
import PageWrapper from "../../components/pageWrapper";
import ProductSkeleton from "../../components/skeletons/ProductSkeleton";
import AddToCartButton from "../../components/customButtons/AddToCartButton";
import RandomProducts from "./components/RandomProducts";
import ProductDetails from "./components/ProductDetails";
import ProductImagesContainer from "./components/ProductImagesContainer";
import ImageContainer from "../../components/imageContainer";
import ServiceInfo from "../../components/ServiceInfo";
import useUser from "../../hooks/useUser";
import ROUTES from "../../constants/routes";
import useGetCart from "../../hooks/useGetCart";
import useAddCartItem from "../../hooks/useAddCartItem";
import { getReadyProduct } from "../../apis/readyProductsApi";
import { READY_PRODUCT_QUERY_KEY } from "../../queries-keys/readyToBuy";
import { CART_ITEMS_TYPES } from "../../constants/cartItems";

const Product = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, user } = useUser();
  const { productId } = useParams();
  const [displayedImage, setDisplayedImage] = useState();
  const { cartItems } = useGetCart();
  const { addCartItemMutation } = useAddCartItem();
  const { handleOpenSnackbar } = useSnackbar();

  const {
    data: product,
    isLoading,
    isFetching,
  } = useQuery(
    READY_PRODUCT_QUERY_KEY(productId),
    () => getReadyProduct(productId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (product) => {
        setDisplayedImage(product?.images?.[0]);
      },
      onError: (error) => {
        // TODO: navigate to error page instead of open the snackbar
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    },
  );

  const images = product?.images || [];

  const handleAddToCart = () => {
    if (!isLoggedIn || !user) {
      return navigate(ROUTES.SIGN_IN.path, { state: { from: location } });
    }

    const found = cartItems.some(
      (cartItem) =>
        cartItem?.type === CART_ITEMS_TYPES.FURNITURE &&
        cartItem[cartItem?.type]?.id === product.id,
    );

    if (!found) {
      addCartItemMutation({
        furniture: product.id,
        type: CART_ITEMS_TYPES.FURNITURE,
      });
    }
  };

  return (
    <PageWrapper>
      {isLoading || isFetching ? (
        <ProductSkeleton />
      ) : (
        <div className="flex flex-col space-y-3 text-primary lg:space-y-5">
          <span className="text-[24px] font-bold lg:text-[34px]">
            {product?.name}
          </span>
          <div className="flex grid-cols-11 flex-col gap-5 lg:grid lg:gap-10">
            <div className="col-span-6 flex flex-col space-y-3">
              <ImageContainer
                src={displayedImage || product?.image}
                alt={product?.name}
                containerClassName="aspect-[13/9] rounded overflow-hidden"
                imgClassName={"w-full h-full object-contain"}
              />
              <ProductImagesContainer
                images={images}
                setDisplayedImage={setDisplayedImage}
              />
              <div className="hidden lg:block">
                <RandomProducts currentProduct={product} />
              </div>
            </div>
            {/* <div className="w-full md:hidden">
              <ImageSlider image={product?.image} images={images} />
            </div> */}
            <div className="col-span-5 flex h-fit flex-col space-y-5 lg:space-y-10">
              <ProductDetails product={product} />
              <AddToCartButton
                onClick={handleAddToCart}
                className="ml-auto w-full lg:w-[180px]"
              />
              <ServiceInfo productId={productId} />
            </div>
          </div>
          <div className="lg:hidden">
            <RandomProducts currentProduct={product} />
          </div>
        </div>
      )}
    </PageWrapper>
  );
};

export default Product;
