import PageWrapper from '../../components/pageWrapper';

const FindStyle = () => {
  return (
    <PageWrapper>
      <div></div>
    </PageWrapper>
  );
};

export default FindStyle;
