import User from "./User";

export default class BookingSlot {
  id: number;
  type: string;
  start: Date;
  duration: string;
  user: User | null = null;  
  consultant: User | null = null;
  price: number;
  notes: string | null = null;
  eventLink: string | null = null;
  meetingLink: string | null = null;
  canBeRescheduled: boolean;
  rescheduleTimes: number;
  consultantFirstName: string;
  consultantLastName: string;
  consultantMobile: string;

  constructor({
    id,
    type,
    start,
    duration,
    user,
    consultant,
    price,
    notes,
    event_link,
    meet_link,
    can_be_rescheduled,
    reschedule_times,
    consultant_first_name,
    consultant_last_name,
    consultant_mobile,
  }: {
    id: number;
    type: string;
    start: Date;
    duration: string;
    user: any | null;
    consultant: any | null;
    price: number;
    notes: string | null;
    event_link: string | null;
    meet_link: string | null;
    can_be_rescheduled: boolean;
    reschedule_times: number;
    consultant_first_name: string;
    consultant_last_name: string;
    consultant_mobile: string;
  }) {
    this.id = id;
    this.type = type;
    this.start = new Date(start);
    this.duration = duration;
    this.user = user && new User(user);
    this.consultant = consultant && new User(consultant);
    this.price = price;
    this.notes = notes;
    this.eventLink = event_link;
    this.meetingLink = meet_link;
    this.canBeRescheduled = can_be_rescheduled;
    this.rescheduleTimes = reschedule_times;
    this.consultantFirstName = consultant_first_name;
    this.consultantLastName = consultant_last_name;
    this.consultantMobile = consultant_mobile;
  }
}


