import api from "./config/axiosConfig";
import User from "../models/User";

const AUTH_URL = "/auth";

export const signUp = async ({
  firstName,
  lastName,
  password,
  confirmedPassword,
  email,
  mobile,
  OTP,
}) => {
  await api.post(
    `${AUTH_URL}/register/`,
    {
      first_name: firstName,
      last_name: lastName,
      password,
      password2: confirmedPassword,
      otp: OTP,
      email,
      mobile: mobile.startsWith("+2") ? mobile : "+2" + mobile,
    },
    { shouldIncludeToke: false },
  );
};

export const signIn = async (email, password) => {
  const response = await api.post(
    `${AUTH_URL}/login/`,
    {
      email,
      password,
    },
    { shouldIncludeToke: false },
  );

  return { user: new User(response?.data?.user), token: response.data?.token };
};

export const signOut = async () => {
  const response = await api.post(`${AUTH_URL}/logout/`);

  return response.data;
};

export const resetPassword = async ({ email, OTP, newPassword }) => {
  await api.post(
    `${AUTH_URL}/forget-password/`,
    { email, new_password: newPassword, otp: OTP },
    { shouldIncludeToke: false },
  );
};

export const getProfile = async () => {
  const response = await api.get(`${AUTH_URL}/profile/`);

  return new User(response.data);
};

export const generateOTP = async (email, requireRegisteredEmail=false) => {
  const response = await api.post(
    `${AUTH_URL}/generate-otp/`,
    { email, require_registered_email: requireRegisteredEmail },
    { shouldIncludeToke: false },
  );
  return response.data;
};
