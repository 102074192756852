import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OutlineButton from "../../components/customButtons/OutlineButton";
import ROUTES from "../../constants/routes";

const PaymentStatus = ({  }) => {
  const navigate=useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get('success') === "true";

  return (
    <div class="bg-gray-100 h-screen">
      <div class="bg-white p-6  md:mx-auto">
        {success 
          ? <svg className="h-64 w-64 text-green-600 mx-auto my-4"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="9" />  <path d="M9 12l2 2l4 -4" /></svg>
          : <svg className="h-64 w-64 text-red-600 mx-auto my-4"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>
        }
        <div class="text-center">
          <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment {success? "Done!" : "Failed!"}</h3>
          <p class="text-gray-600 my-2">
            {success 
              ? "Thank you for completing your secure online payment."
              : "Please try again later."
            }
            
          </p>
          <p> {success ? "Have a great day!" : "Thank you!"}  </p>
          <OutlineButton 
            className="px-12 py-3 my-10"
            onClick={()=>(navigate(success ? ROUTES.PROFILE.path :  ROUTES.CART.path))}
          >
            {success ? "GO TO PROFILE" : "GO TO CART"}
          </OutlineButton>
        </div>
      </div>
    </div>
  )
};

export default PaymentStatus;
