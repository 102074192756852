import React from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import PageWrapper from "../../components/pageWrapper";
import ProductsSkeleton from "../../components/skeletons/ProductsSkeleton";
import useSnackbar from "../../components/snackbar/hooks/useSnackbar";
import useInView from "../../hooks/useInView";
import ImageContainer from "../../components/imageContainer";
import ROUTES from "../../constants/routes";
import { getErrorMessage } from "../../utils/errors";
import { formatToCurrency } from "../../utils/numbers";
import { getReadyProducts } from "../../apis/readyProductsApi";
import { READY_PRODUCTS_QUERY_KEY } from "../../queries-keys/readyToBuy";
import { CURRENCY } from "../../constants/common";
const FETCHING_PRODUCTS_LIMIT = 9;

const ReadyToBuy = () => {
  const navigate = useNavigate();
  const { handleOpenSnackbar } = useSnackbar();
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery(
      READY_PRODUCTS_QUERY_KEY(),
      ({ pageParam = 0 }) =>
        getReadyProducts(pageParam, FETCHING_PRODUCTS_LIMIT),
      {
        getNextPageParam: (lastPage, allPages) => {
          const productsCount = allPages.reduce(
            (prevCount, page) => prevCount + (page?.products?.length || 0),
            0,
          );

          return lastPage?.hasNext ? productsCount : undefined;
        },
        onError: (error) => {
          handleOpenSnackbar(getErrorMessage(error), "error");
        },
      },
    );

  const { targetRef: LoaderRef } = useInView({
    onInView: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
  });

  const handleSelectProduct = (productId) => {
    navigate(`${ROUTES.PRODUCT.path}/${productId}`);
  };

  return (
    <PageWrapper>
      <div className="flex flex-col space-y-3 text-primary lg:space-y-5">
        <span className="text-[24px] font-bold lg:text-[34px]">
          Limited Collection
        </span>
        <span className="text-[14px] font-light lg:w-2/5">
          Explore our selection of pre-designed furniture pieces, available in limited
          quantities for an exclusive touch. These expertly crafted items are ready for
          immediate purchase and quick delivery, allowing you to enhance your space
          without the wait.
        </span>
        <div className="grid auto-rows-fr grid-cols-1 gap-x-1 gap-y-3 md:grid-cols-2 lg:grid-cols-3">
          {isLoading ? (
            <ProductsSkeleton />
          ) : (
            data?.pages?.map((page) => {
              return page?.products?.map((product) => {
                return (
                  <div key={product?.id} className="flex flex-col space-y-1">
                    <ImageContainer
                      src={product?.image}
                      alt={product?.name}
                      containerClassName="aspect-[11/9] rounded overflow-hidden"
                      imgClassName="h-full w-full object-fill cursor-pointer"
                      onClick={() => handleSelectProduct(product?.id)}
                    />
                    <span
                      className="w-fit cursor-pointer text-[14px] font-light"
                      onClick={() => handleSelectProduct(product?.id)}
                    >
                      {product?.name}
                    </span>
                    <span className="w-fit text-[14px] font-light">
                      {formatToCurrency(product?.price,CURRENCY)}
                    </span>
                  </div>
                );
              });
            })
          )}
        </div>
        <div
          ref={LoaderRef}
          className={`flex justify-center !pt-5 ${
            !isFetchingNextPage && "h-0 w-0 overflow-hidden opacity-0"
          }`}
        >
          <MoonLoader color="#24595C" />
        </div>
      </div>
    </PageWrapper>
  );
};

export default ReadyToBuy;
