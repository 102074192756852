import Tooltip from '../tooltip';

function SubStep({
  label,
  isCurrentStep = false,
  isCompleted = false,
  jumpingDisabled = false,
  onClick,
}) {

  return (
    <div className='flex items-center w-full'>
      <Tooltip title={label}>
        <div
          className={`h-2 w-2 rounded-full ${
            !jumpingDisabled && 'cursor-pointer'
          } transition duration-500 ${
            isCurrentStep || isCompleted ? 'bg-primary' : 'bg-secondary'
          }`}
          onClick={!jumpingDisabled? onClick : () => {}}
        ></div>
      </Tooltip>
      <div className={`h-[2px] flex-1 ${isCompleted ? 'bg-primary' : 'bg-secondary'}`}></div>
    </div>
  );
}

export default SubStep;
