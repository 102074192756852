import { useMutation, useQueryClient } from "react-query";

import { requestSession } from "../apis/sessionApi";
import { SESSION_QUERY_KEY } from "../queries-keys/sessions";
import { getErrorMessage } from "../utils/errors";
import { SESSIONS_STATES } from "../constants/sessions";

const useCreateSession = ({ onSessionCreated = () => {} } = {}) => {
  const queryClient = useQueryClient();

  const {
    mutate: createSessionMutation,
    data: createdSession,
    isLoading: isCreatingSession,
    isError: isCreatingSessionFailed,
    error: creatingSessionError,
  } = useMutation(requestSession, {
    onSuccess: (session) => {
      if (session?.state === SESSIONS_STATES.APPROVED) {
        onSessionCreated && onSessionCreated(session);
      } else {
        queryClient.setQueryData(SESSION_QUERY_KEY(), null);
        queryClient.invalidateQueries(SESSION_QUERY_KEY());
      }
    },
  });

  return {
    createSessionMutation,
    createdSession,
    isCreatingSession,
    isCreatingSessionFailed,
    creatingSessionError: getErrorMessage(creatingSessionError),
  };
};

export default useCreateSession;
