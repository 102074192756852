import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { Outlet } from "react-router-dom";

import Loader from "../loader";
import SessionHandler from "../sessionHandler";
import useGetSession from "../../hooks/useGetSession";
import { SESSION_QUERY_KEY } from "../../queries-keys/sessions";
import { SESSIONS_STATES } from "../../constants/sessions";

function RequireSessionApproval() {
  const queryClient = useQueryClient();
  const { session, isLoading, isFetching, isError, error } = useGetSession();

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(SESSION_QUERY_KEY());
    };
  }, []);

  return (
    <>
      {isLoading || isFetching ? (
        <Loader className={"min-h-[400px]"} />
      ) : // TODO: navigate to the error page if there is an error
      isError ||
        session?.state !== SESSIONS_STATES.ACTIVATED ||
        !session?.remainingTime ? (
        <SessionHandler session={session} />
      ) : (
        <Outlet
          context={{
            sessionRemainingTime: session?.remainingTime * 1000,
          }}
        />
      )}
    </>
  );
}

export default RequireSessionApproval;
