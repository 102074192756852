import React from "react";
import PageWrapper from "../../components/pageWrapper";

const NotFound = () => {
  return (
    <PageWrapper>
      <div className="flex items-center justify-center text-xl">
        404 Not Found
      </div>
    </PageWrapper>
  );
};

export default NotFound;
