import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Collapse } from "@mui/material";

export default function Accordion({
  enable = false,
  summary,
  className,
  children,
}) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={`flex h-full w-full flex-col space-y-3 overflow-hidden text-primary lg:space-y-5 ${className}`}
    >
      <div
        onClick={() => setExpanded((prev) => !prev)}
        className="flex items-center justify-between space-x-5"
      >
        <span className="font-medium">{summary}</span>
        {enable && (
          <IoIosArrowDown
            className={`shrink-0 cursor-pointer text-secondary transition-transform duration-200 hover:text-primary ${expanded ? "rotate-180" : "rotate-0"}`}
            size={25}
          />
        )}
      </div>

      <Collapse in={expanded || !enable}>{children}</Collapse>
    </div>
  );
}
