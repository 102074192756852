import React from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";

import TwoDViewer from "./TwoDViewer";
import Table from "../../../components/table";
import ServiceInfo from "../../../components/ServiceInfo";
import useStepper from "../../../components/stepper/hooks/useStepper";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { formatToCurrency } from "../../../utils/numbers";
import { CURRENCY } from "../../../constants/common";

function Viewer() {
  const { item } = useOutletContext() || {};
  const { isFinalStep, currentStep } = useStepper();
  const isMinWidth1024 = useMediaQuery("(min-width: 1024px)");
  const pricesTable = useSelector((state) => state.design.prices);
  const totalPricePerStep = useSelector((state) => state.design.totalPricePerStep);
  const totalPriceRangePercentagePerStep = useSelector(
    (state) => state.design.totalPriceRangePercentagePerStep,
  );
  const priceMode = useSelector((state) => state.design.priceMode);

  return (
    <>
      <TwoDViewer />
      {isMinWidth1024 && (
        <>
          {!isFinalStep && (
            <>
              <Table rows={pricesTable.slice(0,currentStep)} />
              <div className="flex items-center justify-between px-5 text-primary">
                <span className="text-[18px] font-medium">Total Price</span>
                <span className="text-[32px] font-bold">
                  {formatToCurrency(
                    totalPricePerStep[currentStep-1],
                    CURRENCY,
                    totalPriceRangePercentagePerStep[currentStep-1],
                    priceMode
                  )}
                </span>
              </div>
            </>
          )}
          <ServiceInfo itemId={item?.id} />
        </>
      )}
    </>
  );
}

export default Viewer;
