import { useMutation,useQueryClient } from "react-query";
import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getErrorMessage } from "../utils/errors";
import { createDesignPricingRequest } from "../apis/designsApi";
import { CART_ITEMS_QUERY_KEY } from "../queries-keys/cartItems";
import useUser from "./useUser";


const useCreateDesignPricingRequest = () => {
  const { handleOpenSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { user } = useUser();

  const { mutate: createDesignPricingRequestMutation, isLoading: isPricingRequestLoading } =
    useMutation({
      mutationFn: createDesignPricingRequest,
      onSuccess: () => {
        queryClient.invalidateQueries(CART_ITEMS_QUERY_KEY(user?.id));
        handleOpenSnackbar("Design pricing request created successfully", "success");
      },
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    });

  return {
    createDesignPricingRequestMutation,
    isPricingRequestLoading,
  };
};

export default useCreateDesignPricingRequest;
