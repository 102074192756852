export const READY_PRODUCTS_QUERY_KEY = () => ["ready-products"];
export const RANDOM_READY_PRODUCTS_QUERY_KEY = () => ["random-ready-products"];
export const READY_PRODUCT_QUERY_KEY = (productId) => [
  "ready-products",
  productId,
];
export const READY_PRODUCT_SERVICE_QUESTIONS_QUERY_KEY = (productId) => [
  "ready-products",
  productId,
  "service-question",
];
