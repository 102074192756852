import { useMutation, useQueryClient } from "react-query";

import useUser from "./useUser";
import { signIn, signOut, signUp } from "../apis/authApi";
import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getErrorMessage } from "../utils/errors";

const useAuth = ({ onRegister = () => {}, onLogOut = () => {} } = {}) => {
  const queryClient = useQueryClient();
  const { handleOpenSnackbar } = useSnackbar();
  const { saveUser, deleteUser } = useUser();

  const registerMutation = useMutation((data) => signUp(data), {
    onSuccess: () => {
      onRegister();
    },
  });

  const logInMutation = useMutation(
    (credentials) => signIn(credentials.email, credentials.password),
    {
      onSuccess: (data) => {
        const { user, token } = data;
        saveUser(user);
        localStorage.setItem("token", token);
      },
    },
  );

  const logOutMutation = useMutation(() => signOut(), {
    onSuccess: () => {
      deleteUser();
      localStorage.removeItem("token");
      queryClient.removeQueries();
      onLogOut();
    },
    onError: (error) => {
      handleOpenSnackbar(getErrorMessage(error), "error");
    },
  });

  const register = (data) => {
    registerMutation.mutate(data);
  };

  const logIn = (email, password) => {
    logInMutation.mutate({ email, password });
  };

  const logOut = () => {
    logOutMutation.mutate();
  };

  return {
    register,
    registerLoading: registerMutation.isLoading,
    registerError: registerMutation.isError
      ? getErrorMessage(registerMutation.error)
      : null,
    logIn,
    logInLoading: logInMutation.isLoading,
    logInError: logInMutation.isError
      ? getErrorMessage(logInMutation.error)
      : null,
    logOut,
    logOutLoading: logOutMutation.isLoading,
    logOutError: logOutMutation.isError
      ? getErrorMessage(logOutMutation.error)
      : null,
  };
};

export default useAuth;
