import React from "react";

import PageWrapper from "../../components/pageWrapper";
import PersonalDetails from "./components/PersonalDetails";
import ConsultantNextConsultations from "./components/ConsultantNextConsultations";
import ConsultantPrevConsultations from "./components/ConsultantPrevConsultations";

const ConsultantProfile = () => {
  return (
    <PageWrapper>
      <div className="flex flex-col space-y-3 text-primary lg:space-y-5">
        <h1 className="text-[24px] font-bold lg:text-[34px]">My Account</h1>
        <div className="flex flex-col space-y-2 lg:h-[600px] lg:flex-row lg:space-x-3 lg:space-y-0">
          <div className="flex h-fit w-full flex-col space-y-2 lg:space-y-3">
            <PersonalDetails />
          </div>
          <ConsultantNextConsultations />
          <ConsultantPrevConsultations />
        </div>
      </div>
    </PageWrapper>
  );
};

export default ConsultantProfile;
