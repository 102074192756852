import { useMutation, useQueryClient } from "react-query";
import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getErrorMessage } from "../utils/errors";
import { updateSlotNotes } from "../apis/consultationApi";
import {
  CONSULTANT_PREVIOUS_SLOTS,
  CONSULTANT_NEXT_SLOTS,
  CLIENT_PREVIOUS_SLOTS,
} from "../queries-keys/consultation";

const useUpdateSlotNotes = ({ client_id }) => {
  const { handleOpenSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: updateSlotNotesMutation, isLoading: isUpdatingSlotNotes } =
    useMutation({
      mutationFn: updateSlotNotes,
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
      onSuccess: (response) => {
        handleOpenSnackbar("Notes updated successfully!", "success");
        queryClient.invalidateQueries(CONSULTANT_NEXT_SLOTS());
        queryClient.invalidateQueries(CONSULTANT_PREVIOUS_SLOTS());
        queryClient.invalidateQueries(CLIENT_PREVIOUS_SLOTS(client_id));
      },
    });

  return {
    updateSlotNotesMutation,
    isUpdatingSlotNotes,
  };
};

export default useUpdateSlotNotes;
