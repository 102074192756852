import React from "react";
import { useNavigate } from "react-router-dom";

import ROUTES from "../../constants/routes";
import PageWrapper from "../../components/pageWrapper";
import Header from "../../components/header";
import Footer from "../../components/footer";
import MainButton from "../../components/customButtons/MainButton";
import OutlineButton from "../../components/customButtons/OutlineButton";

const Intro = ({ title, image, body, innerRef }) => {
  const navigate = useNavigate();

  const handleClick = (route) => {
    navigate(route.path);
  };

  return (
    <div ref={innerRef} className="h-screen overflow-y-auto">
      <div>
        <Header />
        <PageWrapper>
          <div className="flex flex-col-reverse md:flex-row md:space-x-10">
            <div className="flex flex-col justify-between md:w-2/5">
              <div className="flex flex-col space-y-4">
                <h1 className="text-9xl mt-4 font-bold text-primary md:mt-0 md:text-3xl">
                  {title || "Weclome To WAE,"}
                </h1>

                <p className="text-primary">
                  {body ||
                      <>
                        WAE is an online customizing platform that allows you to design your ideal furniture piece by selecting from various options on our website.
                        Simply choose the design elements you prefer, and we'll create a custom piece tailored to your specifications.
                        Additionally, our consultancy service is available to assist with your needs, ensuring expert guidance every step of the way.
                        We aim to make furnishing your home as smooth and pleasant as possible. <br /> <br />
                        Enjoy a seamless experience in bringing your vision to life, or in other words do it your WAE!
                      </>
                    }
                </p>
              </div>

              <div className="mt-4 flex flex-col justify-end space-y-2 md:flex-row md:space-x-2 md:space-y-0">
                <OutlineButton
                  className="px-10"
                  onClick={() => handleClick(ROUTES.CONSULTANT)}
                >
                  Book a Consultant
                </OutlineButton>
                <MainButton
                  className="!whitespace-normal rounded px-10"
                  onClick={() => handleClick(ROUTES.SHOP)}
                >
                  Start Shopping
                </MainButton>
              </div>
            </div>
            <div className="flex flex-col md:w-3/5">
              <img src={image || "/resources/home.png"} alt="Introduction" />
            </div>
          </div>
        </PageWrapper>
      </div>
      <Footer />
    </div>
  );
};

export default Intro;
