export default class Session {
  id: number;
  remainingTime: number | null;
  state: string;
  createdAt: string;
  accessedAt: string;
  sessionDuration: string;
  user: number; // TODO: add user class

  constructor({
    id,
    remaining_time,
    state,
    created_at,
    accessed_at,
    session_duration,
    user,
  }: {
    id: number;
    remaining_time: number | null;
    state: string;
    created_at: string;
    accessed_at: string;
    session_duration: string;
    user: number;
  }) {
    this.id = id;
    this.remainingTime = remaining_time;
    this.state = state;
    this.createdAt = created_at;
    this.accessedAt = accessed_at;
    this.sessionDuration = session_duration;
    this.user = user;
  }
}
