import React, { useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { AiOutlinePlus } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Joi from "joi";

import MainButton from "../customButtons/MainButton";
import useSnackbar from "../snackbar/hooks/useSnackbar";
import Radio from "../Radio";
import InputField from "../customInputField/InputField";
import Alert from "../alert";
import validators from "../../constants/validators";
import {
  getAddresses,
  addAddress,
  updateAddress,
  deleteAddress,
} from "../../apis/usersApi";
import { getErrorMessage } from "../../utils/errors";
import {
  USER_ADDRESSES_QUERY_KEY,
  PROFILE_QUERY_KEY,
} from "../../queries-keys/user";

function UserAddressesHandler({
  enableSelecting = false,
  selectedAddress = {},
  setSelectedAddress = () => {},
}) {
  const [openAddNewAddressDialog, setOpenAddNewAddressDialog] = useState(false);
  const [currentUpdatingAddress, setCurrentUpdatingAddress] = useState();
  const [currentDeletedAddress, setCurrentDeletedAddress] = useState();
  const queryClient = useQueryClient();
  const { handleOpenSnackbar } = useSnackbar();

  const { data: addresses, isLoading: isFetchingAddresses } = useQuery(
    USER_ADDRESSES_QUERY_KEY(),
    getAddresses,
    {
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    },
  );

  const { mutate: deleteAddressMutation, isLoading: isDeletingAddress } =
    useMutation((addressId) => deleteAddress(addressId), {
      onSuccess: () => {
        queryClient.invalidateQueries(USER_ADDRESSES_QUERY_KEY());
        queryClient.refetchQueries(PROFILE_QUERY_KEY());

        handleOpenSnackbar("Address deleted successfully", "success");
      },
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    });

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
  };

  return isFetchingAddresses ? (
    <div className="flex items-center justify-center">
      <MoonLoader color="#24595C" />
    </div>
  ) : (
    <div className="flex h-full max-h-[400px] w-full flex-col space-y-2 overflow-y-auto pr-2">
      {addresses.map((address, index) => {
        const isSelected = selectedAddress?.id === address?.id;

        const isUpdatingCurrentAddress =
          currentUpdatingAddress?.id === address?.id;

        return (
          <div key={address?.id} className={`flex flex-col`}>
            <div className="flex items-center space-x-2 text-primary">
              {enableSelecting ? (
                <Radio
                  checked={isSelected}
                  onChange={() => handleSelectAddress(address)}
                />
              ) : (
                <GoDotFill className="shrink-0" />
              )}
              <div className="flex flex-wrap">
                <span>{address?.state}</span>
                <span>
                  {", "}
                  {address?.city}
                </span>
                <span>
                  {", "}
                  {address?.street}
                </span>
                {address?.additionalInfo && (
                  <span>
                    {", "}
                    {address?.additionalInfo}
                  </span>
                )}
              </div>

              <FiEdit3
                size={20}
                className="!ml-2 shrink-0 cursor-pointer text-secondary hover:text-primary"
                onClick={() => {
                  setOpenAddNewAddressDialog(false);
                  isUpdatingCurrentAddress
                    ? setCurrentUpdatingAddress(undefined)
                    : setCurrentUpdatingAddress(address);
                }}
              />
              {isDeletingAddress &&
              currentDeletedAddress?.id === address?.id ? (
                <AiOutlineLoading3Quarters
                  size={20}
                  className="shrink-0 animate-spin text-primary"
                />
              ) : (
                <MdOutlineDeleteOutline
                  size={20}
                  className="!ml-2 shrink-0 cursor-pointer text-secondary hover:text-primary"
                  onClick={() => {
                    handleSelectAddress(null);
                    setCurrentDeletedAddress(address);
                    deleteAddressMutation(address?.id);
                  }}
                />
              )}
            </div>
            <AddNewAddressForm
              isUpdatingExistingAddress
              address={address}
              isOpen={isUpdatingCurrentAddress}
              onClose={() => setCurrentUpdatingAddress(undefined)}
            />
          </div>
        );
      })}
      <div className="flex flex-col space-y-3">
        <div
          className="flex w-fit cursor-pointer items-center space-x-2 text-secondary hover:text-primary"
          onClick={() => {
            setCurrentUpdatingAddress(undefined);
            setOpenAddNewAddressDialog((prev) => !prev);
          }}
        >
          <AiOutlinePlus />
          <span className="underline">Add a new address</span>
        </div>
        <AddNewAddressForm
          isOpen={openAddNewAddressDialog}
          onClose={() => setOpenAddNewAddressDialog(false)}
        />
      </div>
    </div>
  );
}

const AddNewAddressForm = ({
  isOpen = false,
  isUpdatingExistingAddress = false,
  address = {
    state: "",
    city: "",
    street: "",
    additionalInfo: "",
  },
  onClose = () => {},
}) => {
  const [newAddress, setNewAddress] = useState(address);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  const { handleOpenSnackbar } = useSnackbar();

  const addressSchema = Joi.object({
    ...validators.address,
  });

  const { mutate, isLoading } = useMutation(
    (addressData) =>
      isUpdatingExistingAddress
        ? updateAddress(addressData?.id, { ...addressData, id: undefined })
        : addAddress(addressData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(USER_ADDRESSES_QUERY_KEY());
        queryClient.refetchQueries(PROFILE_QUERY_KEY());
        !isUpdatingExistingAddress &&
          setNewAddress({
            state: "",
            city: "",
            street: "",
            additionalInfo: "",
          });
        handleOpenSnackbar(
          isUpdatingExistingAddress
            ? "Address updated successfully"
            : "Address added successfully",
          "success",
        );
        onClose();
      },
      onError: (error) => {
        setError(getErrorMessage(error));
      },
    },
  );

  const handleAddressChange = (fieldName, value) => {
    setError("");
    setNewAddress((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleSaveAddress = () => {
    const { error } = addressSchema.validate({
      state: newAddress.state,
      city: newAddress.city,
      street: newAddress.street,
      additionalInfo: newAddress.additionalInfo,
    });

    if (error) {
      return setError(error.message);
    }

    mutate(newAddress);
  };

  return (
    <div
      className={`transition-height h-fit overflow-hidden duration-300 ease-in ${
        isOpen ? "max-h-[400px]" : "max-h-0"
      }`}
    >
      <div className={`flex w-full flex-col space-y-3 px-5 py-2`}>
        <Alert isOpen={error} message={error} severity={"error"} />
        <InputField
          placeholder={"State"}
          value={newAddress?.state || ""}
          onChange={(event) => handleAddressChange("state", event.target.value)}
        />
        <InputField
          placeholder={"City"}
          value={newAddress?.city || ""}
          onChange={(event) => handleAddressChange("city", event.target.value)}
        />
        <InputField
          placeholder={"Street"}
          value={newAddress?.street || ""}
          onChange={(event) =>
            handleAddressChange("street", event.target.value)
          }
        />
        <InputField
          placeholder={"Additional Information"}
          value={newAddress?.additionalInfo || ""}
          onChange={(event) =>
            handleAddressChange("additionalInfo", event.target.value)
          }
        />
        <MainButton
          className={"mx-auto w-full lg:max-w-[200px]"}
          isLoading={isLoading}
          disabled={
            !newAddress?.state || !newAddress?.city || !newAddress?.street
          }
          onClick={handleSaveAddress}
        >
          {isUpdatingExistingAddress ? "Update" : "Add"}
        </MainButton>
      </div>
    </div>
  );
};

export default UserAddressesHandler;
