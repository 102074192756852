export default class ReadyProduct {
  id: number;
  image: string;
  images: string[];
  name: string;
  price: number;
  details: string;
  width: number;
  length: number;
  height: number;
  createdAt: string;
  updatedAt: string;

  constructor({
    id,
    image,
    images,
    name,
    price,
    details,
    width,
    length,
    height,
    created_at,
    updated_at,
  }: {
    id: number;
    image: string;
    images: string[];
    name: string;
    price: number;
    details: string;
    width: number;
    length: number;
    height: number;
    created_at: string;
    updated_at: string;
  }) {
    this.id = id;
    this.image = image;
    this.images = images;
    this.name = name;
    this.price = price;
    this.details = details;
    this.width = width;
    this.length = length;
    this.height = height;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
  }
}