import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

function MainButton({
  children,
  disabled = false,
  isLoading = false,
  type = 'button',
  onClick,
  className,
  width = null,
}) {
  return (
    <button
      className={`whitespace-nowrap rounded-md bg-highlight p-2 text-white transition-colors duration-100 hover:bg-red-700
                ${disabled && 'cursor-not-allowed !bg-gray-200 !text-softGray'}
                ${width ? `w-[${width}px]` : 'min-w-[120px]'}
                ${className}`}
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
    >
      {isLoading && !disabled ? (
        <AiOutlineLoading3Quarters
          size={20}
          className='mx-auto my-auto shrink-0 animate-spin text-white'
        />
      ) : (
        children
      )}
    </button>
  );
}

MainButton.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default MainButton;
