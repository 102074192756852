import React from "react";
import PropTypes from "prop-types";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";

import Step from "./Step";
import SubStep from "./SubStep";
import LastStep from "./LastStep";
import useStepperLogic from "./hooks/useStepperLogic";
import useStepper from "./hooks/useStepper";

const DEFAULT_STEPS_VALUE = [];

function Stepper({
  steps = DEFAULT_STEPS_VALUE,
  disableNext = false,
  disablePrevious = false,
  disableJumping = false,
}) {
  const {
    steps: stepsData,
    allStepsCount,
    currentStep,
    jumpingDisabled,
    jumpTo,
  } = useStepperLogic(steps, {
    disablePrevious,
    disableNext,
    disableJumping,
  });

  const { nextStep, previousStep, prevButtonDisabled, nextButtonDisabled } =
    useStepper();

  let stepsCount = 0;

  return (
    <div className="flex items-center">
      <span
        className={`lg:mr-3 ${
          prevButtonDisabled
            ? "cursor-default text-softGray"
            : "cursor-pointer text-highlight hover:text-red-700 hover:underline underline-offset-2"
        }`}
        onClick={previousStep}
      >
        <MdKeyboardDoubleArrowLeft size={24} />
      </span>

      {stepsData.map((step, index) => {
        const stepIdx = stepsCount + 1;
        stepsCount += step?.subSteps?.length || 1;
        return (
          <React.Fragment key={index}>
            <Step
              label={step?.label}
              icon={step?.icon}
              isCurrentStep={currentStep === stepIdx}
              isCompleted={
                step?.subSteps?.length
                  ? currentStep >= stepIdx
                  : currentStep > stepIdx
              }
              jumpingDisabled={jumpingDisabled}
              onClick={() => jumpTo(stepIdx)}
            />
            {step?.subSteps?.map((subStep, index) => {
              const subStepIdx = stepIdx + index;
              return (
                <React.Fragment key={subStepIdx}>
                  <SubStep
                    label={subStep?.label}
                    isCurrentStep={currentStep === subStepIdx}
                    isCompleted={currentStep > subStepIdx}
                    jumpingDisabled={jumpingDisabled}
                    onClick={() => jumpTo(subStepIdx)}
                  />
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      })}

      <LastStep
        steps={stepsData}
        isCurrentStep={currentStep > allStepsCount}
        jumpingDisabled={jumpingDisabled}
        onClick={() => jumpTo(allStepsCount + 1)}
      />

      <span
        className={`lg:ml-3 ${
          nextButtonDisabled
            ? "cursor-default text-softGray"
            : "cursor-pointer text-highlight hover:text-red-700 hover:underline underline-offset-2"
        }`}
        onClick={nextStep}
      >
        <MdKeyboardDoubleArrowRight size={24} />
      </span>
    </div>
  );
}

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      subSteps: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
        }),
      ),
    }),
  ),
  disableNext: PropTypes.bool,
  disablePrevious: PropTypes.bool,
  disableJumping: PropTypes.bool,
};

export default Stepper;
