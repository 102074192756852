import { useQuery } from "react-query";

import useUser from "./useUser";
import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getCartItems } from "../apis/cartApi";
import { CART_ITEMS_QUERY_KEY } from "../queries-keys/cartItems";
import { getErrorMessage } from "../utils/errors";
import { USER_ROLES } from "../constants/userRoles";

const useGetCartItems = (onSuccessCallback) => {
  const { handleOpenSnackbar } = useSnackbar();
  const { user, isLoggedIn } = useUser();

  const { data: cartItems = [], isLoading: isLoadingCartItems } = useQuery({
    queryKey: CART_ITEMS_QUERY_KEY(user?.id),
    queryFn: getCartItems,
    onError: (error) => {
      handleOpenSnackbar(getErrorMessage(error), "error");
    },
    onSuccess: (cartItems) => {
      if (onSuccessCallback) {
        onSuccessCallback(cartItems);
      }
    },
    enabled: Boolean(isLoggedIn && user?.id && user?.role === USER_ROLES.USER),
  });

  return {
    cartItems,
    isLoadingCartItems,
  };
};

export default useGetCartItems;
