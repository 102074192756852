import { useMutation, useQueryClient } from "react-query";

import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import useUser from "./useUser";
import { getErrorMessage } from "../utils/errors";
import { addCartItem } from "../apis/cartApi";
import { CART_ITEMS_QUERY_KEY } from "../queries-keys/cartItems";
import { WISHLIST_QUERY_KEY } from "../queries-keys/wishlist";
import { CART_ITEMS_TYPES } from "../constants/cartItems";

const DEFAULT_PARAM = {};

const useAddCartItem = ({ onSuccess = () => {} } = DEFAULT_PARAM) => {
  const { handleOpenSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { user } = useUser();

  const {
    mutate: addCartItemMutation,
    mutateAsync: addCartItemMutationAsync,
    isLoading: isAddingCartItemLoading,
  } = useMutation({
    mutationFn: addCartItem,
    onSuccess: (cartItem) => {
      queryClient.invalidateQueries(CART_ITEMS_QUERY_KEY(user?.id));
      if (cartItem?.type === CART_ITEMS_TYPES.DESIGN) {
        queryClient.invalidateQueries(WISHLIST_QUERY_KEY());
      }
      onSuccess(cartItem);
    },
    onError: (error) => {
      handleOpenSnackbar(getErrorMessage(error), "error");
    },
  });

  return {
    addCartItemMutation,
    addCartItemMutationAsync,
    isAddingCartItemLoading,
  };
};

export default useAddCartItem;
