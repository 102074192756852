import React, { useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";

import BookingDetailsCard from "./BookingDetailsCard";
import SlotsSelector from "./SlotsSelector";

function AppointmentScheduler({ bookingDetails, bookingDispatch }) {
  const [searchParams, setSearchParams] = useSearchParams();

  useLayoutEffect(() => {
    if (!bookingDetails?.type) {
      searchParams.delete("step");
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <div className="flex flex-col space-y-3 text-primary lg:space-y-5">
      <h1 className="text-[24px] font-bold lg:text-[34px]">
        Book a Consultant
      </h1>
      <div className="grid grid-cols-2 gap-5 lg:grid-cols-3 lg:gap-16">
        <div className="col-span-2 flex flex-col space-y-5 md:grid md:grid-cols-3 md:gap-16 md:space-y-0">
          <SlotsSelector {...{ bookingDetails, bookingDispatch }} />
        </div>
        <BookingDetailsCard {...{ bookingDetails, bookingDispatch }} />
      </div>
    </div>
  );
}

export default AppointmentScheduler;
