import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setCurrentRoute, setPrevRoute } from "../features/appSlice";

const useRouteTracker = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentRoute } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(setPrevRoute(currentRoute));
    dispatch(setCurrentRoute(location.pathname));
  }, [location]);
};

export default useRouteTracker;
