import React from "react";
import { CiSquarePlus, CiSquareMinus } from "react-icons/ci";
import { MdOutlineDeleteOutline } from "react-icons/md";

import OutlineButton from "../../../components/customButtons/OutlineButton";
import useUpdateCartItem from "../../../hooks/useUpdateCartItem";
import useDeleteCartItem from "../../../hooks/useDeleteCartItem";
import { CART_ITEMS_TYPES } from "../../../constants/cartItems";
import { DEFAULT_DESIGN_IMAGE } from "../../../constants/imagesUrls";
import { formatToCurrency } from "../../../utils/numbers";
import useCreateDesignPricingRequest from "../../../hooks/useCreateDesignPricingRequest";
import { CURRENCY } from "../../../constants/common";

function CartItemsTable({ cartData = [], noPriceCartData = [] }) {
  const { updateCartItemMutation } = useUpdateCartItem();
  const { deleteCartItemMutation } = useDeleteCartItem();

  const handleIncrementQuantity = (item) => {
    updateCartItemMutation({
      cartItemId: item.id,
      updatedCartItemData: {
        quantity: item.quantity + 1,
        type: item.type,
      },
    });
  };

  const handleDecrementQuantity = (item) => {
    updateCartItemMutation({
      cartItemId: item.id,
      updatedCartItemData: {
        quantity: item.quantity - 1,
        type: item.type,
      },
    });
  };
  const { createDesignPricingRequestMutation } = useCreateDesignPricingRequest();

  const handleRemoveItem = (item) => deleteCartItemMutation(item.id);

  return (
    <>
      <CartItemsTableMobileView
        {...{
          cartData,
          noPriceCartData,
          handleDecrementQuantity,
          handleIncrementQuantity,
          handleRemoveItem,
        }}
      />
      <table className="hidden h-max w-full flex-col space-y-5 overflow-x-auto lg:flex lg:w-3/4">
        <thead className="min-w-[600px] px-5 text-secondary">
          <tr className="grid w-full grid-cols-6 whitespace-nowrap text-center text-[14px] md:text-[16px]">
            <th className="col-span-2 md:text-left">Item</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Total Price</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody className="flex min-w-[600px] flex-col space-y-3 px-5 text-[14px] text-primary">
          {[...cartData, ...noPriceCartData].map((item, index) => {
            return (
              <tr
                key={index}
                className={`grid w-full grid-cols-6 whitespace-nowrap border-b-2 border-gray-200 pb-3 text-center last:border-none`}
              >
                <td
                  className={`col-span-2 flex items-center justify-start space-x-3  ${
                    !item.price && "opacity-40"
                  }`}
                >
                  <img
                    src={item?.image ? item?.image : DEFAULT_DESIGN_IMAGE}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = DEFAULT_DESIGN_IMAGE;
                    }}
                    className="h-14 w-14 rounded object-cover"
                    alt={item.name}
                  />
                  <span>{item.name}</span>
                </td>
                {item.price ? (
                  <>
                    {item.isAvailable ||
                    (item?.type === CART_ITEMS_TYPES.SAMPLE && item?.availableQuantity !== 0) ? (
                      <>
                        {item?.quantity > item?.availableQuantity ? (
                          <td className="text-red-500 m-auto whitespace-nowrap">Limited Stock</td>
                        ) : (
                          <td className="m-auto">
                            {formatToCurrency(item.price, CURRENCY, undefined, undefined, false)}
                          </td>
                        )}
                        <td className="flex items-center justify-evenly">
                          <button
                            className="text-red-500 hover:text-red-600 disabled:text-red-300 disabled:hover:text-red-300"
                            disabled={
                              item.type === CART_ITEMS_TYPES.FURNITURE || item.quantity <= 1
                            }
                            onClick={() => handleDecrementQuantity(item)}
                          >
                            <CiSquareMinus size={25} />
                          </button>
                          <span>{item.quantity}</span>
                          <button
                            className="text-red-500 hover:text-red-600 disabled:text-red-300 disabled:hover:text-red-300"
                            disabled={
                              item.type === CART_ITEMS_TYPES.FURNITURE ||
                              item?.availableQuantity <= item?.quantity
                            }
                            onClick={() => handleIncrementQuantity(item)}
                          >
                            <CiSquarePlus size={25} />
                          </button>
                        </td>
                        {item?.quantity > item?.availableQuantity ? (
                          <td className="text-red-500 m-auto whitespace-nowrap">
                            Only {item?.availableQuantity} available
                          </td>
                        ) : (
                          <td className="m-auto">
                            {formatToCurrency(
                              item.finalPrice,
                              CURRENCY,
                              undefined,
                              undefined,
                              false
                            )}
                          </td>
                        )}
                      </>
                    ) : (
                      <td className="col-span-3 text-red-500 m-auto whitespace-nowrap">
                        Out of Stock
                      </td>
                    )}
                    <td className="m-auto">
                      <MdOutlineDeleteOutline
                        size={25}
                        className="cursor-pointer text-secondary hover:text-primary"
                        onClick={() => {
                          handleRemoveItem(item);
                        }}
                      />
                    </td>
                  </>
                ) : (
                  <>
                    <td className="col-span-1 m-auto whitespace-nowrap">Pending Pricing</td>
                    {item?.designRequestExpired && (
                      <td className="col-span-3 m-auto whitespace-nowrap">
                        <OutlineButton
                          onClick={() => createDesignPricingRequestMutation(item?.itemId)}
                          className={
                            "!border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                          }
                        >
                          Send Pricing Request
                        </OutlineButton>
                      </td>
                    )}
                  </>
                )}
              </tr>
            );
          })}
          {!cartData.length && !noPriceCartData.length && (
            <tr className="flex items-center justify-center text-primary">
              <td className="">
                <span className="text-[18px] font-medium">No items to proceed with.</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

const CartItemsTableMobileView = ({
  cartData = [],
  noPriceCartData = [],
  handleDecrementQuantity,
  handleIncrementQuantity,
  handleRemoveItem,
}) => {
  const { createDesignPricingRequestMutation } = useCreateDesignPricingRequest();

  if (!cartData.length && !noPriceCartData.length) {
    return <span className="text-center font-medium lg:hidden">No items to proceed with.</span>;
  }

  return (
    <div className="flex flex-col space-y-3 text-[14px] lg:hidden">
      {[...cartData, ...noPriceCartData].map((item, index) => {
        return (
          <div
            key={index}
            className={`flex space-x-3 border-b-2 border-gray-200 pb-3 last:border-none `}
          >
            <img
              src={item?.image ? item?.image : DEFAULT_DESIGN_IMAGE}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = DEFAULT_DESIGN_IMAGE;
              }}
              className={`h-24 w-24 rounded object-cover ${!item.price && "opacity-40"}`}
              alt={item.name}
            />
            <div className={`flex flex-col space-y-1 ${!item.price && "text-secondary"} `}>
              <span>{item.name}</span>
              {item.isAvailable &&
                (!item.price ? (
                  <span className="text-[12px] text-primary">Pending Pricing</span>
                ) : (
                  <div className="flex flex-col space-y-1 justify-between h-full">
                    <span>{formatToCurrency(item.price, CURRENCY)}</span>
                    <span>{formatToCurrency(item.finalPrice, CURRENCY)}</span>
                  </div>
                ))}
              {!item.isAvailable &&
                (item?.type === CART_ITEMS_TYPES.SAMPLE && item?.availableQuantity !== 0 ? (
                  <span className="text-red-500">
                    Limited Stock: Only {item?.availableQuantity} available
                  </span>
                ) : (
                  <span className="text-red-500">Out of stock</span>
                ))}
            </div>
            <div className="!ml-auto flex flex-col items-end justify-between">
              {item.price && (
                <MdOutlineDeleteOutline
                  size={25}
                  className="cursor-pointer text-secondary hover:text-primary"
                  onClick={() => {
                    handleRemoveItem(item);
                  }}
                />
              )}
              {item.price
                ? (item?.isAvailable ||
                    (item?.type === CART_ITEMS_TYPES.SAMPLE && item?.availableQuantity !== 0)) && (
                    <div className="flex items-center justify-evenly space-x-2">
                      <button
                        className="text-red-500 hover:text-red-600 disabled:text-red-300 disabled:hover:text-red-300"
                        disabled={item.type === CART_ITEMS_TYPES.FURNITURE || item.quantity <= 1}
                        onClick={() => handleDecrementQuantity(item)}
                      >
                        <CiSquareMinus size={25} />
                      </button>
                      <span>{item.quantity}</span>
                      <button
                        className="text-red-500 hover:text-red-600 disabled:text-red-300 disabled:hover:text-red-300"
                        disabled={
                          item.type === CART_ITEMS_TYPES.FURNITURE ||
                          item?.availableQuantity <= item?.quantity
                        }
                        onClick={() => handleIncrementQuantity(item)}
                      >
                        <CiSquarePlus size={25} />
                      </button>
                    </div>
                  )
                : item?.designRequestExpired && (
                    <OutlineButton
                      onClick={() => createDesignPricingRequestMutation(item?.itemId)}
                      className={
                        "border-red-500 text-red-500 hover:bg-red-500 text-[12px] hover:text-white"
                      }
                    >
                      Send Pricing Request
                    </OutlineButton>
                  )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CartItemsTable;
