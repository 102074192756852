import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

function PasswordInputField({ required = false, filled = false, innerRef, className, ...rest }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleCopyPaste = (event) => {
    event.preventDefault();
  };

  return (
    <div className={`relative flex w-full items-center ${className}`}>
      <input
        ref={innerRef}
        {...rest}
        required={required}
        type={showPassword ? "text" : "password"}
        className={`h-full w-full border-b-[1px] border-softGray p-2 text-primary placeholder-secondary outline-none autofill-color ${
          filled && "bg-gray-50"
        }`}
        onCopy={handleCopyPaste}
        onPaste={handleCopyPaste}
      />
      {showPassword ? (
        <FiEye
          className="absolute right-5 mt-auto cursor-pointer"
          onClick={() => setShowPassword(false)}
        />
      ) : (
        <FiEyeOff
          className="absolute right-5 cursor-pointer"
          onClick={() => setShowPassword(true)}
        />
      )}
      {required && <span className="absolute right-0 text-center text-highlight">*</span>}
    </div>
  );
}

export default PasswordInputField;
