import api from "./config/axiosWith401Redirect";

const ADDRESS_URL = "user/address";

export const getAddresses = async () => {
  const response = await api.get(`${ADDRESS_URL}/`);

  return response.data?.map((address) => {
    return {
      ...address,
      additionalInfo: address?.["additional_info"],
    };
  });
};

export const addAddress = async (newAddressData) => {
  const response = await api.post(`${ADDRESS_URL}/`, {
    ...newAddressData,
    additional_info: newAddressData?.additionalInfo,
    additionalInfo: undefined,
  });

  return {
    ...response.data,
    additionalInfo: response.data?.["additional_info"],
  };
};

export const updateAddress = async (addressId, updatedAddressData) => {
  const response = await api.patch(`${ADDRESS_URL}/${addressId}/`, {
    ...updatedAddressData,
    additional_info: updatedAddressData?.additionalInfo,
    additionalInfo: undefined,
  });

  return {
    ...response.data,
    additionalInfo: response.data?.["additional_info"],
  };
};

export const deleteAddress = async (addressId) => {
  const response = await api.delete(`${ADDRESS_URL}/${addressId}/`);

  return response.data;
};
