import { useQuery } from "react-query";
import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getConsultantPreviousSlots } from "../apis/consultationApi";
import { CONSULTANT_PREVIOUS_SLOTS } from "../queries-keys/consultation";
import { getErrorMessage } from "../utils/errors";

const useGetConsultantPreviousSlots = (roomId) => {
  const { handleOpenSnackbar } = useSnackbar();

  const { data: consultantPreviousSlots, isLoading: isFetchingPreviousSlots } =
    useQuery({
      queryKey: CONSULTANT_PREVIOUS_SLOTS(),
      queryFn: getConsultantPreviousSlots,
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    });

  return {
    consultantPreviousSlots,
    isFetchingPreviousSlots,
  };
};

export default useGetConsultantPreviousSlots;
