import api from "./config/axiosConfig";

const LAYOUTS_URL = "/viewer/layout_by_item/";
const SERVICE_URL = "/product/design";

export const getItemLayouts = async (itemId) => {
  const response = await api.get(`${LAYOUTS_URL}${itemId}/`);
  // const itemsLayouts = response.data?.layout.map((layout) => new Layout(layout)) || [];
  const itemsLayouts = response.data;
  return itemsLayouts;
};

export const getItemServiceQuestion = async (itemId) => {
  const response = await api.get(`${SERVICE_URL}/${itemId}/questions/`);
  return response.data;
};

export const getItemsServiceInfo = async () => {
  const response = await api.get(`${SERVICE_URL}/info/`);
  return response.data;
};
