import React, { useState, useLayoutEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import Joi from "joi";

import ConsultationLayout from "./ConsultationLayout";
import MainButton from "../../../components/customButtons/MainButton";
import OutlineButton from "../../../components/customButtons/OutlineButton";
import InputField from "../../../components/customInputField/InputField";
import PasswordInputField from "../../../components/customInputField/PasswordInputField";
import Alert from "../../../components/alert";
import OtpInput from "../../../components/otpInput";
import useAuth from "../../../hooks/useAuth";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import validators from "../../../constants/validators";
import { generateOTP } from "../../../apis/authApi";

function UserInfoForm({ bookingDetails }) {
  const passwordFieldRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleOpenSnackbar } = useSnackbar();
  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [isLogInForm, setIsLogInForm] = useState(false);

  const { register, registerError, registerLoading, logIn, logInError, logInLoading } = useAuth({
    onRegister: () => {
      setIsLogInForm(true);
    },
  });

  const emailSchema = Joi.object({ email: validators.email });

  const signUpSchema = Joi.object({
    firstName: validators.firstName,
    lastName: validators.lastName,
    email: validators.email,
    mobile: validators.mobile,
    password: validators.password,
    confirmedPassword: validators.confirmedPassword,
    OTP: validators.OTP,
  });

  const { mutate: generateOTPMutation, isLoading: isGeneratingOTP } = useMutation({
    mutationFn: () => generateOTP(email),
    onSuccess: () => {
      handleOpenSnackbar("OTP sent successfully", "success");
    },
    onError: () => {
      handleOpenSnackbar("Enter a valid email address.", "error");
    },
  });

  const handleGenerateOTP = () => {
    const { error } = emailSchema.validate({ email: email });
    if (error) {
      return setError("Enter a valid email address");
    }
    generateOTPMutation();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setError("");

    if (isLogInForm) {
      const { email, password } = Object.fromEntries(new FormData(event.target));

      if (!email) {
        return setError("Email address is required.");
      }
      if (!password) {
        return setError("Password is required.");
      }

      return logIn(email, password);
    }

    const { firstName, lastName, email, mobile, password, confirmedPassword } = Object.fromEntries(
      new FormData(event.target)
    );

    const { error } = signUpSchema.validate({
      firstName,
      lastName,
      email,
      mobile,
      password,
      confirmedPassword,
      OTP,
    });

    if (error) {
      return setError(error.message);
    }

    register({
      firstName,
      lastName,
      email,
      mobile,
      password,
      confirmedPassword,
      OTP,
    });
  };

  useLayoutEffect(() => {
    if (!bookingDetails.type) {
      searchParams.delete("step");
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <ConsultationLayout>
      <span className="text-[14px]">Please fill in your details</span>
      <form onSubmit={handleSubmit} noValidate className="flex h-full w-full flex-col space-y-5">
        <Alert
          isOpen={error || registerError || logInError}
          message={error || registerError || logInError}
          severity={"error"}
        />
        <div className={`grid w-full gap-5 lg:gap-10 ${!isLogInForm && "md:grid-cols-2"}`}>
          {!isLogInForm && (
            <>
              <InputField
                id="firstName"
                name="firstName"
                placeholder={"First Name"}
                autoFocus={!isLogInForm}
                onChange={() => setError("")}
              />
              <InputField
                id="lastName"
                name="lastName"
                placeholder={"Last Name"}
                onChange={() => setError("")}
              />
            </>
          )}
          <InputField
            id="email"
            name="email"
            placeholder={"Email"}
            type={"email"}
            autoFocus={isLogInForm}
            onChange={(event) => (setEmail(event.target.value), setError(""))}
          />
          {!isLogInForm && (
            <>
              <InputField
                id="mobile"
                name="mobile"
                placeholder={"+2 | 01234567890"}
                onChange={() => setError("")}
              />
              <OtpInput
                disabled={!email}
                className={"md:w-full md:justify-between"}
                fieldClassName={"xl:w-10"}
                onComplete={(OTP) => {
                  setOTP(OTP);
                  passwordFieldRef?.current?.focus();
                }}
              />
              <OutlineButton
                disabled={!email}
                isLoading={isGeneratingOTP}
                onClick={handleGenerateOTP}
              >
                Get OTP
              </OutlineButton>
            </>
          )}
          <PasswordInputField
            innerRef={passwordFieldRef}
            id="password"
            name="password"
            placeholder={"Password"}
            onChange={() => setError("")}
          />
          {!isLogInForm && (
            <>
              <PasswordInputField
                id="confirmedPassword"
                name="confirmedPassword"
                placeholder={"Confirm password"}
                onChange={() => setError("")}
              />
            </>
          )}
        </div>
        <div className="!mb-2">
          <span className="text-gray-500 pr-2">
            {isLogInForm ? "Don’t have an account?" : "Do you have an account?"}
          </span>

          <span
            onClick={() => {
              setIsLogInForm(!isLogInForm);
            }}
            className="cursor-pointer text-primary underline underline-offset-2 hover:text-blue-500 border-none hover:bg-transparent min-w-[60px]"
          >
            {isLogInForm ? "Sign In" : "Log In"}
          </span>
        </div>
        <MainButton
          type="submit"
          isLoading={registerLoading || logInLoading}
          className="h-[40px] w-full text-[14px] lg:!mt-auto lg:ml-auto lg:max-w-[200px]"
        >
          {isLogInForm ? "Log in" : "Sign up"}
        </MainButton>
      </form>
    </ConsultationLayout>
  );
}

export default UserInfoForm;
