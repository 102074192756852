import Item from "./item";

export default class Room {
  id: number;
  name: string;
  image: string;
  items: Item[] | null = null;
  is_available: boolean;

  constructor({id, name, image, items= [], is_available} : {id: number, name: string, image: string, items: Item[] | null ,   is_available: boolean} ) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.items = items;
    this.is_available = is_available;
  }
}