import React from "react";
import PropTypes from "prop-types";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function OutlineButton({
  children,
  isLoading = false,
  type = "button",
  onClick,
  className,
  disabled = false,
}) {
  return (
    <button
      className={`min-w-[120px] rounded border border-solid border-secondary p-[7px] text-primary hover:bg-gray-200
                ${disabled && "cursor-not-allowed !bg-gray-100 !text-softGray"}
                ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <AiOutlineLoading3Quarters
          size={20}
          className="mx-auto my-auto shrink-0 animate-spin"
        />
      ) : (
        children
      )}
    </button>
  );
}

OutlineButton.propTypes = {
  children: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default OutlineButton;
