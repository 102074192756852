class User {
  id: number;
  address: [] | null;
  firstName: string;
  lastName: string;
  dateJoined: string;
  email: string;
  birthdate: string | null;
  mobile: string;
  username: string | null;
  role: string;

  constructor({
    id,
    address,
    first_name,
    last_name,
    date_joined,
    email,
    birthdate,
    mobile,
    username,
    role,
  }: {
    id: number;
    address: [] | null;
    first_name: string;
    last_name: string;
    date_joined: string;
    email: string;
    birthdate: string | null;
    mobile: string;
    username: string | null;
    role: string;
  }) {
    this.id = id;
    this.address = address || [];
    this.firstName = first_name;
    this.lastName = last_name;
    this.dateJoined = date_joined;
    this.email = email;
    this.birthdate = birthdate;
    this.mobile = mobile;
    this.username = username;
    this.role = role;
  }
}

export default User;
