const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const getFormattedDate = (date) => {
  if (!date) {
    return undefined;
  }

  date = new Date(date);
  if (!(date instanceof Date) || isNaN(date)) {
    return undefined;
  }

  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  const formattedDay = getFormattedDay(date);

  return `${formattedDay}, ${month}, ${year}`;
};

export const formatDateToddMMyyyy = (date) => {
  if (!date) {
    return undefined;
  }

  date = new Date(date);
  if (!(date instanceof Date) || isNaN(date)) {
    return undefined;
  }

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

  return formattedDate;
};

export const getFormattedDay = (date) => {
  if (!date) {
    return undefined;
  }

  date = new Date(date);
  if (!(date instanceof Date) || isNaN(date)) {
    return undefined;
  }

  const day = date.getDate();
  const suffix = getDaySuffix(day);
  return `${day}${suffix}`;
};

export const getFormattedMonthAndYear = (date) => {
  if (!date) {
    return undefined;
  }

  date = new Date(date);
  if (!(date instanceof Date) || isNaN(date)) {
    return undefined;
  }

  const options = { year: "numeric", month: "long" };
  const formattedDate = new Date(date).toLocaleDateString(undefined, options);
  const [month, year] = formattedDate.split(" ");
  return `${month}, ${year}`;
};

export const getFormattedDayAndMonth = (date) => {
  if (!date) {
    return undefined;
  }

  date = new Date(date);
  if (!(date instanceof Date) || isNaN(date)) {
    return undefined;
  }

  const month = date.toLocaleString("default", { month: "long" });

  return `${getFormattedDay(date)} of ${month}`;
};

const getDaySuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDateToYYYYMMDD = (date) => {
  if (!date) {
    return undefined;
  }

  date = new Date(date);
  if (!(date instanceof Date) || isNaN(date)) {
    return undefined;
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const formatDateToTime = (date) => {
  if (!date) {
    return undefined;
  }

  date = new Date(date);
  if (!(date instanceof Date) || isNaN(date)) {
    return undefined;
  }

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const isLessThan = (date, diff = 24) => {
  if (!date) {
    return false;
  }

  const targetTime = new Date(date);
  if (!(targetTime instanceof Date) || isNaN(targetTime)) {
    return false;
  }

  const currentTime = new Date();

  const diffMilliseconds = targetTime - currentTime;

  const diffHours = diffMilliseconds / (1000 * 60 * 60);

  return diffHours < diff;
};
