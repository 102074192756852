import { useMutation, useQueryClient } from "react-query";
import useSnackbar from "../components/snackbar/hooks/useSnackbar";
import { getErrorMessage } from "../utils/errors";
import { deleteDesign } from "../apis/designsApi";
import { WISHLIST_QUERY_KEY } from "../queries-keys/wishlist";
import { CART_ITEMS_QUERY_KEY } from "../queries-keys/cartItems";
import useUser from "./useUser";

const useDeleteDesign = () => {
  const { handleOpenSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { user } = useUser();

  const { mutate: deleteDesignMutation, isLoading: isDeleting } =
    useMutation({
      mutationFn: deleteDesign,
      onSuccess: () => {
        queryClient.invalidateQueries(WISHLIST_QUERY_KEY());
        queryClient.invalidateQueries(CART_ITEMS_QUERY_KEY(user?.id));
      },
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    });

  return {
    deleteDesignMutation,
    isDeleting,
  };
};

export default useDeleteDesign;
