import React, { useState } from "react";

import useUpdateSlotNotes from "../../../hooks/useUpdateSlotNotes";
import MainButton from "../../../components/customButtons/MainButton";

function UpdateConsultationSlotNotes({ slot }) {
  const [notes, setNotes] = useState(slot?.notes);
  const { updateSlotNotesMutation, isUpdatingSlotNotes } = useUpdateSlotNotes({
    client_id: slot.client_id,
  });

  return (
    <div className="flex flex-col items-center space-y-3">
      <span className="text-[18px] font-bold text-primary">Update Notes</span>
      <textarea
        id="notes"
        autoFocus
        className="w-[90%] resize-none rounded-lg border border-softGray bg-gray-50 p-3 text-primary outline-none focus:border-secondary"
        rows="5"
        value={notes || ""}
        onChange={(event) => setNotes(event.target.value)}
      ></textarea>
      <MainButton
        isLoading={isUpdatingSlotNotes}
        className="mx-auto w-full lg:max-w-[300px]"
        onClick={() => {
          updateSlotNotesMutation({
            slot_id: slot.id,
            new_notes: notes,
          });
        }}
      >
        {slot?.notes ? "Update" : "Add"} Notes
      </MainButton>
    </div>
  );
}

export default UpdateConsultationSlotNotes;
