import api from "./config/axiosConfig";
import ConfigurationSettings from "../models/ConfigurationSettings";

const CONFIG_URL = "/config";

export const getAdminConfigs = async () => {
  const response = await api.get(`${CONFIG_URL}/`, {
    shouldIncludeToke: false,
  });
  return new ConfigurationSettings(response.data);
};
