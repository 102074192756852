import { useState, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Joi from "joi";

import MainButton from "../../../components/customButtons/MainButton";
import OutlineButton from "../../../components/customButtons/OutlineButton";
import PasswordOutLinedField from "../../../components/customInputField/PasswordOutLinedField";
import OtpInput from "../../../components/otpInput";
import useUser from "../../../hooks/useUser";
import Alert from "../../../components/alert";
import validators from "../../../constants/validators";
import { generateOTP, resetPassword } from "../../../apis/authApi";
import { getErrorMessage } from "../../../utils/errors";
import { formatSeconds } from "../../../utils/time";

const ChangePassword = ({ onPasswordChange = () => {} }) => {
  const remainingSecondsIntervalRef = useRef();
  const passwordFieldRef = useRef();
  const { user } = useUser();
  const [OTP, setOTP] = useState();
  const [error, setError] = useState("");
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [firstOTP, setFirstOTP] = useState(true);

  const passwordsSchema = Joi.object({
    password: validators.password,
    confirmedPassword: validators.confirmedPassword,
  });

  const { mutate: generateOTPMutation, isLoading: isGeneratingOTP } =
    useMutation((email) => generateOTP(email), {
      onSuccess: () => {
        setError("");
        setFirstOTP(false);
        setRemainingSeconds(60);
        clearInterval(remainingSecondsIntervalRef.current);
        remainingSecondsIntervalRef.current = setInterval(() => {
          setRemainingSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
      },
      onError: (error) => {
        setError(getErrorMessage(error));
      },
    });

  useEffect(() => {
    clearInterval(remainingSecondsIntervalRef.current);
  }, []);

  const { mutate: resetPasswordMutation, isLoading: isResetPassword } =
    useMutation(
      ({ email, OTP, newPassword }) =>
        resetPassword({ email, OTP, newPassword }),
      {
        onSuccess: () => {
          onPasswordChange();
        },
        onError: (error) => {
          setError(getErrorMessage(error));
        },
      },
    );

  const handleResendOTP = () => {
    if (remainingSeconds <= 0 && !isGeneratingOTP) {
      generateOTPMutation(user?.email);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    const { newPassword, confirmedNewPassword } = Object.fromEntries(
      new FormData(event.target),
    );

    if (!OTP || OTP?.length < 6) {
      return setError("Please enter valid OTP.");
    }

    const { error } = passwordsSchema.validate({
      password: newPassword,
      confirmedPassword: confirmedNewPassword,
    });

    if (error) {
      return setError(error.message);
    }

    resetPasswordMutation({ email: user?.email, newPassword, OTP });
  };

  return (
    <div className="flex flex-col space-y-2 text-primary lg:space-y-4">
      <span className="text-center text-[18px] font-bold">
        Reset your password
      </span>
      <form
        onSubmit={handleSubmit}
        noValidate
        className="flex flex-col space-y-5"
      >
        <Alert isOpen={error} message={error} severity={"error"} />
        <div className="flex flex-col space-y-2">
          <label
            htmlFor="OTP"
            className="mx-auto text-[16px] font-semibold text-primary"
          >
            OTP
          </label>
          <OtpInput
            disabled={firstOTP}
            onComplete={(OTP) => {
              setOTP(OTP);
              passwordFieldRef?.current?.focus();
            }}
          />
          {firstOTP ? (
            <OutlineButton
              className={"mx-auto"}
              onClick={handleResendOTP}
              isLoading={isGeneratingOTP}
            >
              Get OTP
            </OutlineButton>
          ) : (
            <div className="flex space-x-2 text-sm text-primary">
              <span className="text-secondary">Didn't receive OTP?</span>
              {isGeneratingOTP ? (
                <AiOutlineLoading3Quarters
                  size={20}
                  className="mx-auto my-auto shrink-0 animate-spin"
                />
              ) : (
                <span
                  onClick={handleResendOTP}
                  className={`${!formatSeconds(remainingSeconds) && "cursor-pointer underline-offset-2 hover:font-medium hover:underline"}`}
                >
                  {formatSeconds(remainingSeconds) || "Resend OTP"}
                </span>
              )}
            </div>
          )}
        </div>
        <PasswordOutLinedField
          innerRef={passwordFieldRef}
          id="newPassword"
          name="newPassword"
          label="New Password"
          required
          disabled={firstOTP}
        />
        <PasswordOutLinedField
          id="confirmedNewPassword"
          name="confirmedNewPassword"
          label="Confirmed New Password"
          required
          disabled={firstOTP}
        />
        <MainButton
          type="submit"
          className="mx-auto w-full lg:max-w-[300px]"
          disabled={firstOTP}
          isLoading={isResetPassword}
        >
          Reset Password
        </MainButton>
      </form>
    </div>
  );
};

export default ChangePassword;
