import { useContext, useEffect } from "react";

import StepperContext from "../contexts/StepperProvider";

const useStepperLogic = (stepsData, config) => {
  const {
    steps,
    setSteps,
    currentStep,
    setCurrentStep,
    setCurrentStepTitle,
    setCurrentStepData,
    allStepsCount,
    setAllStepsCount,
    setIsFinalStep,
    setPrevButtonDisabled,
    setNextButtonDisabled,
    jumpingDisabled,
    setJumpingDisabled,
  } = useContext(StepperContext);

  useEffect(() => {
    setSteps([...stepsData]);

    const calculatedStepsCount =
      stepsData.reduce((prevSubStepsCount, currentStep) => {
        return prevSubStepsCount + (currentStep?.subSteps?.length - 1 || 0);
      }, 0) + stepsData.length;

    setAllStepsCount(calculatedStepsCount);
    if (currentStep > calculatedStepsCount) setCurrentStep(1);
  }, [stepsData]);

  useEffect(() => {
    setPrevButtonDisabled(currentStep <= 1 || config?.disablePrevious || false);

    setNextButtonDisabled(
      currentStep >= allStepsCount + 1 || config?.disableNext || false,
    );

    setJumpingDisabled(config?.disableJumping || false);
  }, [
    config?.disablePrevious,
    config?.disableNext,
    config?.disableJumping,
    steps,
    currentStep,
    allStepsCount,
  ]);

  useEffect(() => {
    let stepsCount = 0;
    setCurrentStepTitle();
    setCurrentStepData();

    for (const step of steps) {
      if (step?.subSteps) {
        for (const subStep of step?.subSteps) {
          stepsCount++;
          if (stepsCount === currentStep) {
            setCurrentStepTitle(subStep?.label);
            setCurrentStepData(subStep?.data);
          }
        }
      } else {
        stepsCount++;
        if (stepsCount === currentStep) {
          setCurrentStepTitle(step?.label);
          setCurrentStepData(step?.data);
        }
      }
    }
  }, [steps, currentStep]);

  useEffect(() => {
    setIsFinalStep(currentStep === allStepsCount + 1 && allStepsCount !== 0);
  }, [currentStep, allStepsCount]);

  const jumpTo = (step) => {
    if (jumpingDisabled) {
      return;
    }

    if (step > allStepsCount) {
      step = allStepsCount + 1;
    } else if (step < 1) {
      step = 1;
    }

    setCurrentStep(step);
  };

  return { steps, allStepsCount, currentStep, jumpingDisabled, jumpTo };
};

export default useStepperLogic;
