import api from "./config/axiosWith401Redirect";
import Question from "../models/FAQs";

const FAQS_URL = "/faq";

export const getFaqs = async () => {
  const response = await api.get(`${FAQS_URL}/`);
  const questions = response.data?.map((question) => new Question(question));
  return questions;
};
