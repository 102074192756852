import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import PageWrapper from "../../components/pageWrapper";
import useSnackbar from "../../components/snackbar/hooks/useSnackbar";
import ProductsSkeleton from "../../components/skeletons/ProductsSkeleton";
import ROUTES from "../../constants/routes";
import ImageContainer from "../../components/imageContainer";
import { getErrorMessage } from "../../utils/errors";
import { getRooms } from "../../apis/roomsApi";
import { ROOMS_QUERY_KEY } from "../../queries-keys/rooms";

const Shop = () => {
  const navigate = useNavigate();
  const { handleOpenSnackbar } = useSnackbar();

  const { data: rooms, isLoading } = useQuery(ROOMS_QUERY_KEY(), getRooms, {
    staleTime: Infinity,
    onError: (error) => {
      handleOpenSnackbar(getErrorMessage(error), "error");
    },
  });

  const handleSelectRoom = (room) => {
    const roomName = room?.name?.toLowerCase().split(" ").join("-");
    navigate(`${ROUTES.SHOP.path}/${room?.id}/?room=${roomName}`);
  };

  return (
    <PageWrapper>
      <div className="flex flex-col space-y-3 lg:space-y-5">
        <h1 className="text-[24px] font-bold text-primary lg:text-[34px]">
          Choose your room
        </h1>
        <span className="w-full text-[14px] font-light text-primary lg:w-2/5">
          Select the room you want to furnish
        </span>
        <div className="grid auto-rows-fr grid-cols-1 gap-x-1 gap-y-3 md:grid-cols-2 lg:grid-cols-3">
          {isLoading ? (
            <ProductsSkeleton />
          ) : (
            rooms?.map((room, index) => {
              return (
                <div key={index} className="flex flex-col space-y-2">
                  <ImageContainer
                    src={room?.image}
                    alt={room?.name}
                    containerClassName="aspect-[11/9] rounded overflow-hidden"
                    imgClassName={`h-full w-full object-fill ${room.is_available ? "cursor-pointer" : "opacity-50"}`}
                    onClick={
                      !room.is_available
                        ? undefined
                        : () => handleSelectRoom(room)
                    }
                  />
                  <span
                    className={`w-fit text-[14px] font-light text-primary ${room.is_available ? "cursor-pointer" : "opacity-50"}`}
                    onClick={
                      !room.is_available
                        ? undefined
                        : () => handleSelectRoom(room)
                    }
                  >
                    {room?.name}
                  </span>
                </div>
              );
            })
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default Shop;
