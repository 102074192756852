import React, { useState } from "react";

const Tooltip = ({ title, className, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className={`relative ${className}`}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      <div
        className={`${
          showTooltip && title ? "opacity-100" : "opacity-0"
        } absolute bottom-[calc(100%+6px)] left-1/2 -translate-x-1/2 whitespace-nowrap rounded-md bg-primary p-2 text-sm text-white transition-opacity duration-200`}
      >
        {title}
      </div>
      <span
        className={`${
          showTooltip && title ? "opacity-100" : "opacity-0"
        } absolute bottom-[calc(100%+1px)] left-1/2 -translate-x-1/2 border-[5px] border-b-0 border-l-transparent border-r-transparent border-t-primary transition-opacity duration-200`}
      ></span>
    </div>
  );
};

export default Tooltip;
