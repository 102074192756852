import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isHomePageVisited: false,
  currentRoute: "/",
  prevRoute: "/",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setHomePageVisited: (state) => {
      state.isHomePageVisited = true;
    },
    resetHomePageVisited: (state) => {
      state.isHomePageVisited = false;
    },
    setCurrentRoute: (state, action) => {
      state.currentRoute = action?.payload || "/";
    },
    setPrevRoute: (state, action) => {
      state.prevRoute = action?.payload || "/";
    },
  },
});

export const { setHomePageVisited, resetHomePageVisited, setCurrentRoute, setPrevRoute } = appSlice.actions;
export default appSlice.reducer;
