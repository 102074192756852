import { useContext } from "react";

import FilterContext from "../contexts/FilterProvider";

function useFilterLogic() {
  const {
    filters,
    selectedFilters,
    selectedFiltersDispatch,
    UPDATE_SELECTED_COLORS_FILTERS,
    UPDATE_SELECTED_MATERIALS_FILTERS,
    UPDATE_SELECTED_STYLES_FILTERS,
    UPDATE_SELECTED_DIMENSIONS_FILTERS,
    RESET_SELECTED_FILTERS,
    RESET_SELECTED_FILTERS_EXCEPT_DIMENSIONS,
  } = useContext(FilterContext);

  const selectColorFilter = (filter) => {
    const { selectedColorsFilters } = selectedFilters;
    const updatedFilters = selectedColorsFilters.includes(filter)
      ? selectedColorsFilters.filter((f) => f !== filter)
      : [...selectedColorsFilters, filter];
    selectedFiltersDispatch({
      type: UPDATE_SELECTED_COLORS_FILTERS,
      payload: updatedFilters,
    });
  };

  const selectMaterialFilter = (filter) => {
    const { selectedMaterialsFilters } = selectedFilters;
    const updatedFilters = selectedMaterialsFilters.includes(filter)
      ? selectedMaterialsFilters.filter((f) => f !== filter)
      : [...selectedMaterialsFilters, filter];
    selectedFiltersDispatch({
      type: UPDATE_SELECTED_MATERIALS_FILTERS,
      payload: updatedFilters,
    });
  };

  const selectStyleFilter = (filter) => {
    const { selectedStylesFilters } = selectedFilters;
    const updatedFilters = selectedStylesFilters.includes(filter)
      ? selectedStylesFilters.filter((f) => f !== filter)
      : [...selectedStylesFilters, filter];
    selectedFiltersDispatch({
      type: UPDATE_SELECTED_STYLES_FILTERS,
      payload: updatedFilters,
    });
  };

  const selectDimensionFilter = (filter) => {
    const updatedFilters = [filter];
    selectedFiltersDispatch({
      type: UPDATE_SELECTED_DIMENSIONS_FILTERS,
      payload: updatedFilters,
    });
  };

  const resetSelectedFilters = () => {
    selectedFiltersDispatch({ type: RESET_SELECTED_FILTERS });
  };

  const resetSelectedFiltersExceptDimensions = () => {
    selectedFiltersDispatch({ type: RESET_SELECTED_FILTERS_EXCEPT_DIMENSIONS });
  };

  return {
    filters,
    selectedFilters,
    selectColorFilter,
    selectMaterialFilter,
    selectStyleFilter,
    selectDimensionFilter,
    resetSelectedFilters,
    resetSelectedFiltersExceptDimensions,
  };
}

export default useFilterLogic;
