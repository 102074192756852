import { createContext, useState, useMemo } from "react";

const StepperContext = createContext();

export const StepperProvider = ({ children }) => {
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepTitle, setCurrentStepTitle] = useState();
  const [currentStepData, setCurrentStepData] = useState();
  const [allStepsCount, setAllStepsCount] = useState(0);
  const [isFinalStep, setIsFinalStep] = useState(false);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [jumpingDisabled, setJumpingDisabled] = useState(false);

  const stepperObject = useMemo(() => {
    return {
      steps,
      setSteps,
      currentStep,
      setCurrentStep,
      currentStepTitle,
      setCurrentStepTitle,
      currentStepData,
      setCurrentStepData,
      allStepsCount,
      setAllStepsCount,
      isFinalStep,
      setIsFinalStep,
      prevButtonDisabled,
      setPrevButtonDisabled,
      nextButtonDisabled,
      setNextButtonDisabled,
      jumpingDisabled,
      setJumpingDisabled,
    };
  }, [
    steps,
    setSteps,
    currentStep,
    setCurrentStep,
    currentStepTitle,
    setCurrentStepTitle,
    currentStepData,
    setCurrentStepData,
    allStepsCount,
    setAllStepsCount,
    isFinalStep,
    setIsFinalStep,
    prevButtonDisabled,
    setPrevButtonDisabled,
    nextButtonDisabled,
    setNextButtonDisabled,
    jumpingDisabled,
    setJumpingDisabled,
  ]);

  return (
    <StepperContext.Provider value={stepperObject}>
      {children}
    </StepperContext.Provider>
  );
};

export default StepperContext;
