import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Joi from "joi";

import MainButton from "../../../components/customButtons/MainButton";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import InputField from "../../../components/customInputField/InputField";
import useUser from "../../../hooks/useUser";
import validators from "../../../constants/validators";
import Alert from "../../../components/alert";
import { updateProfile } from "../../../apis/profileApi";
import { getErrorMessage } from "../../../utils/errors";
import { PROFILE_QUERY_KEY } from "../../../queries-keys/user";

function EditProfile({ onCloseModal = () => {} }) {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const [error, setError] = useState();
  const [userData, setUserData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    mobile: user.mobile,
    birthdate: user.birthdate,
  });
  const [isUserDataChanged, setIsUserDataChanged] = useState(false);
  const { handleOpenSnackbar } = useSnackbar();

  const editProfileSchema = Joi.object({
    firstName: validators.firstName,
    lastName: validators.lastName,
    mobile: validators.mobile,
    birthdate: validators.birthdate,
  });

  const { mutate: editUserDataMutation, isLoading } = useMutation(
    (data) => updateProfile(data),
    {
      onSuccess: () => {
        queryClient.refetchQueries(PROFILE_QUERY_KEY());
        handleOpenSnackbar(
          "Your profile details have been updated successfully!",
          "success",
        );
        setIsUserDataChanged(false);
        onCloseModal();
      },
      onError: (error) => {
        setError(getErrorMessage(error));
      },
    },
  );

  const handleUserDataChange = (fieldName, value) => {
    setError("");
    const updatedData = {
      ...userData,
      [fieldName]: value === "" ? null : value,
    };
    setUserData(updatedData);
    let isChanged = false;
    for (let field in updatedData) {
      if (updatedData[field] !== user[field]) {
        isChanged = true;
        break;
      }
    }
    if (isChanged) setIsUserDataChanged(true);
    else setIsUserDataChanged(false);
  };

  const handleEditUserData = () => {
    const { error } = editProfileSchema.validate(userData);
    if (error) {
      return setError(error.message);
    }

    editUserDataMutation(userData);
  };

  return (
    <div className="flex flex-col items-center space-y-3 rounded">
      <span className="text-[18px] font-bold text-primary">
        Edit Profile Data
      </span>

      <div className={`flex w-full flex-col items-center space-y-2`}>
        <div className="flex w-[90%] flex-col space-y-2">
          <Alert isOpen={error} message={error} severity={"error"} />
          <div className="flex flex-col lg:grid lg:grid-cols-5">
            <span className="col-span-2 shrink-0 pr-12 text-[16px] font-medium text-primary">
              First Name
            </span>
            <InputField
              placeholder={"First Name"}
              value={userData?.firstName || ""}
              className={"col-span-3"}
              required
              onChange={(event) =>
                handleUserDataChange("firstName", event.target.value)
              }
            />
          </div>
          <div className="flex flex-col lg:grid lg:grid-cols-5">
            <span className="col-span-2 shrink-0 pr-12 text-[16px] font-medium text-primary">
              Last Name
            </span>
            <InputField
              placeholder={"Last Name"}
              value={userData?.lastName || ""}
              className={"col-span-3"}
              required
              onChange={(event) =>
                handleUserDataChange("lastName", event.target.value)
              }
            />
          </div>
          <div className="flex flex-col lg:grid lg:grid-cols-5">
            <span className="col-span-2 shrink-0 text-[16px] font-medium text-primary ">
              Mobile Number
            </span>
            <InputField
              placeholder={"Mobile"}
              value={userData?.mobile || ""}
              className={"col-span-3"}
              required
              onChange={(event) =>
                handleUserDataChange("mobile", event.target.value)
              }
            />
          </div>
          <div className="flex flex-col lg:grid lg:grid-cols-5">
            <span className="col-span-2 shrink-0 pr-12 text-[16px] font-medium text-primary">
              Birth Date
            </span>
            <InputField
              placeholder={"YYYY-MM-DD"}
              value={userData?.birthdate || ""}
              className={"col-span-3"}
              onChange={(event) =>
                handleUserDataChange("birthdate", event.target.value)
              }
              type="date"
            />
          </div>
        </div>
      </div>

      <MainButton
        className="mx-auto w-full lg:max-w-[300px]"
        onClick={handleEditUserData}
        disabled={
          !isUserDataChanged ||
          !userData.firstName ||
          !userData.lastName ||
          !userData.mobile
        }
        isLoading={isLoading}
      >
        Update Data
      </MainButton>
    </div>
  );
}

export default EditProfile;
