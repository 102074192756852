import React, { useState } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ProductsSkeleton from "../../components/skeletons/ProductsSkeleton";
import useCreateDesign from "../../hooks/useCreateDesign";
import useGetItems from "../../hooks/useGetItems";
import PageWrapper from "../../components/pageWrapper";
import useUser from "../../hooks/useUser";
import ROUTES from "../../constants/routes";
import ImageContainer from "../../components/imageContainer";

function RoomItems() {
  const navigate = useNavigate();
  const location = useLocation();
  const { roomId } = useParams();
  const { isLoggedIn, user } = useUser();
  const [selectedItem, setSelectedItem] = useState();

  const { items, isLoading } = useGetItems(roomId);

  const { createDesignMutation, isCreatingDesign } = useCreateDesign();

  const handleSelectRoomItem = (item) => {
    if (!isLoggedIn || !user) {
      return navigate(ROUTES.SIGN_IN.path, { state: { from: location } });
    }
    if (isCreatingDesign) {
      return;
    }

    setSelectedItem(item);
    createDesignMutation(item?.id);
  };

  return (
    <PageWrapper>
      <div className="flex flex-col space-y-3 lg:space-y-5">
        <h1 className="text-[24px] font-bold text-primary lg:text-[34px]">
          Customize your furniture
        </h1>
        <span className="w-full text-[14px] font-light text-primary lg:w-2/5">
          Select the furniture piece you want to begin customizing
        </span>
        <div className="grid auto-rows-fr grid-cols-1 gap-x-1 gap-y-3 md:grid-cols-2 lg:grid-cols-3">
          {isLoading ? (
            <ProductsSkeleton />
          ) : (
            items?.map((item, index) => {
              return (
                <div key={index} className="flex flex-col space-y-2">
                  <div className="relative">
                    <ImageContainer
                      src={item?.image}
                      alt={item?.name}
                      containerClassName="aspect-[11/9] rounded overflow-hidden"
                      imgClassName={`h-full w-full object-fill ${item.is_available ? "cursor-pointer" : "opacity-50"}`}
                      onClick={
                        !item.is_available
                          ? undefined
                          : () => handleSelectRoomItem(item)
                      }
                    />

                    {selectedItem?.id === item?.id && isCreatingDesign && (
                      <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center overflow-hidden bg-gray-700 bg-opacity-60">
                        <PropagateLoader color="#BDCBCC" />
                      </div>
                    )}
                  </div>
                  <span
                    className={`w-fit text-[14px] font-light text-primary  ${item.is_available ? "cursor-pointer" : "opacity-50"}`}
                    onClick={
                      !item.is_available
                        ? undefined
                        : () => handleSelectRoomItem(item)
                    }
                  >
                    {item?.name}
                  </span>
                </div>
              );
            })
          )}
        </div>
      </div>
    </PageWrapper>
  );
}

export default RoomItems;
