export default class CartProduct {
    name: string;
    type: string;
    id: number;
    image: string;
    price: number;
    quantity: number;
    availableQuantity: number;
    finalPrice: number;
    isAvailable : boolean;
    designRequestExpired : boolean;
    itemId : number
  
    constructor({ name,type, id, image, price, quantity, availableQuantity, isAvailable, designRequestExpired, itemId }: { name: string, type: string, id: number, image: string, price: number, quantity: number, availableQuantity: number, isAvailable: boolean, designRequestExpired: boolean, itemId : number }) {
      this.name = name;
      this.id = id;
      this.image = image;
      this.price = price;
      this.quantity = quantity;
      this.availableQuantity = availableQuantity;
      this.finalPrice = quantity*price;
      this.isAvailable = isAvailable;
      this.type = type;
      this.designRequestExpired = designRequestExpired;
      this.itemId = itemId;
    }
  }

  