import { useEffect, useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import PageWrapper from "../../components/pageWrapper";
import MainButton from "../../components/customButtons/MainButton";
import PasswordOutLinedField from "../../components/customInputField/PasswordOutLinedField";
import OutlinedField from "../../components/customInputField/OutlinedField";
import Alert from "../../components/alert";
import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";
import ROUTES from "../../constants/routes";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState();
  const { logIn, logInError, logInLoading } = useAuth();
  const { isLoggedIn } = useUser();

  const fromPath =
    sessionStorage.getItem("pathBeforeLogin") ??
    location.state?.from?.pathname ??
    "/";

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    const { email, password } = Object.fromEntries(new FormData(event.target));
    if (!email) {
      return setError("Email address is required.");
    }
    if (!password) {
      return setError("Password is required.");
    }

    logIn(email, password);
  };

  useEffect(() => {
    if (isLoggedIn) {
      sessionStorage.removeItem("pathBeforeLogin");
      navigate(fromPath, { replace: true });
    }
  }, [isLoggedIn]);

  return (
    <PageWrapper>
      <div className="grid grid-cols-1 lg:grid-cols-7 lg:gap-16">
        <img
          alt=""
          src="/auth.png"
          className="col-span-4 hidden lg:block lg:w-full"
        />
        <div className="col-span-3 flex flex-col space-y-3 text-primary lg:space-y-5">
          <span className="text-[24px] font-bold lg:text-[34px]">Log in</span>
          <form
            onSubmit={handleSubmit}
            noValidate
            className="flex flex-col space-y-5"
          >
            <Alert
              isOpen={error || logInError}
              message={error || logInError}
              severity={"error"}
            />
            <OutlinedField
              id="email"
              name="email"
              label="Email"
              type="email"
              autoFocus
            />
            <PasswordOutLinedField
              id="password"
              name="password"
              label="Password"
              required
            />
            <NavLink
              to={ROUTES.FORGET_PASSWORD.path}
              className="cursor-pointer w-fit justify-end text-sm text-primary underline hover:text-blue-500"
            >
              Forget your password?
            </NavLink>
            <MainButton
              type="submit"
              className="ml-auto w-full lg:w-1/3"
              isLoading={logInLoading}
            >
              Log in
            </MainButton>
          </form>
          {/* <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-center space-x-5">
              <div className="w-1/3 border-t border-gray-400"></div>
              <span className="text-sm text-gray-500">or</span>
              <div className="w-1/3 border-t border-gray-400"></div>
            </div>
            <span className="text-center text-sm text-gray-500">
              Sign up with
            </span>
          </div>
          <div className="flex items-center justify-center space-x-3">
            <FcGoogle size={30} className="cursor-pointer" />
            <FaFacebook size={30} className="cursor-pointer text-blue-500" />
            <FaApple size={30} className="cursor-pointer text-black" />
          </div> */}
          <div className="flex justify-between">
            <span className="text-sm text-gray-500">
              Don’t have an account?
            </span>
            <NavLink
              to={ROUTES.SIGN_UP.path}
              className="cursor-pointer justify-end text-sm text-primary underline hover:text-blue-500"
            >
              Sign up
            </NavLink>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Login;
