import React from "react";
import { useQuery } from "react-query";

import Accordion from "../accordion";
import useSnackbar from "../snackbar/hooks/useSnackbar";
import {
  getItemServiceQuestion,
  getItemsServiceInfo,
} from "../../apis/itemsApi";
import {
  getReadyProductServiceQuestion,
  getReadyProductServiceInfo,
} from "../../apis/readyProductsApi";
import { ITEM_SERVICE_QUESTIONS_QUERY_KEY } from "../../queries-keys/items";
import { READY_PRODUCT_SERVICE_QUESTIONS_QUERY_KEY } from "../../queries-keys/readyToBuy";
import {
  READY_PRODUCTS_SERVICE_INFO_QUERY_KEY,
  ITEMS_SERVICE_INFO_QUERY_KEY,
} from "../../queries-keys/serviceInfo";
import { getErrorMessage } from "../../utils/errors";

function ServiceInfo({ itemId, productId }) {
  const { handleOpenSnackbar } = useSnackbar();
  const { data: questions, isLoadingQuestions } = useQuery(
    itemId
      ? ITEM_SERVICE_QUESTIONS_QUERY_KEY(itemId)
      : READY_PRODUCT_SERVICE_QUESTIONS_QUERY_KEY(productId),
    itemId
      ? () => getItemServiceQuestion(itemId)
      : () => getReadyProductServiceQuestion(productId),
    {
      staleTime: Infinity,
      enabled: !!(itemId || productId),
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    },
  );

  const { data: serviceInfo, isLoadingServiceInfo } = useQuery(
    itemId
      ? ITEMS_SERVICE_INFO_QUERY_KEY()
      : READY_PRODUCTS_SERVICE_INFO_QUERY_KEY(),
    itemId ? () => getItemsServiceInfo() : () => getReadyProductServiceInfo(),
    {
      staleTime: Infinity,
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    },
  );

  if (isLoadingQuestions || isLoadingServiceInfo) {
    return;
  }

  return (
    <div className="flex flex-col space-y-3">
      {!!questions?.length && (
        <Accordion
          enable={true}
          summary={"Product Questions"}
          className={"border-b border-gray-200 last:border-none"}
        >
          <ul className="list-disc px-6 pb-3">
            {questions?.map(({ question, answer }, index) => {
              return (
                <li key={index}>
                  <span className="block">{question}</span>
                  <p className="whitespace-pre-wrap px-3 pb-3 text-[14px] text-secondary">
                    {answer}
                  </p>
                </li>
              );
            })}
          </ul>
        </Accordion>
      )}
      {serviceInfo?.map(({ title, note }) => {
        return (
          <Accordion
            key={title}
            enable={true}
            summary={title}
            className={"border-b border-gray-200 last:border-none"}
          >
            <p className="whitespace-pre-wrap px-3 pb-3 text-[14px] text-secondary">
              {note}
            </p>
          </Accordion>
        );
      })}
    </div>
  );
}

export default ServiceInfo;
