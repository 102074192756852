import { useRef, useEffect, useState, useLayoutEffect } from "react";

export default function useInView({threshold = 0, onInView = () => {} } = {}) {
  const targetRef = useRef(null);
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => {
      setIntersecting(entry.isIntersecting);
    },
    { root: null, threshold },
  );

  useEffect(() => {
    if (isIntersecting) {
      onInView();
    }
  }, [isIntersecting]);

  useLayoutEffect(() => {
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    return () => observer.disconnect();
  }, [observer, targetRef.current]);

  return { isIntersecting, targetRef };
}
