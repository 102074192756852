import axios from "axios";

import ROUTES from "../../constants/routes";

const axiosWith401Redirect = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:8000",
  // withCredentials: true,
  timeout: 10000,
});

axiosWith401Redirect.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const shouldIncludeToke =
      "shouldIncludeToke" in config ? config.shouldIncludeToke : true;
    if (token && shouldIncludeToke) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosWith401Redirect.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");

      const currentPath = window.location.pathname;
      sessionStorage.setItem("pathBeforeLogin", currentPath);

      window.location.href = ROUTES.SIGN_IN.path;
    }
    return Promise.reject(error);
  },
);

export default axiosWith401Redirect;
