import React, { useEffect, useState } from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';

function LastStep({ steps, isCurrentStep = false, jumpingDisabled = false, onClick }) {

  return (
    <div
      className={`flex items-center justify-center h-[24px] w-[24px] p-[3px] rounded-full ${
        !jumpingDisabled && 'cursor-pointer'
      } border transition duration-500 ${
        isCurrentStep ? 'text-primary border-primary' : 'text-secondary border-secondary'
      }`}
      onClick={!jumpingDisabled ? onClick : () => {}}
    >
      <BsFillCheckCircleFill />
    </div>
  );
}

export default LastStep;
