export const DESIGN_CATEGORY = {
  LAYOUT: "Layout",
  MATERIAL: "Material",
  SHAPE: "Design",
};


export const LAYOUT_TYPES = {
  LAYOUT_SIZE : "Layout Size",
  LAYOUT_BODY : "Main Layout",
}