import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

import useKeyPressed from "../../hooks/useKeyPressed";
import { DEFAULT_DESIGN_IMAGE } from "../../constants/imagesUrls";

function Gallery({ open = false, images, startImageIdx = 0, onClose }) {
  const backdropRef = useRef();
  const [currentImageIdx, setCurrentImageIdx] = useState(startImageIdx);

  const handleGetNextImage = () => {
    setCurrentImageIdx((prevValue) =>
      prevValue + 1 >= images.length ? prevValue : prevValue + 1,
    );
  };

  const handleGetPrevImage = () => {
    setCurrentImageIdx((prevValue) =>
      currentImageIdx - 1 < 0 ? prevValue : prevValue - 1,
    );
  };

  useKeyPressed("ArrowRight", handleGetNextImage);
  useKeyPressed("ArrowLeft", handleGetPrevImage);
  useKeyPressed("Escape", onClose);

  useLayoutEffect(() => {
    setCurrentImageIdx(startImageIdx);
  }, [open, startImageIdx]);

  useEffect(() => {
    const handleOverlayClick = (event) => {
      if (backdropRef.current && backdropRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOverlayClick);

    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, [backdropRef.current]);

  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [open]);

  return (
    <div
      className={`fixed left-0 top-0 z-50 !m-0 h-screen w-screen ${!open && "hidden"} flex select-none items-center justify-evenly`}
    >
      <div
        ref={backdropRef}
        className="absolute left-0 top-0 !m-0 h-screen w-screen bg-black opacity-50"
      ></div>
      <MdArrowBackIos
        onClick={handleGetPrevImage}
        className={`text[30px] z-50 cursor-pointer text-secondary hover:text-primary lg:text-[50px] ${currentImageIdx - 1 < 0 && "!cursor-not-allowed hover:text-secondary"}`}
      />
      <img
        src={
          images[currentImageIdx]
            ? images[currentImageIdx]
            : DEFAULT_DESIGN_IMAGE
        }
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = DEFAULT_DESIGN_IMAGE;
        }}
        className="z-50 max-w-[70vw] aspect-video object-cover"
      />
      <MdArrowForwardIos
        onClick={handleGetNextImage}
        className={`text[30px] z-50 cursor-pointer text-secondary hover:text-primary lg:text-[50px] ${currentImageIdx + 1 >= images.length && "!cursor-not-allowed hover:text-secondary"}`}
      />
    </div>
  );
}

export default Gallery;
