export const formatSeconds = (seconds) => {
  if (isNaN(seconds) || seconds <= 0) {
    return undefined;
  }

  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = Math.floor(seconds % 60);

  var formattedHours = hours < 10 ? "0" + hours : hours;
  var formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  var formattedSeconds =
    remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

  var formattedTime =
    formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;

  return formattedTime;
};
