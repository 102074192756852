import React, { useState, useLayoutEffect } from "react";
import { useMutation } from "react-query";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import ConsultationLayout from "./ConsultationLayout";
import MainButton from "../../../components/customButtons/MainButton";
import useSnackbar from "../../../components/snackbar/hooks/useSnackbar";
import UserAddressesHandler from "../../../components/userAddresses";
import { bookOnsiteRequest } from "../../../apis/consultationApi";
import { getErrorMessage } from "../../../utils/errors";
import usePayment from "../../../hooks/usePayment";

function OnsiteBookingForm({ bookingDetails }) {
  const [selectedAddress, setSelectedAddress] = useState();
  const [searchParams, setSearchParams] = useState();
  const { handleOpenSnackbar } = useSnackbar();
  const { createPaymentMutation, isPaymentInProgress } = usePayment();

  const { mutate: bookTimeRequestMutation, isLoading } = useMutation(
    ({ address, notes }) => bookOnsiteRequest({ address, notes }),
    {
      onSuccess: (response) => {
        const booking_request_id = response?.data?.id;
        const price = response?.data?.price;
        createPaymentMutation({
          amount: price,
          booking_requests: [booking_request_id],
        });
      },
      onError: (error) => {
        handleOpenSnackbar(getErrorMessage(error), "error");
      },
    },
  );

  const handleBookingClick = () => {
    const bookingData = {
      address: selectedAddress?.id,
      notes: "",
    };
    bookTimeRequestMutation(bookingData);
  };

  useLayoutEffect(() => {
    if (!bookingDetails.type) {
      searchParams.delete("step");
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <ConsultationLayout>
      {isPaymentInProgress && (
        <div className="fixed left-0 top-0 z-[100] !m-0 flex h-screen w-screen items-center justify-center bg-black bg-opacity-50">
          <AiOutlineLoading3Quarters
            size={50}
            className="shrink-0 animate-spin text-primary"
          />
        </div>
      )}
      <span className="text-[14px]">Choose an address</span>
      <div className="!mb-3 lg:h-[350px]">
        <UserAddressesHandler
          enableSelecting
          {...{ selectedAddress, setSelectedAddress }}
        />
      </div>

      <MainButton
        onClick={handleBookingClick}
        disabled={!selectedAddress || isLoading || isPaymentInProgress}
        isLoading={isPaymentInProgress}
        className="h-[40px] w-full text-[14px] lg:!mt-auto lg:ml-auto lg:max-w-[200px]"
      >
        {isLoading || isPaymentInProgress ? "Payment In Progress..." : "Book"}
      </MainButton>
    </ConsultationLayout>
  );
}

export default OnsiteBookingForm;
