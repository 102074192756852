import React from "react";

function PageWrapper({ children, className = "" }) {
  return (
    <main
      className={`mx-auto w-full px-4 pb-10 pt-[25px] lg:px-10 xl:max-w-[1200px] xl:px-0 xl:pt-[50px] 2xl:max-w-[1400px] min-[1800px]:max-w-[1500px] ${className}`}
    >
      {children}
    </main>
  );
}

export default PageWrapper;
