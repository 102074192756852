import api from "./config/axiosConfig";

const ABOUT_URL = "/about-us";

export const submitInquiry = async ({ name, email, message }) => {
  const response = await api.post(
    `${ABOUT_URL}/contact-us/`,
    { name, email, message },
    { shouldIncludeToke: false },
  );

  return response.data;
};
