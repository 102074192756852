import api from "./config/axiosConfig";
import TeamMember from "../models/TeamMember";

const OUR_TEAM_URL = "/ourTeam";
const ABOUT_URL = "/about-us";

export const getTeamMembers = async () => {
  const response = await api.get(`${OUR_TEAM_URL}/`, {
    shouldIncludeToke: false,
  });
  const teamMembers = response.data?.map((member) => new TeamMember(member));
  return teamMembers;
};

export const getSocialMedia = async () => {
  const response = await api.get(`${ABOUT_URL}/social-media/`, {
    shouldIncludeToke: false,
  });
  return response.data;
};
