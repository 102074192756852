import React from "react";

import { BOOK_CONSULTANT_THUMBNAIL_URL } from "../../../constants/imagesUrls";

function ConsultationLayout({ children }) {
  return (
    <div className="flex flex-col-reverse gap-3 lg:grid lg:grid-cols-2 lg:gap-20">
      <div className="flex flex-col space-y-3 text-primary lg:space-y-5">
        <h1 className="hidden text-[24px] font-bold md:block lg:text-[34px]">
          Book a Consultant
        </h1>
        {children}
      </div>
      <img
        className="col-span-1 h-full w-full object-cover"
        src={BOOK_CONSULTANT_THUMBNAIL_URL}
        alt="Modern Furniture"
      />
      <h1 className="block text-[24px] font-bold text-primary lg:hidden lg:text-[34px]">
        Book a Consultant
      </h1>
    </div>
  );
}

export default ConsultationLayout;
