import React from "react";

function InputField({ required = false, filled = false, className, ...rest }) {
  return (
    <div className={`relative flex w-full items-center ${className}`}>
      <input
        {...rest}
        required={required}
        className={`autofill-color h-full w-full border-b-[1px] border-softGray py-2 text-primary placeholder-secondary outline-none ${filled && "bg-gray-50"}`}
      />
      {required && (
        <span className="absolute right-0 mt-auto text-center text-highlight">
          *
        </span>
      )}
    </div>
  );
}

export default InputField;
